<app-output [label]="label" class="abs-margin">
  <mat-chip-listbox class="abs-scroll-auto">
    @for (contact of contacts; track contact) {
      <mat-chip (click)="select(contact)">
        <mat-icon
          matChipAvatar
          [fontIcon]="contact.type === 'PERSON' ? 'mdi-account' : contact.branch ? 'mdi-office-building' : 'mdi-domain'"
        ></mat-icon>
        {{ contact.name }}
      </mat-chip>
    }
  </mat-chip-listbox>
</app-output>
