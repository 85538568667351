/**
 * All available permissions to have.
 */
export enum UserPermissionEnum {
  /**
   * Users with login permission.
   */
  LOGIN = 'LOGIN',

  /**
   * The default base access in the system.
   */
  BASE_ACCESS = 'BASE_ACCESS',

  /**
   * Users with allowed to create accounts.
   */
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',

  /**
   * Users with allowed to edit accounts.
   */
  EDIT_ACCOUNT = 'EDIT_ACCOUNT',

  /**
   * Users with allowed to create transactions.
   */
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',

  /**
   * Users with allowed to edit transactions.
   */
  EDIT_TRANSACTION = 'EDIT_TRANSACTION',

  /**
   * Users with allowed to view opportunities.
   */
  VIEW_OPPORTUNITIES = 'VIEW_OPPORTUNITIES',

  /**
   * Users with allowed to import data in the system.
   */
  IMPORT_DATA = 'IMPORT_DATA',

  /**
   * User allowed to delete transactions.
   */
  DELETE_TRANSACTION = 'DELETE_TRANSACTION',

  /**
   * User allowed to see names from internal contact.
   */
  VIEW_INTERNAL_DATA = 'VIEW_INTERNAL_DATA',

  /**
   * Permission to view campaigns.
   */
  VIEW_CAMPAIGNS = 'VIEW_CAMPAIGNS',

  /**
   * Permission to manage campaigns
   */
  MANAGE_CAMPAIGNS = 'MANAGE_CAMPAIGNS',

  /**
   * Permission to send campaigns.
   */
  SEND_CAMPAIGNS = 'SEND_CAMPAIGNS',

  /**
   * Permission to view groups.
   */
  VIEW_GROUPS = 'VIEW_GROUPS',

  /**
   * Permission to manage groups.
   */
  MANAGE_GROUPS = 'MANAGE_GROUPS',

  /**
   * Permission to assign a transaction in an opportunity.
   */
  ASSIGN_TRANSACTION_IN_OPPORTUNITY = 'ASSIGN_TRANSACTION_IN_OPPORTUNITY',

  /**
   * Permission to manage fees and flows.
   */
  MANAGE_FEES_AND_FLOWS = 'MANAGE_FEES_AND_FLOWS',

  /**
   * Edit portfolios.
   */
  EDIT_PORTFOLIO = 'EDIT_PORTFOLIO',
}
