import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Observable } from 'rxjs';
import { SearchService } from '../../services/search.service';

/**
 * Responsible to show the results of super search
 */
@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent {
  /**
   * The result set to be displayed as an observable.
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public results$: Observable<{ [key: string]: any }>;

  /**
   * The type to display or any to show all
   */
  public activeType: string | null = null;

  /**
   * Map to translate types to proper display labels.
   */
  public readonly typeLabels: { [key: string]: string } = {
    crm_company: 'Company',
    crm_contact: 'Contact',
    crm_log: 'Log',
    crm_document: 'Document',
    crm_account: 'Account',
  };

  constructor(
    private searchService: SearchService,
    private mediaObserver: MediaObserver,
    private trackerService: TrackerService,
    private router: Router,
  ) {
    this.results$ = this.searchService.results$;
  }

  /**
   * Shows more results of the provided type.
   *
   * @param type the type to show more
   */
  public showMore(type: string): void {
    this.setType(type);
    this.searchService.getMore(type);
  }

  /**
   * Sets the type of entity to be displayed.
   *
   * @param type the type to display or null for all
   */
  public setType(type: string | null): void {
    this.trackerService.event('super_search', 'filter', { type: type ? this.typeLabels[type] : 'All' });
    this.activeType = type;
  }

  /**
   * Function to avoid keyValue pipe from ordering by key
   */
  public returnZero(): number {
    return 0;
  }

  /**
   * Whenever an option is clicked, track the event and clear the search.
   */
  clicked(section: string, entity: any, index: number): void {
    this.trackerService.event('super_search', 'select', { type: this.typeLabels[section] });

    // open sidenav when the log is clicked and show log details
    if (section === 'crm_log') {
      this.router.navigate([{ outlets: { sidenav: ['search', 'logs'] } }], {
        queryParams: {
          'sidenav.searchTerm': this.searchService.searchTem,
          'sidenav.idtLog': entity.id,
          'sidenav.index': index,
        },
      });
    }

    // close the search results
    this.searchService.search('');
  }

  /**
   * Verify if the window is currently in mobile/tablet size.
   *
   * @returns truem if in mobile window size
   */
  isMobile(): boolean {
    return this.mediaObserver.isActive('xs');
  }
}
