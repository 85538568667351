<div class="tab-tools abs-margin-right" fxLayoutAlign="end center">
  <button mat-icon-button (click)="orderBy('statusOrder')" matTooltip="Sort by status" [color]="sort.active === 'statusOrder' ? 'accent' : 'grey'">
    <mat-icon fontIcon="mdi-thermometer"></mat-icon>
  </button>
  <button mat-icon-button (click)="orderBy('size')" matTooltip="Sort by size" [color]="sort.active === 'size' ? 'accent' : 'grey'">
    <mat-icon fontIcon="mdi-currency-usd"></mat-icon>
  </button>
</div>

@if (showChip) {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip>
    }
  </mat-chip-listbox>
}

<!-- Skeleton items while loading -->

<app-custom-list fxFlex fxLayout="column" [ngClass]="{ 'abs-scroll-hidden': loading }">
  <cdk-virtual-scroll-viewport fxFlex fxLayout="column" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()"></app-list-skeleton-loader>

    <app-home-opportunity-list-item
      *cdkVirtualFor="let opportunity of opportunities"
      [selected]="opportunity.idtOpportunity === selectedOpportunity?.idtOpportunity"
      (click)="select(opportunity)"
      [opportunity]="opportunity"
    ></app-home-opportunity-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
