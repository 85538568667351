<mat-form-field class="tw-flex tw-flex-1" [subscriptSizing]="subscriptSizing()">
  <mat-label>Portfolio</mat-label>
  <mat-select #input [formControl]="control()" [multiple]="multiple()">
    <mat-option>
      <ngx-mat-select-search [formControl]="searchPortfolioControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
        <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>

    @for (portfolios of groupedPortfolios() | keyvalue: sortGroups; track portfolios.key) {
      <mat-optgroup [label]="portfolios.key !== 'null' ? portfolios.key : 'None'">
        @for (portfolio of portfolios.value; track portfolio.idtPortfolio) {
          <mat-option [value]="portfolio.idtPortfolio">
            {{ portfolio.shortName }}
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-select>
</mat-form-field>
