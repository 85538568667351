<app-custom-list-item
  (click)="editTask()"
  [ngClass]="{ late: isLate() }"
  [routerLink]="['', { outlets: { sidenav: ['task', task.idtTask, 'edit'] } }]"
  [queryParams]="{ 'sidenav.backTo': backTo }"
>
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="abs-overflow-hidden">
    <mat-icon [fontIcon]="getTaskIcon() || ''" fxFlex="40px" [ngClass]="{ 'abs-color-warn': isDue(), 'abs-color-error': isLate() }"></mat-icon>
    <div fxFlex fxLayout="column" class="abs-margin-double-right abs-overflow-hidden">
      <div fxLayout="row" class="abs-bold">
        @if (task.contacts.length > 0) {
          <span class="abs-text-overflow-ellipsis">
            {{ task.contacts[0].name }}
          </span>
        }
        @if (task.contacts.length > 1) {
          <span style="flex-shrink: 0">&nbsp;+ {{ task.contacts.length - 1 }}</span>
        }
      </div>

      <span class="abs-color-faded abs-text-overflow-ellipsis">{{ getTaskDescription() }}</span>
    </div>
    @if (displayPortfolios) {
      <div fxFlex="150px" class="portfolio">{{ task.portfolios | joinMax: 1 : 'ticker' }}</div>
    }
    <div fxFlex="100px" [ngClass]="{ 'abs-color-error': isLate() }">{{ task.dueDate | timeDiffDays }}</div>
    <div fxFlex="45px" class="abs-color-faded assignee-small">{{ task.users | joinMax: 1 : 'initials' }}</div>
    <div fxFlex="75px" class="abs-color-faded assignee-big">{{ task.users | joinMax: 2 : 'initials' }}</div>
    @if (displayAction) {
      <div fxFlex="36px">
        @if (task.type !== 'ADD_DISTRIBUTION_LIST') {
          <button
            mat-icon-button
            color="primary"
            matTooltip="Write a log"
            (click)="onNewLogClick($event)"
            [routerLink]="['', { outlets: { sidenav: ['log', 'new'] } }]"
            [queryParams]="{ 'sidenav.idtContacts': getContactIds() }"
          >
            <mat-icon fontIcon="mdi-comment-plus"></mat-icon>
          </button>
        }
        @if (task.type === 'ADD_DISTRIBUTION_LIST') {
          <button
            mat-icon-button
            color="primary"
            matTooltip="Add to distribution list"
            routerLink="/mailing/group"
            (click)="onAddToDistributionGroupClick($event)"
          >
            <mat-icon fontIcon="mdi-account-group"></mat-icon>
          </button>
        }
      </div>
    }
  </div>
</app-custom-list-item>
