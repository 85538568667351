import { CommonModule as AngularModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'app/modules/common/material-module';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { AlertBoxComponent } from './alert-box/alert-box.component';
import { ChipListComponent } from './chip-list/chip-list.component';
import { CompanyAutocompleteComponent } from './components/company-autocomplete/company-autocomplete.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { OutputIframeComponent } from './components/output-iframe/output-iframe.component';
import { OutputSectionComponent } from './components/output-section/output-section.component';
import { OutputComponent } from './components/output/output.component';
import { PositiveNegativeComponent } from './components/positive-negative/positive-negative.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { SidenavOutletComponent } from './components/sidenav-outlet/sidenav-outlet.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { CustomListItemComponent } from './custom-list-item/custom-list-item.component';
import { CustomListComponent } from './custom-list/custom-list.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { DialogComponent } from './dialog/dialog.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ResizeObserverDirective } from './directives/resize-observer.directive';
import { ScrollEndingDirective } from './directives/scroll-ending.directive';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { DropzoneDirective } from './dropzone.directive';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { InvestmentStatusComponent } from './investment-status/investment-status.component';
import { ListNavigationComponent } from './list-navigation/list-navigation.component';
import { ListSkeletonLoaderComponent } from './list-skeleton-loader/list-skeleton-loader.component';
import { trimAllInputs } from './overrides/overrides';
import { PerformanceValueComponent } from './performance-value/performance-value.component';
import { AcronymPipe } from './pipe/acronym.pipe';
import { CustomPercentPipe } from './pipe/custom-percent.pipe';
import { EmptyValuePipe } from './pipe/empty-value.pipe';
import { JoinMaxPipe } from './pipe/join-max.pipe';
import { JoinPipe } from './pipe/join.pipe';
import { KebabCasePipe } from './pipe/kebab-case.pipe';
import { LimitNumberPipe } from './pipe/limit-number.pipe';
import { NearUnitNumberPipe } from './pipe/near-unit-number.pipe';
import { OrdinalPipe } from './pipe/ordinal.pipe';
import { PluckPipe } from './pipe/pluck.pipe';
import { PrefixPipe } from './pipe/prefix.pipe';
import { RemoveUnderlinePipe } from './pipe/remove-underline.pipe';
import { SplitByPipe } from './pipe/split-by.pipe';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { TimeDiffDaysPipe } from './pipe/time-diff-days.pipe';
import { YesNoPipe } from './pipe/yes-no.pipe';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ScrollableComponent } from './scrollable/scrollable.component';
import { SidenavComponent } from './sidenav/sidenav.component';

const components = [
  ErrorMessageComponent,
  YesNoPipe,
  TimeAgoPipe,
  OutputComponent,
  AcronymPipe,
  RemoveUnderlinePipe,
  NearUnitNumberPipe,
  SplitByPipe,
  PositiveNegativeComponent,
  SidenavComponent,
  ChipListComponent,
  NoDataComponent,
  ResizeObserverDirective,
  SidenavOutletComponent,
  EmptyValuePipe,
  DialogComponent,
  ScrollEndingDirective,
  CompanyAutocompleteComponent,
  DropzoneDirective,
  SearchFieldComponent,
  HasPermissionDirective,
  OutputIframeComponent,
  ConfirmDialogComponent,
  OutputSectionComponent,
  InvestmentStatusComponent,
  ContextMenuComponent,
  AlertBoxComponent,
  ListSkeletonLoaderComponent,
  LimitNumberPipe,
  ImageDialogComponent,
  CustomListComponent,
  CustomListItemComponent,
  TimeDiffDaysPipe,
  JoinMaxPipe,
  PluckPipe,
  JoinPipe,
  PrefixPipe,
  PerformanceValueComponent,
  OrdinalPipe,
  ScrollableComponent,
  ProgressBarComponent,
  DateRangePickerComponent,
  DialogTitleComponent,
  AutoFocusDirective,
  ScrollSpyDirective,
  KebabCasePipe,
  CustomPercentPipe,
  ListNavigationComponent,
];

@NgModule({
  declarations: components,
  imports: [
    AngularModule,
    MaterialModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    CKEditorModule,
    NgxTrimDirectiveModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        height: '15px',
        margin: 0,
        'border-radius': '10px',
      },
    }),
  ],
  exports: components,
})
export class FrameworkModule {}

trimAllInputs();
