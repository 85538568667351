<div fxLayout="row">
  <mat-form-field subscriptSizing="dynamic">
    <input matInput placeholder="Search" [formControl]="control" #searchField />
  </mat-form-field>
  <div>
    <button mat-icon-button color="primary" matTooltip="Search">
      <mat-icon fontIcon="mdi-magnify"></mat-icon>
    </button>
  </div>
</div>
