import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Currency } from 'app/modules/common/business/account/model/account.model';
import { ClientTransactionTO, ClientTransactionType, ClientTransactionViewTO } from 'app/modules/common/business/client/model/client.model';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { environment } from 'environments/environment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Column } from '../../entity-table/model/column.model';
import { CustomTableService } from '../../entity-table/services/custom-table';

/**
 * Service to interact with the server for transaction related opeartions.
 */
@Injectable({
  providedIn: 'root',
})
export class TransactionService implements CustomTableService<ClientTransactionViewTO> {
  constructor(private http: HttpClient) {}

  /**
   * Get the types of transaction from the server.
   *
   * @param filter the filter string to apply to the query
   * @returns the list of transaction founds wrapped in an observable
   */
  public getTypes(filter: string = ''): Observable<ClientTransactionType[]> {
    return this.http.get<ClientTransactionType[]>(`${environment.apiUrl}/transaction/type`, { params: { filter } });
  }

  public getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(`${environment.apiUrl}/currency`);
  }

  /**
   * Creates a new transaction.
   *
   * @param transaction the transaction data
   * @returns the transaction created wrapped in an observable
   */
  public create(transaction: ClientTransactionViewTO): Observable<ClientTransactionViewTO> {
    return this.http.post<ClientTransactionViewTO>(`${environment.apiUrl}/transaction`, transaction);
  }

  /**
   * Updates and existing transaction.
   *
   * @param transaction the transaction data
   * @returns the transaction updated wrapped in an observable
   */
  public update(transaction: ClientTransactionViewTO): Observable<ClientTransactionViewTO> {
    return this.http.put<ClientTransactionViewTO>(`${environment.apiUrl}/transaction/${transaction.idtClientTransaction}`, transaction);
  }

  /**
   * Get a transaction data from the server.
   *
   * @param idtTransaction the transaction id
   * @returns an observable that emits the transaction data when the server responds
   */
  getOne(idtTransaction: number): Observable<ClientTransactionViewTO> {
    return this.http.get<ClientTransactionViewTO>(`${environment.apiUrl}/transaction/${idtTransaction}`);
  }

  /**
   * Get the details for the transaction with the provided id.
   *
   * @param idtTransaction the id of the transaction
   * @returns the transaction found wrapped in an observable
   */
  public getDetails(idtTransaction: number): Observable<ClientTransactionTO> {
    return this.http.get<ClientTransactionTO>(`${environment.apiUrl}/transaction/${idtTransaction}/details`);
  }

  /**
   * Get the column definitions form the server.
   *
   * @returns the column definitions
   */
  getColumnsDefinitions(): Observable<Column[]> {
    return this.http.get<Column[]>(`${environment.apiUrl}/transaction/columns`).pipe(shareReplay());
  }

  /**
   * Request the values for a single property..
   *
   * @param filter the filters to apply to the query
   * @param groupBy the column to select
   * @param sumColumns the columns to calculate the sum
   * @returns the value of the selected property and the summed columns wrapped in an observable
   */
  getGroups(filter: string, groupBy: string, sumColumns: string[]): Observable<{ [key: string]: any }[]> {
    return this.http.post<{ [key: string]: any }[]>(`${environment.apiUrl}/transaction/searchGroup`, {
      filter,
      groupBy,
      sumColumns,
    });
  }

  /**
   * Gets the value of summing the provided columns.
   *
   * @param filter the filters to apply to the query
   * @param sumColumns the columns to calculate the sum
   * @returns the summed columns values wrapped in an observable
   */
  getTotals(filter: string, sumColumns: string[]): Observable<{ [key: string]: number }> {
    return this.http.post<{ [key: string]: number }>(`${environment.apiUrl}/transaction/search/totals`, {
      filter,
      sumColumns,
    });
  }

  /**
   * Export the current displayed columns to an excel file.
   *
   * @param filter the filers to apply to the query
   * @param selectedColumns the columns to add to the exported file
   * @param sort the colunm and direction to sort by
   */
  export(filter: string, selectedColumns: string[], sort: string): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.apiUrl}/transaction/search/export`,
      { selectedColumns, filter },
      { params: { sort }, responseType: 'blob' as 'json' }
    );
  }

  /**
   * Gets a page of entities form the server.
   *
   * @param page the page number
   * @param size the size of the page
   * @param sort the column and direction to sort results
   * @param filter the filters to apply to the query
   * @param entities the entities to join
   * @returns the page of results found wrapped in an observable
   */
  getPage(
    page: number,
    size: number,
    sort: string | string[],
    filter: string,
    selectedColumns?: string[],
    entities: string[] = []
  ): Observable<Pageable<ClientTransactionViewTO>> {
    return this.http.post<Pageable<ClientTransactionViewTO>>(`${environment.apiUrl}/transaction/search?page=${page}&size=${size}&sort=${sort}`, {
      filter,
      entities,
      selectedColumns,
    });
  }

  /**
   * Get all possible values for the provided column.
   *
   * @param filter filter logic to apply
   * @param column the column to get the values
   * @returns a list of values found
   */
  getOptions(filter: string, column: string): Observable<string[]> {
    return this.getGroups(filter, column, []).pipe(map((gs) => gs.map((g) => g[column])));
  }

  /**
   * Deletes the provided id of the transaction.
   *
   * @param idtTransaction the id of the transaction
   * @returns an observable that resolves after server responds
   */
  delete(idtTransaction: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/transaction/${idtTransaction}`);
  }

  /**
   * Get a datasource of transactions.
   *
   * @param filter the filters to apply
   * @param size the page size
   * @returns the datasource built
   */
  getDatasource(filter: DataFilter, sort = 'transactionDate,DESC', size: number = 20): PageableDataSource<ClientTransactionViewTO> {
    const self = this;

    return new (class extends PageableDataSource<ClientTransactionViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<ClientTransactionViewTO>> {
        return self.http.get<Pageable<ClientTransactionViewTO>>(`${environment.apiUrl}/transaction?filter=${filter.encodeURIComponent()}`, {
          params: {
            page,
            size: limit,
            sort,
          },
        });
      }
    })(size);
  }
}

/**
 * Resolve service to get a transaction details based on the route id.
 */
export const transactionDetailsResolver: ResolveFn<ClientTransactionTO> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  transactionService: TransactionService = inject(TransactionService),
  snackBar: MatSnackBar = inject(MatSnackBar)
) => {
  return transactionService.getDetails(Number(route.paramMap.get('idtTransaction'))).pipe(
    catchError((err) => {
      if (err.status === 403) {
        snackBar.open(err.error.detail, 'Close', { duration: 10000 });
      }

      return EMPTY;
    })
  );
};

/**
 * Resolve service to get a transaction based on the route id.
 */
export const transactionResolver: ResolveFn<ClientTransactionViewTO> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  transactionService: TransactionService = inject(TransactionService),
  snackBar: MatSnackBar = inject(MatSnackBar)
) => {
  return transactionService.getOne(Number(route.paramMap.get('idtTransaction'))).pipe(
    catchError((err) => {
      if (err.status === 403) {
        snackBar.open(err.error.detail, 'Close', { duration: 10000 });
      }

      return EMPTY;
    })
  );
};
