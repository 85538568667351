<app-custom-list-item>
  @if (!last) {
    <mat-divider></mat-divider>
  }
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    @if (selectable) {
      <div>
        <mat-checkbox (click)="$event.stopPropagation()" [checked]="selected" (change)="selectedChange.emit($event.checked)"></mat-checkbox>
      </div>
    }

    <div [fxFlex]="dense ? 15 : 6" fxLayout="column" class="abs-align-center">
      <span>{{ transaction.transactionDate | date: 'MM/dd' : 'UTC' }}</span>
      <span>{{ transaction.transactionDate | date: 'yyyy' : 'UTC' }}</span>
    </div>
    <div fxFlex class="abs-text-overflow-ellipsis-2-line" matTooltip="{{ transaction.accountName }}" fxLayout="column">
      @if (!dense) {
        <span>{{ transaction.accountName }}</span>
      } @else {
        <span class="abs-text-overflow-ellipsis">{{ transaction.accountName }}</span>
        <span class="mat-caption abs-text-overflow-ellipsis">{{ transaction.portfolioTicker }} - {{ transaction.className }}</span>
      }
    </div>
    @if (!dense) {
      <div fxFlex="24" fxLayout="column">
        <span class="abs-text-overflow-ellipsis">{{ transaction.portfolioTicker }}</span>
        <span>{{ transaction.className }}</span>
      </div>

      <div fxFlex="13">
        @if (transaction.mode) {
          <div>{{ transaction.mode | titlecase }}</div>
        }
        <div>{{ transaction.type }}</div>
      </div>
    }
    <div [fxFlex]="dense ? 20 : 13" class="abs-align-right" fxLayout="column">
      @if (transaction.value !== 0) {
        <app-positive-negative [value]="transaction.value">
          <span>{{ transaction.value | number: '0.0-0' }}</span>
        </app-positive-negative>
      }
      @if (dense) {
        <div class="mat-caption">{{ transaction.type }}</div>
      }
    </div>
    <mat-icon
      fxFlex="none"
      [fontIcon]="clientIcons[transaction.status]"
      matTooltip="{{ transaction.pendingStatus | removeUnderline | titlecase }}"
      class="abs-margin-half-right"
    ></mat-icon>
  </div>
</app-custom-list-item>
