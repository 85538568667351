<div fxFlex fxLayout="column" style="width: 100%">
  <mat-toolbar color="primary">
    <span class="abs-margin-left">{{ account?.idtAccount ? 'Edit Account' : 'New Account' }}</span>
    <span fxFlex></span>
    <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
      <mat-icon fontIcon="mdi-close"></mat-icon>
    </button>
  </mat-toolbar>
  <app-progress-bar [loading]="loading"></app-progress-bar>
  <div fxFlex fxLayout="column" class="abs-padding abs-scroll-auto">
    @if (client) {
      <app-account-form [client]="client" [idtContact]="client.idtContact" [account]="account" (save)="save($event)"></app-account-form>
    }
  </div>
  <div fxLayout="row" fxLayoutAlign="end" class="abs-padding">
    <button mat-raised-button color="primary" type="submit" form="account-form" [disabled]="loading">Save</button>
  </div>
</div>
