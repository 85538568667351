import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { MONTH_YEAR_DATE_FORMATS } from 'app/modules/common/framework/date-format/custom-date-format';
import { DateTime } from 'luxon';
import { distinctUntilChanged } from 'rxjs';
import { AccountBalanceGroupBy } from '../../../contact/components/contact-account-balance-list/contact-account-balance-list.component';

/**
 * Component for header of client balance.
 */
@Component({
  selector: 'app-client-balance-header',
  templateUrl: './client-balance-header.component.html',
  styleUrls: ['./client-balance-header.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMATS }],
})
export class ClientBalanceHeaderComponent implements OnInit {
  /**
   * Field to filter the balance by reporting month date.
   */
  public reportingMonthDateField = new FormControl<DateTime | null>(null);

  /**
   * Emits the chosen reporting month date.
   */
  @Output()
  public emitterReportingMonthDate = new EventEmitter<DateTime>();

  /**
   * Sets the balance reporting month date.
   */
  @Input()
  set reportingMonthDate(date: DateTime | undefined) {
    if (!date) {
      return;
    }

    this.reportingMonthDateField.setValue(DateTime.fromObject({ year: date.year, month: date.month }), { emitEvent: false });
  }

  /**
   * The max date to allow selecting.
   */
  @Input()
  maxReportingMonthDate?: DateTime;

  /**
   * Emits the selected tab.
   */
  @Output()
  public groupByChange = new EventEmitter<AccountBalanceGroupBy | undefined>();

  /**
   * Whether the contact to display data from is a consultant.
   */
  private _isConsultant?: boolean;

  @Input()
  set isConsultant(value: boolean | undefined) {
    this._isConsultant = value;
  }

  get isConsultant(): boolean | undefined {
    return this._isConsultant;
  }

  /**
   * The selected grouping.
   */
  @Input()
  groupBy?: AccountBalanceGroupBy;

  /**
   * Total AUM for all accounts.
   */
  @Input({ required: true })
  totalAum?: number;

  /**
   * Whether the total aum is an estimate.
   */
  @Input()
  hasEstimates = false;

  /**
   * Whether there are missing estimates so the total aum is unreliable.
   */
  @Input()
  missingEstimates = false;

  /**
   * Grouping options.
   */
  accountBalanceGroupBy = AccountBalanceGroupBy;

  constructor(
    private trackerService: TrackerService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.reportingMonthDateField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        this.emitterReportingMonthDate.emit(value.endOf('month'));
      }
    });
  }

  /**
   * Handles the date when the month is chosen.
   * It sets the reporting month date field with a formated value like mm/yyyy
   * @param fullDate the full date comes when the client choose a month on calendar
   * @param datepicker responsible for managing the datepicker popup/dialog
   */
  public chosenMonthHandler(fullDate: DateTime, datepicker: MatDatepicker<DateTime>): void {
    const formatedDate = DateTime.fromObject({ year: fullDate.year, month: fullDate.month });
    this.reportingMonthDateField.setValue(formatedDate);
    datepicker.close();
  }

  /**
   * Handles the click on Icon 'tab'.
   * @param index the index of clicked icon
   */
  public onIconClick(type?: AccountBalanceGroupBy): void {
    this.trackerService.event('contact', 'switch_balance_view', { type: type || 'none' });
    this.groupByChange.emit(type);
  }

  /**
   * Checks whether a date should be selectable based on the max allowed date.
   * Although the material lib allows to use `[max]="maxReportingMonthDate"` that does the same (more efficiently), it has a bug that causes the `valueChanges`
   * to be triggered that would duplicate the backend request.
   * https://github.com/angular/components/issues/9295
   *
   * @param d the date option
   * @returns true, if the date should be selectable
   */
  maxFilter = (d: DateTime | null): boolean => {
    if (this.maxReportingMonthDate && d && d > this.maxReportingMonthDate) {
      return false;
    }

    return true;
  };
}
