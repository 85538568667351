<div fxFlex fxLayout="column">
  <!-- Contact (Client) -->
  @if (logContacts$ | async; as logContacts) {
    @if (selectingContact()) {
      <h3>Who is the opportunity client?</h3>
      @if (logContacts.contacts && logContacts.contacts.length > 0) {
        <app-log-contact-chips label="External Contacts" [contacts]="logContacts.contacts" (selected)="select($event)" />
      }
      @if (logContacts.relatedContacts && logContacts.relatedContacts.length > 0) {
        <app-log-contact-chips label="Related Contacts" [contacts]="logContacts.relatedContacts" (selected)="select($event)" />
      }
    }
  }

  @if (selectingKeyContact()) {
    <div fxLayout="row" class="abs-margin-vertical">
      <app-output label="Client">
        @if (!selectedContact) {
          <span>{{ '' | emptyValue }}</span>
        }
        @if (selectedContact) {
          <app-contact-context-menu [displayText]="selectedContact.name" [id]="selectedContact.idtContact"></app-contact-context-menu>
        }
      </app-output>
      @if (!edition) {
        <button mat-icon-button (click)="changeContact()">
          <mat-icon fontIcon="mdi-pencil"></mat-icon>
        </button>
      }
    </div>
  }

  <!-- Key contact -->
  @if (selectingKeyContact()) {
    <h3>Who is the opportunity's contact?</h3>
    <app-custom-list fxFlex>
      <cdk-virtual-scroll-viewport #keyContactViewPort itemSize="50" fxFlex>
        <app-custom-list-item class="selectable" style="height: 50px; padding-left: 38px" (click)="selectKeyContact(null)">
          No specific contact
          <mat-divider></mat-divider>
        </app-custom-list-item>
        <app-contact-item
          *cdkVirtualFor="let contact of contacts"
          [contact]="contact"
          [handleClicks]="false"
          (click)="selectKeyContact(contact)"
        ></app-contact-item>
      </cdk-virtual-scroll-viewport>
    </app-custom-list>
  }

  @if (!selectingContact() && !selectingKeyContact() && !selectingConsultantContact()) {
    <div fxLayout="row" class="abs-margin-vertical">
      <div fxLayout="row" fxFlex="50">
        <app-output label="Client" class="abs-text-overflow-ellipsis">
          @if (!selectedContact) {
            <span>{{ '' | emptyValue }}</span>
          }
          @if (selectedContact) {
            <app-contact-context-menu [displayText]="selectedContact.name" [id]="selectedContact.idtContact"></app-contact-context-menu>
          }
        </app-output>
        @if (!edition) {
          <button mat-icon-button (click)="changeContact()">
            <mat-icon fontIcon="mdi-pencil"></mat-icon>
          </button>
        }
      </div>
      <div fxLayout="row" fxFlex="50">
        <app-output label="Client contact" class="abs-text-overflow-ellipsis">
          @if (!selectedKeyContact) {
            <span>{{ '' | emptyValue }}</span>
          }
          @if (selectedKeyContact) {
            <app-contact-context-menu [displayText]="selectedKeyContact.name" [id]="selectedKeyContact.idtContact"></app-contact-context-menu>
          }
        </app-output>
        <button mat-icon-button (click)="changeKeyContact()" [disabled]="selectedContact?.idtContact === selectedKeyContact?.idtContact">
          <mat-icon fontIcon="mdi-pencil"></mat-icon>
        </button>
      </div>
    </div>
  }

  @if (!selectingContact() && !selectingKeyContact()) {
    <div fxLayout="row" class="abs-margin-vertical">
      <app-output label="Consultant" fxFlex="50">
        @if (!selectedConsultant) {
          <span>{{ '' | emptyValue }}</span>
        }
        @if (selectedConsultant) {
          <app-contact-context-menu [displayText]="selectedConsultant.name" [id]="selectedConsultant.idtContact"></app-contact-context-menu>
        }
      </app-output>
      <app-output label="Consultant Company">
        @if (!selectedConsultantCompany) {
          <span>{{ '' | emptyValue }}</span>
        }
        @if (selectedConsultantCompany) {
          <app-contact-context-menu
            [displayText]="selectedConsultantCompany.name"
            [id]="selectedConsultantCompany.idtContact"
          ></app-contact-context-menu>
        }
      </app-output>
      @if (!edition && !selectedContact) {
        <button mat-icon-button (click)="changeConsultant()">
          <mat-icon fontIcon="mdi-pencil"></mat-icon>
        </button>
      }
      @if (!selectedConsultantCompany) {
        <button mat-raised-button (click)="addNewConsultant()" class="abs-margin-double-left">Add consultant</button>
      }
    </div>
  }

  <!-- Leads -->
  @if (selectedContact && !selecting) {
    <div fxLayout="row" class="abs-margin-vertical">
      <div fxFlex="50" fxLayout="row">
        <app-output label="Client Lead">
          <span>{{ selectedContact.lead | emptyValue }}</span>
        </app-output>
        @if (!selectedContact.lead) {
          <button mat-raised-button (click)="addLead()" class="abs-margin-double-left">Add lead</button>
        }
      </div>
      <app-output label="Client Lead Backup" fxFlex="50">
        <span>{{ selectedContact.leadBackup | emptyValue }}</span>
      </app-output>
    </div>
  }

  @if (!selectedContact && !selecting) {
    <div fxLayout="row" class="abs-margin-vertical">
      <app-output label="Consultant Lead" fxFlex="50">
        <span>{{ selectedConsultantCompany?.lead | emptyValue }}</span>
      </app-output>
      <app-output label="Consultant Lead Backup" fxFlex="50">
        <span>{{ selectedConsultantCompany?.leadBackup | emptyValue }}</span>
      </app-output>
    </div>
  }

  <!-- Consultant clients -->
  @if (selectingConsultantContact()) {
    <h3>Who is the opportunity client?</h3>
    <app-custom-list fxFlex>
      <cdk-virtual-scroll-viewport itemSize="50" fxFlex>
        <app-custom-list-item class="selectable" style="height: 50px; padding-left: 38px" (click)="selectContact()">
          No specific contact
          <mat-divider></mat-divider>
        </app-custom-list-item>
        <app-contact-item
          *cdkVirtualFor="let contact of consultantContacts$ | async"
          [contact]="contact"
          [handleClicks]="false"
          (click)="selectContact(contact)"
        ></app-contact-item>
      </cdk-virtual-scroll-viewport>
    </app-custom-list>
  }
</div>
