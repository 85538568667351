import { Pipe, PipeTransform } from '@angular/core';

/**
 * Shows how much time has passed from a given date until now.
 *
 * Based on https://stackoverflow.com/a/61341940
 */
@Pipe({
  name: 'timeAgo',
  pure: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 30) {
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      }
      const intervals: { [key: string]: number } = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      for (const i in intervals) {
        const counter = Math.floor(seconds / intervals[i]);
        if (counter === 1) {
          return counter + ' ' + i + ' ago'; // singular (1 day ago)
        } else if (counter > 0) {
          return counter + ' ' + i + 's ago'; // plural (2 days ago)
        }
      }
    }
    return value;
  }
}
