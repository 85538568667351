<div fxLayout="column">
  <form [formGroup]="formGroup" class="tw-flex tw-flex-1 tw-flex-col tw-gap-1">
    <div class="tw-flex-row">
      <div fxLayoutGap="8px" fxLayoutAlign="start center">
        <div class="abs-margin-right abs-bold">Does this require an RFP?</div>

        <mat-button-toggle-group formControlName="rfp" hideSingleSelectionIndicator>
          <mat-button-toggle [value]="true">Yes</mat-button-toggle>
          <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      @if (formGroup.controls.rfp.value) {
        <div fxFlex fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px" class="abs-overflow-hidden">
          <mat-form-field class="abs-margin-left" fxFlex="50" subscriptSizing="dynamic">
            <mat-label>Status</mat-label>
            <mat-select formControlName="rfpStatus">
              @for (value of statusOptions; track value) {
                <mat-option [value]="value">
                  {{ value | removeUnderline | titlecase }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field (click)="picker.open()" fxFlex="50" subscriptSizing="dynamic">
            <mat-label>Due date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="rfpDueDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      }
    </div>

    @if (formGroup.controls.rfp.value) {
      <div class="tw-flex tw-flex-row tw-gap-1" fxLayoutGap="8px" fxLayoutAlign="start center">
        <div class="abs-margin-right abs-bold">Start blackout period</div>
        <mat-button-toggle-group formControlName="blackout" hideSingleSelectionIndicator>
          <mat-button-toggle [value]="true">Yes</mat-button-toggle>
          <mat-button-toggle [value]="false">No</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    }
  </form>
</div>
