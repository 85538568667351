import { Observable } from 'rxjs';
import { SuperSearchPageableResult, SuperSearchPageableResultItem } from './pageable';
import { PageableDataSource } from './pageable-datasource';

/**
 * Abstraction for infinite scroll to be used with data from super search (elastic)
 */
export abstract class PageableSuperSearchDataSource<T> extends PageableDataSource<T> {
  constructor(public limit: number, public startPage: number = 0) {
    super(limit, startPage);
  }

  /**
   * Fetches page data
   * @param page the page to load
   * @param limit the size of page
   */
  public abstract fetch(page: number, limit: number): Observable<SuperSearchPageableResult<T>> | Observable<SuperSearchPageableResultItem<T>>;

  /**
   * Handles the data to get the sources from elastic search response
   * @param data the result of search from elastic
   */
  public handleData(data: SuperSearchPageableResult<T> | SuperSearchPageableResultItem<T>): void {
    let hit;
    if ((data as SuperSearchPageableResult<T>).responses) {
      hit = (data as SuperSearchPageableResult<T>).responses[0].hits;
    } else {
      hit = (data as SuperSearchPageableResultItem<T>).hits;
    }
    if (hit.total.value === 0) {
      this.hasData = false;
    }
    const sources: T[] = hit.hits.map((h) => h._source);

    this.totalElements = hit.total.value;
    this.cachedData = this.cachedData.concat(sources);
    this.currentData = sources;
    this.dataStream.next(this.cachedData);
  }

  /**
   * Loads the next page
   */
  public loadMore(): void {
    this.lastPage++;
    this.nextPage++;
    this.fetchPage();
  }
}
