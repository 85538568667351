<div fxFlex fxLayout="row" [fxShow]="previous || next" class="navigator abs-bold">
  <div fxFlex="50">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" [fxShow]="previous" class="abs-clickable" (click)="goPrevious()">
      <mat-icon fontIcon="mdi-chevron-left" class="abs-margin-half-horizontal"></mat-icon>
      <span>{{ previous?.reportedDate | date: 'MM/dd/yyyy' }} - {{ previousCompany }} - {{ previous?.authorInitials }}</span>
    </div>
  </div>
  <div fxFlex="50">
    <div fxFlex fxLayout="row" fxLayoutAlign="end center" [fxShow]="next" class="abs-clickable" (click)="goNext()">
      <span>{{ next?.reportedDate | date: 'MM/dd/yyyy' }} - {{ nextCompany }} - {{ next?.authorInitials }}</span>
      <mat-icon fontIcon="mdi-chevron-right" class="abs-margin-half-horizontal"></mat-icon>
    </div>
  </div>
</div>
