<app-custom-list-item (click)="downloadDocument()">
  <mat-icon class="list-icon tw-flex-none" fontIcon="mdi-file-document"></mat-icon>
  <div class="abs-margin-left tw-flex tw-flex-1 tw-flex-col tw-overflow-hidden">
    <div>
      <span>{{ document.type }}</span>
      @if (document.type && document.creationDate) {
        <span>&nbsp;-&nbsp;</span>
      }
      <span>{{ document.creationDate | date: 'MM/dd/yyyy' }}</span>
    </div>
    <div class="abs-color-faded abs-text-overflow-ellipsis">{{ document.name }}</div>
  </div>
  @if (showLog && document.idtLog) {
    <button mat-icon-button (click)="navigateToLog($event)" matTooltip="Associated Log">
      <mat-icon fontIcon="mdi-message-reply-text"></mat-icon>
    </button>
  }
  @if (showEdit) {
    <button mat-icon-button (click)="navigateToEdit($event)" matTooltip="Edit">
      <mat-icon fontIcon="mdi-pencil"></mat-icon>
    </button>
  }
  @if (showDelete) {
    <button mat-icon-button (click)="deleteDocument($event)" matTooltip="Delete">
      <mat-icon fontIcon="mdi-delete"></mat-icon>
    </button>
  }
  <mat-divider></mat-divider>
</app-custom-list-item>
