<form [formGroup]="contactChannelForm">
  <div fxLayout="row">
    <div fxFlex fxLayout="column">
      <div fxFlex fxLayout="row" fxLayoutGap="8px">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" required>
            <mat-option [value]="ContactChannelTypeEnum.BUSINESS">
              {{ ContactChannelTypeEnum.BUSINESS | titlecase }}
            </mat-option>
            <mat-option [value]="ContactChannelTypeEnum.PERSONAL">
              {{ ContactChannelTypeEnum.PERSONAL | titlecase }}
            </mat-option>
            @if (channel === ContactChannelEnum.PHONE) {
              <mat-option [value]="ContactChannelTypeEnum.FAX">
                {{ ContactChannelTypeEnum.FAX | titlecase }}
              </mat-option>
            }
          </mat-select>
          <mat-error>Required</mat-error>
        </mat-form-field>

        @if (channel === ContactChannelEnum.EMAIL) {
          <div fxFlex fxLayout="column" style="position: relative">
            <app-contact-email-field
              [control]="contactChannelForm.controls.value"
              (errorChanged)="error = $event"
              [idtContact]="contactChannelForm.controls.idtContact.value"
            ></app-contact-email-field>
            @if (error) {
              <mat-error class="custom-error">{{ error }}</mat-error>
            }
          </div>
        } @else if (channel === ContactChannelEnum.PHONE) {
          <mat-form-field fxFlex>
            <mat-label>Phone number</mat-label>
            <input matInput formControlName="value" required type="text" />
            <mat-error>Required</mat-error>
          </mat-form-field>
        }
      </div>
      <mat-slide-toggle formControlName="defaultChannel">
        {{ channel === ContactChannelEnum.EMAIL ? 'Primary email' : 'Primary phone' }}
      </mat-slide-toggle>
    </div>

    <button fxLayoutAlign="end" mat-icon-button aria-label="Remove" (click)="removeForm()">
      <mat-icon fontIcon="mdi-minus-circle"></mat-icon>
    </button>
  </div>
</form>
