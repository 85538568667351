<mat-form-field fxFlex>
  <mat-label>Client or Account</mat-label>

  <mat-select [formControl]="control">
    <mat-option>
      <ngx-mat-select-search [formControl]="searchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
        <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>

    @if (contacts$ | async; as contacts) {
      @if (contacts.length > 0) {
        <div class="abs-bold abs-padding-half">Clients</div>
      }
      @for (contact of contacts; track contact) {
        <mat-option [value]="contact">
          {{ contact.name }}
        </mat-option>
      }
    }

    @if (accounts$ | async; as accounts) {
      @if (accounts.length > 0) {
        <div class="abs-bold abs-padding-half">Accounts</div>
      }
      @for (account of accounts; track account) {
        <mat-option [value]="account">
          {{ account.name }}
        </mat-option>
      }
    }

    @if (control.value; as value) {
      <mat-option [value]="value">
        {{ value.name }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
