@if (!showChip) {
  <div class="tab-tools abs-margin-right" fxLayoutAlign="end center"></div>
}

@if (showChip) {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip>
    }
    @if (!!asOf) {
      <mat-chip>as of {{ asOf.toLocaleString() }}</mat-chip>
    }
  </mat-chip-listbox>
}

<app-custom-list fxFlex fxLayout="column">
  <cdk-virtual-scroll-viewport fxFlex fxLayout="column" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [loading]="loading()" [items]="20"></app-list-skeleton-loader>

    <app-custom-list-item
      *cdkVirtualFor="let client of clients$ | async; trackBy: trackClient"
      [selected]="selectedClient?.idtClient === client.idtClient"
      (click)="select(client)"
    >
      <div fxFlex="100" fxLayout="row">
        <div fxFlex fxLayout="column" class="column-name">
          <span>
            <div style="display: flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="client.name"
                [displayText]="client.name"
                [id]="client.idtContact"
              ></app-contact-context-menu>
            </div>
          </span>
          <span class="abs-text-overflow-ellipsis abs-color-faded" [matTooltip]="client.externalRep">
            <div fxLayout="row">
              @for (consultant of client.consultants; track consultant) {
                <app-contact-context-menu [displayText]="consultant.name" [id]="consultant.idtContact"></app-contact-context-menu>
              }
            </div>
          </span>
        </div>
        <div fxLayout="column" fxLayoutAlign="center end" class="column-amount">
          <span>{{ client.totalClient | currency }}</span>
          <span class="abs-color-faded">{{ client.allocation / 100 | percent: '.2' }}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
