import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { SidenavService } from 'app/modules/common/framework/services/sidenav.service';
import { catchError, finalize, throwError } from 'rxjs';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { Document } from '../../model/document.model';
import { DocumentService } from '../../services/document.service';

/**
 * An item of document for a list
 */
@Component({
  selector: 'app-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss'],
})
export class DocumentListItemComponent {
  @Input()
  document!: Document;

  @Input()
  showLog = false;

  @Input()
  showEdit = false;

  @Input()
  showDelete = false;

  @Input()
  trackingFeature!: string;

  @Output()
  deleted = new EventEmitter<Document>();

  processing = signal(false);

  constructor(
    private documentService: DocumentService,
    private sidenavService: SidenavService,
    private trackerService: TrackerService,
    private dialogService: DialogService,
  ) {}

  /**
   * Downloads the document
   *
   * @param hash the hash of file stored in S3
   * @param filename the name of file
   */
  downloadDocument(): void {
    this.trackerService.event(this.trackingFeature, 'download_document', { idtDocument: this.document.idtDocument });
    this.documentService.download(this.document.idtDocument);
  }

  /**
   * Navigate to associated log details.
   *
   * @param e the click event triggered
   */
  navigateToLog(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'open_log', { idtDocument: this.document.idtDocument });
    this.sidenavService.navigate(['log', this.document.idtLog]);
  }

  /**
   * Navigate to edit document page.
   *
   * @param e the click event triggered
   */
  navigateToEdit(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'edit_document', { idtDocument: this.document.idtDocument });
    this.sidenavService.navigate(['doc', 'edit', this.document.idtDocument]);
  }

  /**
   * Delete the document.
   *
   * @param e the click event triggered
   */
  deleteDocument(e: Event): void {
    e.stopPropagation(); // Avoid downloading the file
    this.trackerService.event(this.trackingFeature, 'delete_document', { idtDocument: this.document.idtDocument });

    this.dialogService
      .showConfirm('Are you sure you want to remove this document from the system?')
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.processing.set(true);
          this.documentService
            .delete(this.document.idtDocument)
            .pipe(
              finalize(() => {
                this.processing.set(false);
              }),
              catchError((err) => {
                this.dialogService.showError(err.error.message || 'Error deleting document');
                return throwError(() => err);
              }),
            )
            .subscribe(() => {
              this.dialogService.showSuccess('Document deleted successfully');
              this.deleted.emit(this.document);
            });
        }
      });
  }
}
