import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { environment } from 'environments/environment';
import { Observable, tap } from 'rxjs';
import { InboxMessageCountPerUserDTO, InboxMessageTO } from '../models/inbox.model';
import { InboxEventEnum } from './inbox-event.enum';

@Injectable({
  providedIn: 'root',
})
export class InboxService {
  constructor(
    private http: HttpClient,
    private eventQueueService: EventQueueService,
  ) {}

  /**
   * Get all the messages still pending analysis, optionally filtering by user.
   *
   * @param idtUser optional user to filter for
   * @param email optional search term
   * @returns an observable that emits all messages found
   */
  findMessages(idtUser: number | null, email?: string): Observable<InboxMessageTO[]> {
    return this.http.get<InboxMessageTO[]>(`${environment.apiUrl}/inbox/message`, {
      params: {
        ...(idtUser && { idtUser }),
        ...(email && { email }),
      },
    });
  }

  /**
   * Get the number of messages needing analysis per user.
   *
   * @returns an observable that emits an array with all users and the amount of messages for each
   */
  getCountPerUser(): Observable<InboxMessageCountPerUserDTO[]> {
    return this.http.get<InboxMessageCountPerUserDTO[]>(`${environment.apiUrl}/inbox/user`);
  }

  /**
   * Get the number of pending inbox messages for the provided user.
   *
   * @param idtUser the user to filter for
   * @returns observable that emits the number of pending messages
   */
  getCountForSingleUser(idtUser: number): Observable<number> {
    return this.http.get<number>(`${environment.apiUrl}/inbox/user/${idtUser}/count`);
  }

  /**
   * Get the details of a single message.
   *
   * @param idtInboxMessage the message id
   * @returns an observable that emits the message found when the server responds
   */
  getMessageDetails(idtInboxMessage: number): Observable<InboxMessageTO> {
    return this.http.get<InboxMessageTO>(`${environment.apiUrl}/inbox/message/${idtInboxMessage}`);
  }

  /**
   * Saves the log associated with the provided inbox message.
   *
   * @param idtInboxMessage the inbox message id
   * @returns an observable that emits the id of the saved log when the server responds
   */
  saveLog(idtInboxMessage: number): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/inbox/message/${idtInboxMessage}`, {}).pipe(
      tap(() => {
        this.eventQueueService.dispatch(InboxEventEnum.messageSaved);
      }),
    );
  }

  /**
   * Delete a message and its associated log.
   *
   * @param idtInboxMessage the message id
   * @returns an observable that emits void when the server responds
   */
  deleteMessageAndLog(idtInboxMessage: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/inbox/message/${idtInboxMessage}`).pipe(
      tap(() => {
        this.eventQueueService.dispatch(InboxEventEnum.messageSaved);
      }),
    );
  }
}
