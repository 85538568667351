<mat-chip-grid #chipList>
  @for (entity of selectedEntities; track entity; let first = $first) {
    <mat-chip-row (removed)="remove(entity)">
      <mat-icon matChipAvatar fontIcon="mdi-account" class="abs-margin-half-right"></mat-icon>
      {{ entity.name }}
      @if (textToFirst && first) {
        <span>&nbsp;(author)</span>
      }
      <button matChipRemove>
        <mat-icon fontIcon="mdi-close"></mat-icon>
      </button>
    </mat-chip-row>
  }
  <input [placeholder]="placeholder" #input [formControl]="formControl" [matAutocomplete]="auto" [matChipInputFor]="chipList" />
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  @for (entity of entities$ | async; track entity) {
    <mat-option [value]="entity">
      <span [class.abs-color-faded]="entity.inactive">{{ entity.name }}</span>
    </mat-option>
  }
</mat-autocomplete>
