import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, startWith, take, tap } from 'rxjs/operators';

/**
 * Abstract class for autocomplete components to implement.
 * Notice we use @Component decorator just to supress an error of angular requiring an annotation for it to work, not understanding this is an abstract class.
 *
 * @param <T> describes the type of the selected entities
 * @param <S> describes the type of the displayed list, commonly the same as selected entities
 */
@Component({ template: '' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class AbstractAutoComplete<T, S> implements OnDestroy, ControlValueAccessor, MatFormFieldControl<T[]> {
  /**
   * The a counter to avoid duplicating ids for this component.
   */
  static nextId = 0;

  /**
   * Observable wrapping the entities to display in the autocomplete list.
   */
  entities$!: Observable<S[]>;

  /**
   * The list of currently displayed entities.
   */
  entities: S[] = [];

  /**
   * The list of selected entities.
   */
  selectedEntities: T[] = [];

  /**
   * A form control to observa input changes.
   */
  formControl = new FormControl<string>('', { nonNullable: true });

  /**
   * Element reference to the input field.
   */
  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;

  /**
   * The id to apply to this input element.
   */
  id = `autocomplete-${AbstractAutoComplete.nextId++}`;

  /**
   * State changes observable to emit events when the component state changes so the mat-form-field can react.
   */
  stateChanges = new Subject<void>();

  /**
   * Function to trigger when data changes, to notify the FormControl.
   */
  onChange?: (_: T[]) => void;

  /**
   * Function to trigger when the component is touched
   */
  onTouched?: () => void;

  /**
   * Value getter.
   */
  get value(): T[] {
    return this.selectedEntities;
  }

  /**
   * Value setter.
   */
  set value(v: T[]) {
    if (v !== this.selectedEntities) {
      this.selectedEntities = v;
    }
  }

  /**
   * Flag to indicate if the component is focused, initially false.
   */
  focused = false;

  /**
   * Getter for current error state.
   */
  get errorState(): boolean {
    return !!this.ngControl?.errors && (!!this.ngControl?.touched || this.parentFormGroup?.submitted || this.parentForm?.submitted);
  }

  /**
   * Getter to see if the input is currently empty.
   */
  get empty(): boolean {
    return this.selectedEntities.length === 0;
  }

  /**
   * Getter to verify if the component should make the label float.
   */
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!this.formControl.value;
  }

  /**
   * The input placeholder.
   */
  private _placeholder = '';

  /**
   * Getter for the placeholder.
   */
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  /**
   * Setter for the placeholder.
   */
  set placeholder(newPlaceholder: string) {
    this._placeholder = newPlaceholder;
    this.stateChanges.next();
  }

  /**
   * If the input is required, for template driver forms.
   */
  private _required = false;

  /**
   * Getter for the required flag.
   */
  @Input()
  get required(): boolean {
    return this._required;
  }

  /**
   * Setter for the required flag.
   */
  set required(newRequired: boolean) {
    this._required = coerceBooleanProperty(newRequired);
    this.stateChanges.next();
  }

  /**
   * If the input should be disabled.
   */
  private _disabled = false;

  /**
   * Getter for the disabled flag.
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  /**
   * Setter for the disabled flag.
   */
  set disabled(newDisabled: boolean) {
    this._disabled = coerceBooleanProperty(newDisabled);
    if (this._disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    this.stateChanges.next();
  }

  /**
   * The aria described by value, for accessibility.
   */
  @HostBinding('attr.aria-describedby')
  describedBy = '';

  constructor(
    protected focusMonitor: FocusMonitor,
    protected elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() protected parentForm: NgForm,
    @Optional() protected parentFormGroup: FormGroupDirective,
    protected cdr: ChangeDetectorRef,
  ) {
    // Set the value acessor for the ngControl
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Force change detection to be executed on form submission. Fixes field not being marked as invalid until interacted with.
    // Error started after upgrading to MDC based form field
    if (this.parentForm) {
      this.parentForm.ngSubmit.pipe(take(1)).subscribe(() => this.stateChanges.next());
    }

    if (this.parentFormGroup) {
      this.parentFormGroup.ngSubmit.pipe(take(1)).subscribe(() => this.stateChanges.next());
    }

    // Monitor if the input is focused
    this.focusMonitor.monitor(this.elementRef, true).subscribe((origin) => {
      if (this.focused && !origin && this.onTouched) {
        this.onTouched();
      }

      this.focused = !!origin;
      this.stateChanges.next();
    });

    // Set the handler to search for entities in the server
    this.entities$ = this.formControl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(1000),
      mergeMap((term) => {
        return this.searchEntities(term);
      }),
      tap((entities) => {
        this.entities = entities;
      }),
    );
  }

  /**
   * Search for entities based on a search term.
   *
   * @param term the search term
   * @returns an observable that resolves to the entities found matchinf the search term
   */
  abstract searchEntities(term: string): Observable<S[]>;

  /**
   * Function to verify if the provided entity is already selected.
   */
  abstract isIncluded(entity: T): boolean;

  /**
   * Write values when formcontrol setter is called.
   *
   * @param obj the entities to set
   */
  writeValue(obj: T[]): void {
    this.selectedEntities = obj;
  }

  /**
   * Register the on change handler.
   *
   * @param fn the new on change handler funtion
   */
  registerOnChange(fn: (_: T[]) => void): void {
    this.onChange = fn;
  }

  /**
   * Register a new on touch handler.
   *
   * @param fn the new on touched function
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Angular lifecycle hook method, called on component destruction.
   * Cleans any subscription to avoid memory leaks.
   */
  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  /**
   * Adds an etity to the selected list when an option is selected.
   *
   * @param event the event that triggered the selection
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.isIncluded(event.option.value)) {
      this.selectedEntities = [...this.selectedEntities, event.option.value];

      if (this.onChange) {
        this.onChange(this.selectedEntities);
      }
    }

    this.input.nativeElement.value = '';
    this.formControl.reset();
  }

  /**
   * Remove an entity for the selected list.
   *
   * @param entity the entity to be removed
   */
  remove(entity: T): void {
    this.selectedEntities = this.selectedEntities.filter((e) => e === entity);

    if (this.onChange) {
      this.onChange(this.selectedEntities);
    }

    this.cdr.detectChanges();
  }

  /**
   * Set the aria described by based on the ids of the inputs.
   */
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  /**
   * Handle clicking on the component
   * @param event the mouse click event
   */
  onContainerClick(): void {
    this.input.nativeElement.focus();
  }
}
