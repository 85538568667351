import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { DateTime } from 'luxon';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import {
  ClientTransactionStatusRawEnum,
  ClientTransactionTotals,
  ClientTransactionTypeWithCount,
  ClientTransactionViewTO,
} from '../../model/client.model';
import { TransactionEventEnum } from '../../model/transaction-event.enum';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-client-transaction-list',
  templateUrl: './client-transaction-list.component.html',
  styleUrls: ['./client-transaction-list.component.scss'],
})
export class ClientTransactionListComponent implements OnInit, OnDestroy {
  /**
   * The id of the client.
   */
  private _idtClient!: number;

  /**
   * Setter for the id of the contact.
   */
  @Input()
  set idtClient(value: number) {
    this._idtClient = value;
    this.loadTransactionTypes().subscribe(() => {
      this.loadTransactions();
    });
  }

  /**
   * Getter for the id of the client.
   */
  get idtClient(): number {
    return this._idtClient;
  }

  /**
   * The clients contact id.
   */
  @Input()
  idtContact!: number;

  /**
   * Event emitted when loading data from server.
   */
  @Output()
  public loading = new EventEmitter<boolean>(true);

  /**
   * The transaction list as a pageable interface.
   */
  public clientTransactionData?: PageableDataSource<ClientTransactionViewTO>;

  /**
   * The sum of the transaction values.
   */
  public clientTransactionTotals$!: Observable<ClientTransactionTotals>;

  /**
   * Transaction types for select list
   */
  public transactionTypes!: ClientTransactionTypeWithCount[];

  /**
   * Subscription to the loading indicator from the data source.
   */
  private loadingSubscription?: Subscription;

  /**
   * Tracks the value of a group of FormControl instances from client transaction filter.
   */
  filterForm = this.fb.group({
    accountNamePortfolioTicker: new FormControl<string>('', { nonNullable: true }),
    idtClientTransactionType: new FormControl<number[]>([], { nonNullable: true }),
    startDate: new FormControl<DateTime | null>(null),
    endDate: new FormControl<DateTime | null>(null),
  });

  @Input()
  dense = false;

  @Input()
  sidenav = false;

  /**
   * Constructor.
   *
   * @param clientService service to call the client api
   */
  constructor(
    private clientService: ClientService,
    private eventService: EventQueueService,
    public auth: AuthService,
    private trackerService: TrackerService,
    private router: Router,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.eventService
      .on(TransactionEventEnum.transactionSaved)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap(() => this.loadTransactionTypes(false)),
        tap(() => {
          this.loadTransactions();
        }),
      )
      .subscribe();

    this.filterForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((values) => {
          this.trackFilters(values);
          this.loadTransactions();
        }),
      )
      .subscribe();
  }

  /**
   * Load transaction list.
   */
  loadTransactions(): void {
    const limit = 25;

    if (this.filterForm.invalid) {
      return;
    }

    this.clientTransactionData = this.clientService.findTransactions(this.idtClient, limit, this.filterForm.getRawValue());
    this.clientTransactionTotals$ = this.clientService.getTransactionsSum(this.idtClient, this.filterForm.getRawValue());

    // Unsubscribe from previous observable and subscribe to next one for listening to server loading changes.
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.clientTransactionData.loading$.subscribe((loading) => {
      this.loading.next(loading);
    });
  }

  /**
   * Formats the total of transactions by status
   * @param transactionsByStatus the list of transaction status and its respective count
   */
  formatTotalTransactionsByStatus(transactionsByStatus: { [key: string]: number }): string {
    const result: string[] = [];

    Object.keys(transactionsByStatus).forEach((s) => {
      let status;

      switch (s) {
        case 'PPT':
          status = ClientTransactionStatusRawEnum.PPT;
          break;
        case 'CFM':
          status = ClientTransactionStatusRawEnum.CFM;
          break;
        case 'PND':
          status = ClientTransactionStatusRawEnum.PND;
          break;
        case 'CCD':
          status = ClientTransactionStatusRawEnum.CCD;
          break;
      }

      result.push(`${status} ${transactionsByStatus[s]}`);
    });

    return result.join(', ');
  }

  /**
   * Loas the types of transaction for this client.
   *
   * @param [updateFilters=true] if it should set the filters initial values
   */
  loadTransactionTypes(updateFilters = true): Observable<ClientTransactionTypeWithCount[]> {
    return this.clientService.findTransactionTypes(this.idtClient).pipe(
      tap((transactionTypes) => {
        this.transactionTypes = transactionTypes;

        if (updateFilters) {
          this.filterForm.controls.idtClientTransactionType.setValue(
            this.transactionTypes.filter((tp) => tp.trade).map((tp) => tp.idtClientTransactionType),
            { emitEvent: false },
          );
        }
      }),
    );
  }

  /**
   * Set the filter value for transaction type. Used in mobile version.
   *
   * @param type the selected transaction type
   */
  setTransactionType(type: ClientTransactionTypeWithCount): void {
    this.filterForm.controls.idtClientTransactionType.setValue([type.idtClientTransactionType]);
  }

  /**
   * Track the given form filters to analytics.
   * @param values the filter values
   */
  private trackFilters(values: any): void {
    const selectedTypes = values.idtClientTransactionType?.map((id: number) =>
      this.transactionTypes?.find((type) => type.idtClientTransactionType === id),
    );
    const properties: any = {
      name: values.accountNamePortfolioTicker || undefined,
      types: selectedTypes?.map((item: ClientTransactionTypeWithCount) => item.type).join(','),
      startDate: values.startDate?.toISODate(),
      endDate: values.endDate?.toISODate(),
    };
    this.trackerService.event('contact', 'filter_transactions', properties);
  }

  /**
   * Open the sidebar to create a new transaction for the client.
   */
  createTransaction(): void {
    this.trackerService.event('contact', 'create_transaction');
    this.router.navigate(['', { outlets: { sidenav: ['transaction', 'new'] } }], {
      queryParams: { 'sidenav.idtClient': this.idtClient },
    });
  }

  /**
   * Open the details of a transaction in the sidebar.
   * @param transaction the clicked transaction
   */
  openTransactionDetails(transaction: ClientTransactionViewTO): void {
    this.trackerService.event('contact', 'open_transaction_details');
    this.router.navigate(['', { outlets: { sidenav: ['transaction', transaction.idtClientTransaction] } }]);
  }

  /**
   * Angular lifecycle hook method, called as component is destroyed.
   * Unsubscribe from loading indicator.
   * Unsubscribe for filter Form.
   */
  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  /**
   * Show all related accounts in the sidebar.
   */
  expandAccounts(): void {
    this.trackerService.event('contact', 'expand_accounts');
    this.router.navigate(['', { outlets: { sidenav: ['contact', this.idtContact, 'accounts'] } }]);
  }

  /**
   * Function to track transactions and thus avoid unecessary rerenders when the list of transactions changes, when loading a new page.
   *
   * @param index the list index
   * @param item the transaction
   * @returns the transaction id
   */
  trackTransaction(index: number, item: ClientTransactionViewTO): number {
    return item.idtClientTransaction;
  }
}
