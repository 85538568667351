import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ClientTransactionViewTO } from '../../client/model/client.model';
import { Column } from '../../entity-table/model/column.model';
import { CustomTableService } from '../../entity-table/services/custom-table';
import {
  OpportunitiesByConsultantTO,
  OpportunityDetailsViewTO,
  OpportunityEvolutionViewTO,
  OpportunityInputDTO,
  OpportunityReportData,
  OpportunityTO,
  OpportunityViewTO,
} from '../model/opportunity.model';

/**
 * Service for opportunity operations.
 */
@Injectable({
  providedIn: 'root',
})
export class OpportunityService implements CustomTableService<OpportunityDetailsViewTO> {
  constructor(private http: HttpClient) {}

  getColumnsDefinitions(): Observable<Column[]> {
    return this.http.get<Column[]>(`${environment.apiUrl}/opportunity/columns`).pipe(shareReplay());
  }

  getGroups(filter: string, groupBy: string, sumColumns: string[]): Observable<{ [key: string]: any }[]> {
    return this.http.post<{ [key: string]: any }[]>(`${environment.apiUrl}/opportunity/searchGroup`, {
      filter,
      groupBy,
      sumColumns,
    });
  }

  getTotals(filter: string, sumColumns: string[]): Observable<{ [key: string]: number }> {
    return this.http.post<{ [key: string]: number }>(`${environment.apiUrl}/opportunity/search/totals`, {
      filter,
      sumColumns,
    });
  }

  export(filter: string, selectedColumns: string[], sort: string): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.apiUrl}/opportunity/search/export`,
      { selectedColumns, filter },
      { params: { sort }, responseType: 'blob' as 'json' },
    );
  }

  getOptions(filter: string, column: string): Observable<string[]> {
    return this.getGroups(filter, column, []).pipe(map((gs) => gs.map((g) => g[column])));
  }

  getPage(
    page: number,
    size: number,
    sort: string | string[],
    filter: string,
    selectedColumns?: string[],
    entities?: string[],
  ): Observable<Pageable<OpportunityDetailsViewTO>> {
    return this.http.post<Pageable<OpportunityDetailsViewTO>>(`${environment.apiUrl}/opportunity/search?page=${page}&size=${size}&sort=${sort}`, {
      filter,
      selectedColumns,
      entities,
    });
  }

  /**
   * Find the opportunities associated with the provided log.
   *
   * @param idtLog the log id
   * @returns list of opportunities found, wrapped in an observable
   */
  findLogOpportunities(idtLog: number): Observable<OpportunityViewTO[]> {
    return this.http.get<OpportunityViewTO[]>(`${environment.apiUrl}/opportunity/log/${idtLog}`);
  }

  /**
   * Find the opportunities related to contacts referenced by the provided log.
   *
   * @param idtLog the id of the log
   * @returns the list of opportunities found wrapped in an observable
   */
  findLogOpportunitiesOptions(idtLog: number): Observable<OpportunityViewTO[]> {
    return this.http.get<OpportunityViewTO[]>(`${environment.apiUrl}/opportunity/log/${idtLog}/options`);
  }

  /**
   * Create an opportunity.
   *
   * @param data the opportunity data
   * @returns an observable that resolves to the created opportunity when the server responds
   */
  create(data: OpportunityInputDTO): Observable<OpportunityTO> {
    return this.http.post<OpportunityTO>(`${environment.apiUrl}/opportunity`, data);
  }

  /**
   * Update an existing opportunity.
   *
   * @param idtOpportunity the id of the opportunity to be updated
   * @param data the opportunity data
   * @returns an observable that emits the updated opportunity after the server responds
   */
  update(idtOpportunity: number, data: OpportunityInputDTO): Observable<OpportunityTO> {
    return this.http.put<OpportunityTO>(`${environment.apiUrl}/opportunity/${idtOpportunity}`, data);
  }

  /**
   * Sets the client for the provided opportunity.
   *
   * @param idtOpportunity the opportunity id
   * @param idtContact the contact id
   * @returns an observable that emits void when the server responds
   */
  updateClient(idtOpportunity: number, idtContact: number): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/opportunity/${idtOpportunity}/client`, { idtContact });
  }

  /**
   * Get a single opportunity from the server.
   *
   * @param idtOpportunity the id of the opportunity
   * @returns an observable that emits the found opportunity
   */
  getOne(idtOpportunity: number): Observable<OpportunityTO> {
    return this.http.get<OpportunityTO>(`${environment.apiUrl}/opportunity/${idtOpportunity}`);
  }

  /**
   * Get a single opportunity from the server.
   *
   * @param idtOpportunity the id of the opportunity
   * @returns an observable that emits the found opportunity
   */
  getDetails(idtOpportunity: number): Observable<OpportunityDetailsViewTO> {
    return this.http.get<OpportunityDetailsViewTO>(`${environment.apiUrl}/opportunity/${idtOpportunity}/details`);
  }

  /**
   * Get all opportunities.
   *
   * @param filter the filters to apply searching
   * @returns an observable that emits the list of opportunities found when the server responds
   */
  getAll(filter: string, sort: string): Observable<OpportunityViewTO[]> {
    return this.http.get<OpportunityViewTO[]>(`${environment.apiUrl}/opportunity?filter=${filter}&sort=${sort}`);
  }

  /**
   * Get list of opportunities by contact.
   *
   * @param idtContact the contact id to filter by
   * @returns an observable that resolves to the list of opportunities
   */
  getByContacts(idtContacts: number[]): Observable<OpportunityViewTO[]> {
    const filter = new DataFilter()
      .in(['idtContact', 'idtKeyContact', 'idtConsultant', 'idtConsultantCompany', 'idtParentCompany', 'idtParentConsultant'], idtContacts, 'long')
      .encodeURIComponent();
    return this.getAll(filter, 'size,DESC');
  }

  /**
   * Get list of opportunities by consultant.
   *
   * @param idtPortfolio the portfolio id to filter for
   * @returns an observable that emits the list of opportunity data by consultant when the server responds
   */
  getByConsultant(idtPortfolio?: number): Observable<OpportunitiesByConsultantTO[]> {
    const params = {
      idtPortfolio: idtPortfolio || '',
    };

    return this.http.get<OpportunitiesByConsultantTO[]>(`${environment.apiUrl}/opportunity/consultant`, { params });
  }

  /**
   * Delete an opportunity.
   *
   * @param idtOpportunity the opporutnity id
   * @returns an observable that resolves to void when the server responds
   */
  delete(idtOpportunity: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/opportunity/${idtOpportunity}`);
  }

  /**
   * Get the evolution timeline for an opportunity, including the logs and most relevant changes.
   *
   * @param idtOpportunity the id of the opportunity
   * @return list of relevant facts on the opportunity lifecycle
   */
  getEvolution(idtOpportunity: number): Observable<OpportunityEvolutionViewTO[]> {
    return this.http.get<OpportunityEvolutionViewTO[]>(`${environment.apiUrl}/opportunity/${idtOpportunity}/evolution`);
  }

  /**
   * Get the opportunity report data.
   *
   * @param limit the lower limit value to filter subscriptions and redemptions
   */
  getReportData(limit: number): Observable<OpportunityReportData> {
    return this.http.get<OpportunityReportData>(`${environment.apiUrl}/opportunity/report`, {
      params: { limit },
    });
  }

  /**
   * Updates an opportunity's transactions.
   *
   * @param idtOpportunity the opportunity to update
   * @param idtTransaction list of transaction ids
   * @returns an observable that emits void when the server responds
   */
  setTransactions(idtOpportunity: number, idtTransactions: number[]): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/opportunity/${idtOpportunity}/transaction`, { idtTransactions });
  }

  /**
   * Get the transactions assocaited with the provided opportunity id.
   *
   * @param idtOpportunity the opportunity id
   * @returns an observable that emits the list of transactions found when the server responds
   */
  getTransactions(idtOpportunity: number): Observable<ClientTransactionViewTO[]> {
    return this.http.get<ClientTransactionViewTO[]>(`${environment.apiUrl}/opportunity/${idtOpportunity}/transaction`);
  }

  /**
   * Get the opportunities to be listed at the home page.
   *
   * @param idtPortfolio optional portfolio to filter by
   * @returns observable that emits the list of opportunities found when the server responds
   */
  getHomeOpportunities(idtPortfolio?: number): Observable<OpportunityViewTO[]> {
    return this.http.get<OpportunityViewTO[]>(`${environment.apiUrl}/opportunity/home`, {
      params: {
        idtPortfolio: idtPortfolio || '',
      },
    });
  }

  /**
   * Send request to verify if contact has opportunities under blackout period.
   *
   * @param idtContact the contact id
   * @returns an observable that emits true if there is an opportunity under blackout period or false otherwise
   */
  hasBlackoutOpportunity(idtContact: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/opportunity/blackout`, { params: { idtContact } });
  }
}
