<mat-form-field (click)="picker.open()" class="abs-clickable" fxFlex>
  <mat-label>Select period</mat-label>
  <mat-date-range-input [rangePicker]="picker" [min]="minDate()" [max]="maxDate()">
    <input matStartDate placeholder="Start date" #startRangeInput [formControl]="startControl()" />
    <input matEndDate placeholder="End date" [formControl]="endControl()" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker panelClass="with-actions">
    <mat-date-range-picker-actions>
      <div fxLayout="column">
        <mat-chip-listbox class="abs-margin-bottom">
          <mat-chip (click)="selectDate('week', -1)">WTD-1</mat-chip>
          <mat-chip (click)="selectDate('week')">WTD</mat-chip>
          <mat-chip (click)="selectDate('month')">MTD</mat-chip>
          <mat-chip (click)="selectDate('quarter')">QTD</mat-chip>
          <mat-chip (click)="selectDate('year')">YTD</mat-chip>
        </mat-chip-listbox>

        <div fxLayoutAlign="end">
          <button mat-button matDatepickerCancel>Cancel</button>
          <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
        </div>
      </div>
    </mat-date-range-picker-actions>
  </mat-date-range-picker>
</mat-form-field>
