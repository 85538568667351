<div fxLayout="column">
  <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="abs-padding">
    @if (selectable) {
      <div style="width: 28px"></div>
    }

    <span [fxFlex]="dense ? 15 : 6" class="mat-header-cell abs-align-center">Date</span>
    <span fxFlex class="mat-header-cell">Account</span>
    @if (!dense) {
      <span fxFlex="24" class="mat-header-cell">Portfolio</span>
      <span fxFlex="13" class="mat-header-cell">Type</span>
    }
    <span [fxFlex]="dense ? 20 : 13" class="mat-header-cell abs-align-center">Value</span>
    <mat-icon fxFlex="none" class="mat-header-cell abs-margin-half-right"></mat-icon>
  </div>
  <mat-divider></mat-divider>
</div>
