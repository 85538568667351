import { ContactViewTO } from 'app/modules/common/business/contact/model/contact.model';
import { DateTime } from 'luxon';
import { Account, Currency } from '../../account/model/account.model';
import { PortfolioClass, PortfolioTO } from '../../portfolio/model/portfolio.model';

export interface ClientViewTO {
  idtClient: number;
  idtContact: number;
  idtCompany: number;
  companyName: string;
  parentCompanyName: string;
  name: string;
  category: string;
  subcategory: string;
  email: string;
  phone: string;
  inactive: boolean;
  investmentStatus: string;
}

/**
 * Represents the Client
 */
export interface Client {
  id?: number;
  idtClient: number;
  idtOwner: number;
  idtCompany: number;
  companyName: string;
  parentCompanyName: string;
  name: string;
  investmentStatus: string;
  category: string;
  subcategory: string;
  lead: string;
  leadBackup: string;
  email: string;
  phone: string;
  inactive: boolean;
  owner: ContactViewTO;
}

/**
 * Represents the Client Transaction
 */
export interface ClientTransactionViewTO {
  idtClientTransaction: number;
  idtClient: number;
  idtContact: number;
  idtAccount: number;
  idtPortfolio: number;
  idtClass: number;
  idtClassSeries: number;
  accountName: string;
  portfolioName: string;
  portfolioTicker: string;
  className: string;
  seriesName: string;
  transactionDate: string;
  shares: number;
  value: number;
  valueLocal: number;
  nav: number;
  altNav: number;
  status: ClientTransactionStatusEnum;
  mode: ClientTransactionModeEnum;
  pendingStatus: string;
  transactionsByStatus: string;
  transactionValueBalance: number;
  notes: string;
  blueSkyState: string;
  comments: string;
  type: string;
  contactName: string;
  hasOpportunity: boolean;
  altCurrency?: string;
}

export interface ClientTransactionTO {
  idtClientTransaction: number;
  portfolio: PortfolioTO;
  portfolioClass?: PortfolioClass;
  portfolioSeries?: PortfolioClass;
  idtCurrency: number;
  idtAltCurrency: number;
  currencyAlt: Currency;
  type: ClientTransactionType;
  account: Account;
  version?: number;
  blueSkyState: string;
  comments: string;
  transactionDate: Date;
  shares: number;
  value: number;
  valueLocal: number;
  nav: number;
  altNav: number;
  status: ClientTransactionStatusEnum;
  mode: ClientTransactionModeEnum;
  pendingStatus: string;
}

/**
 * Api response when requesting total transaction
 */
export interface ClientTransactionTotals {
  transactionBalance: number;
  transactionsByStatus: { [key: string]: number };
}

/**
 * Represents the Enum of transaction status from client
 */
export enum ClientTransactionStatusEnum {
  PROSPECT = 'PROSPECT',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

/**
 * Get icon name
 */
export const ClientIcons = {
  PROSPECT: 'mdi-account-search-outline',
  CONFIRMED: 'mdi-check-circle-outline',
  PENDING: 'mdi-timer-sand',
  CANCELED: 'mdi-close-circle-outline',
};

/**
 * Represents the Enum of transaction status from client
 */
export enum ClientTransactionStatusRawEnum {
  PPT = 'Prospect',
  CFM = 'Confirmed',
  PND = 'Pending',
  CCD = 'Canceled',
}

/**
 * Represents the filter fields of client transaction
 */
export interface ClientTransactionFilterForm {
  accountNamePortfolioTicker: string;
  idtClientTransactionType: number[];
  startDate: DateTime | null;
  endDate: DateTime | null;
}

/**
 * Represents the client transaction type
 */
export interface ClientTransactionType {
  idtClientTransactionType: number;
  type: string;
  trade: boolean;
  name: string;
  transactionSign: number;
}

/**
 * Client transaction types with the used count.
 */
export interface ClientTransactionTypeWithCount {
  idtClientTransactionType: number;
  type: string;
  trade: boolean;
  count: number;
}

/**
 * Represents the Enum of transaction mode from client
 */
export enum ClientTransactionModeEnum {
  NEW = 'NEW',
  ADDITIONAL = 'ADDITIONAL',
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

/**
 * Output DTO when creating a client.
 */
export interface ClientAndAccountOutputDTO {
  account: Account;
  client: Client;
}
