import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform an array of objects into a list of comma seprated properties of that object. Define a max number to show `+ x` after the given limit.
 */
@Pipe({
  name: 'joinMax',
  pure: false,
})
export class JoinMaxPipe implements PipeTransform {
  /**
   * Formats the array of objects into a comma seprated list.
   *
   * @param value the array of objects
   * @param max the max number of values to show
   * @param property the object property to use
   * @returns the formatted string to be displayed
   */
  transform(value: ({ [key: string]: any } | string)[], max: number = 9, property?: string): string {
    let str = value
      .map((v) => (typeof v === 'string' ? v : v[property as string]))
      .slice(0, max)
      .join(', ');

    if (value.length > max) {
      str += ` + ${value.length - max}`;
    }

    return str;
  }
}
