import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PortfolioClass } from '../model/portfolio.model';

/**
 * Service to call the api for a Portfolio CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class PortfolioClassService {
  constructor(private http: HttpClient) {}

  /**
   * Search for portfolio classes.
   *
   * @returns list of classes found
   */
  public findAll(sort: string = 'name,asc', filter = ''): Observable<PortfolioClass[]> {
    return this.http.get<PortfolioClass[]>(`${environment.apiUrl}/class?filter=${filter}`, { params: { sort } });
  }
}
