import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreakpointService } from '../../services/breakpoint.service';

/**
 * Responsive Base component
 */
@Component({ template: '' })
export class ResponsiveComponent implements OnInit {
  breakpoints: { [key: string]: boolean } = {};

  constructor(
    public breakpointService: BreakpointService,
    protected destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.breakpointService.breakpoints.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => (this.breakpoints = data));
  }
}
