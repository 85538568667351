import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PortfolioTO, customPortfolioEntity } from '../../../portfolio/model/portfolio.model';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { TaskViewTO } from '../../models/task.model';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
})
export class TaskListItemComponent {
  /**
   * The task to display.
   */
  private _task!: TaskViewTO;

  @Input()
  set task(value: TaskViewTO) {
    this._task = value;

    if (this.task.customPortfolio && !this.task.portfolios.some((p) => p.idtPortfolio === customPortfolioEntity.idtPortfolio)) {
      this.task.portfolios.push(customPortfolioEntity as PortfolioTO);
    }
  }

  get task(): TaskViewTO {
    return this._task;
  }

  /**
   * The category to log events to.
   */
  @Input()
  trackingCategory = 'task_list';

  /**
   * Whether to display a link to the corresponding action on the system.
   */
  @Input()
  displayAction = false;

  /**
   * Whether to display the assigned portfolios.
   */
  @Input()
  displayPortfolios = false;

  /**
   * The route definition to navigate back to after either saving or clicking the back button.
   */
  @Input()
  backTo?: string[];

  /**
   * Event emitter called when saving the form. Emits the form current value.
   */
  @Output()
  click = new EventEmitter<TaskViewTO>();

  constructor(
    private taskService: TaskService,
    private trackerService: TrackerService
  ) {}

  /**
   * Checks if the provided task is past the due date.
   *
   * @returns true, if the task is late
   */
  isLate(): boolean {
    return this.taskService.isLate(this.task);
  }

  /**
   * Checks if the provided task's due date is in 7 or less days.
   *
   * @returns true, if the task is late
   */
  isDue(): boolean {
    return this.taskService.isDue(this.task);
  }

  /**
   * Get the icon to use for the provided task type.
   *
   * @returns string for the icon to use
   */
  getTaskIcon(): string | undefined {
    return this.taskService.getTaskIcon(this.task);
  }

  /**
   * Get the description text to use for the provided task type.
   *
   * @returns text for the description
   */
  getTaskDescription(): string | undefined {
    return this.taskService.getTaskDescription(this.task);
  }

  /**
   * Get the ids of all contacts associated in the provided task.
   *
   * @returns array of contact ids
   */
  getContactIds(): number[] {
    return this.task.contacts.map((t) => t.idtContact);
  }

  /**
   * Open dialog to edit an existing task.
   */
  editTask(): void {
    const { idtTask } = this.task;
    this.trackerService.event(this.trackingCategory, 'edit_task', { idtTask });
    this.click.emit(this.task);
  }

  /**
   * On clicking to add a new log, send event to analytics.
   *
   * @param event the click event
   */
  onNewLogClick(event: Event): void {
    event.stopPropagation();
    const { idtTask } = this.task;
    this.trackerService.event(this.trackingCategory, 'new_log', { idtTask });
    this.click.emit(this.task);
  }

  /**
   * On clicking to add to distribution group, send event to analytics.
   *
   * @param event the click event
   */
  onAddToDistributionGroupClick(event: Event): void {
    event.stopPropagation();
    const { idtTask } = this.task;
    this.trackerService.event(this.trackingCategory, 'add_to_group', { idtTask });
    this.click.emit(this.task);
  }
}
