@if (id) {
  <app-context-menu
    #menu
    [displayText]="displayText"
    (menuOpen)="loadEntity()"
    [options]="options()"
    [link]="['/contact', id]"
    entity="contact"
    [disabled]="disabled()"
  >
    @if (entity(); as contact) {
      <div class="tw-mr-0.5 tw-w-1/2">
        <div class="abs-text-overflow-ellipsis">{{ contact.name }}</div>
        <app-investment-status [investmentStatus]="contact.investmentStatus"></app-investment-status>
      </div>
      <app-output label="Category" class="abs-align-center tw-w-[32%]">
        <div class="abs-text-overflow-ellipsis">{{ contact.category | emptyValue }}</div>
      </app-output>
      <app-output label="Leads" class="abs-align-center tw-w-[18%]">
        <div>
          <span>{{ contact.lead | emptyValue }}</span>
          @if (contact.lead && contact.leadBackup) {
            <span>,&nbsp;</span>
          }
          <span>{{ contact.leadBackup }}</span>
        </div>
      </app-output>
    }
  </app-context-menu>
} @else {
  <span class="abs-text-overflow-ellipsis">{{ displayText }}</span>
}
