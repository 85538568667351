@if (disabled()) {
  <span class="abs-text-overflow-ellipsis">{{ displayText }}</span>
} @else {
  <div fxLayout="row" fxLayoutAlign="start center" class="abs-link display-text" (contextmenu)="openContextMenu($event)">
    <span class="abs-text-overflow-ellipsis" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="clicked($event)">
      {{ displayText }}
    </span>
    <mat-icon fxFlex="none" fontIcon="mdi-chevron-down" class="context-menu-arrow" (click)="openContextMenu($event)"></mat-icon>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen()"
    [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    (backdropClick)="close()"
  >
    <div class="mat-elevation-z8 wrapper" ngClass.lt-md="fullscreen" ngClass.gt-sm="abs-margin-double-horizontal" fxLayout="column">
      <mat-toolbar color="primary">
        <ng-content></ng-content>
        <button mat-icon-button (click)="close()" fxShow fxHide.gt-sm><mat-icon fontIcon="mdi-close"></mat-icon></button>
      </mat-toolbar>
      <div fxLayout="row wrap" class="abs-padding">
        @for (option of options; track option) {
          <div fxFlex="50">
            @if (option.type === 'link') {
              <a
                fxLayout="row"
                fxLayoutAlign="start center"
                class="abs-padding abs-link"
                (click)="trackClick(option)"
                [routerLink]="option.routerLink"
                [queryParams]="option.queryParams"
              >
                <mat-icon [fontIcon]="option.icon" class="abs-margin-right" color="primary"></mat-icon>
                <span fxFlex>{{ option.text }}</span>
              </a>
            }
            @if (option.type === 'button' && option.click) {
              <a
                fxLayout="row"
                fxLayoutAlign="start center"
                class="abs-padding abs-link"
                (click)="trackClick(option); option.click(); isOpen.set(false)"
              >
                <mat-icon [fontIcon]="option.icon" class="abs-margin-right" color="primary"></mat-icon>
                <span fxFlex>{{ option.text }}</span>
              </a>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
}
