import { Component, ElementRef, Inject, ViewChild, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BasicContact, ContactTypeEnum } from 'app/modules/common/business/contact/model/contact.model';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { NavigationService } from 'app/modules/common/framework/services/navigation.service';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ContactAutocompleteService } from '../../contact/components/contact-autocomplete/contact-autocomplete.service';
import { LogService } from '../../log/services/log.service';
import { DocumentEventEnum } from '../DocumentEventEnum';
import { DocumentFormComponent, DocumentFormGroup } from '../document-form/document-form.component';
import { DocumentTO, DocumentUploadDataWithFile } from '../model/document.model';
import { DocumentService } from '../services/document.service';

@Component({
  selector: 'app-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss'],
})
export class DocumentAddComponent {
  @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;

  /**
   * Contact types enum.
   */
  contactTypes = ContactTypeEnum;

  /**
   * List of all document types.
   */
  documentTypes$ = this.documentService.getDocumentTypes();

  /**
   * List of form groups, for each document.
   */
  formGroups: DocumentFormGroup[] = [];

  /**
   * Flag to indicate if processing an upload.
   */
  processing = false;

  /**
   * Flag to indicate if loading data prior to upload.
   */
  loading = signal(false);

  /**
   * The contacts to be suggested/auto-filled, based on the context.
   */
  private contactSuggestions: BasicContact[] = [];

  constructor(
    private documentService: DocumentService,
    private logService: LogService,
    private dialogService: DialogService,
    private eventQueueService: EventQueueService,
    private contactAutocompleteService: ContactAutocompleteService,
    private navigationService: NavigationService,
    private dialogRef: MatDialogRef<DocumentAddComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { idtLog?: number },
  ) {
    this.loadContactSuggestions();
  }

  /**
   * Load the contact suggestions, depending on the current route and/or the selected log.
   */
  private loadContactSuggestions() {
    if (this.data.idtLog) {
      // If associating a document to a log, suggest its related contacts.
      this.loading.set(true);
      this.logService
        .get(this.data.idtLog)
        .pipe(
          finalize(() => {
            this.loading.set(false);
          }),
        )
        .subscribe((log) => {
          log.contacts
            .filter((c) => !this.contactSuggestions.some((s) => s.idtContact === c.idtContact))
            .forEach((c) => {
              this.contactSuggestions.push({
                idtContact: c.idtContact,
                idtCompany: c.idtCompany!,
                name: c.name,
                type: c.type,
                branch: false,
              });
            });
        });
    } else {
      // Otherwise, verify if the main page has an entity with a related contact
      const mainPageEntity = this.navigationService.getCurrentMainRouteData().entity;
      if (mainPageEntity) {
        this.contactSuggestions.push(mainPageEntity);
      }
    }
  }

  /**
   * Handles dropping files into the upload zone.
   * @param event the files that were dropped
   */
  onFileDropped(event: any): void {
    this.onFilesSelected(event);
  }

  /**
   * Handles selecting files, either by dropping or selecting.
   *
   * @param files the files selected
   */
  onFilesSelected(files: File[]): void {
    for (const item of files) {
      const formGroup = DocumentFormComponent.buildFormGroup(item);

      const contactsControl = formGroup.controls.contacts;
      this.contactAutocompleteService.setupContactsBehavior(contactsControl, contactsControl);
      contactsControl.setValue(this.contactSuggestions);

      this.formGroups.push(formGroup);
    }

    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * Get the extension from the selected file.
   *
   * @param fileName the name of the file
   * @returns the file extension
   */
  getFileExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  /**
   * Remove a file from the list to be uploaded.
   *
   * @param index the index to remove
   */
  removeAt(index: number): void {
    this.formGroups.splice(index, 1);
  }

  /**
   * Remove a file from the list after uploaded.
   *
   * @param removedDocument the document to remove
   */
  remove(removedDocument: DocumentFormGroup): void {
    this.formGroups = this.formGroups.filter((doc) => doc !== removedDocument);
  }

  /**
   * Performs the request to the server to upload all documents.
   */
  upload(): void {
    // Mark all fields as touched to mark invalids in red
    this.formGroups.forEach((fg) => {
      fg.markAllAsTouched();
    });

    // Make sure all fields are valid before proceeding
    if (!this.formGroups.some((fg) => fg.invalid)) {
      this.processing = true;

      // Execute all request in parallel, remove from list when upload is successful
      forkJoin<DocumentTO[]>(
        this.formGroups.map((d) =>
          this.documentService.uploadDocument(this.prepareForm(d)).pipe(
            tap(() => {
              this.remove(d);
            }),
            catchError((err) => of(err)), // Ignore errors, they'll be handled later
          ),
        ),
      )
        .pipe(finalize(() => (this.processing = false)))
        .subscribe((docs) => {
          if (this.formGroups.length > 0) {
            this.dialogService.showError('Not all files were uploaded. Files with errors remain in the page');
          } else {
            this.dialogService.showSuccess('Documents uploaded successfully');
            this.dialogRef.close(docs.map((d) => d.idtDocument));
          }

          this.eventQueueService.dispatch(DocumentEventEnum.documentSaved);
        });
    }
  }

  /**
   * Prepare form group.
   * @returns Return a typed formGroup.
   */
  private prepareForm(form: DocumentFormGroup) {
    const formValue = form.getRawValue();

    const formValueInput: DocumentUploadDataWithFile = {
      idtLog: this.data.idtLog,
      file: formValue.file!,
      type: formValue.type!,
      contacts: formValue.contacts,
      comments: formValue.comments,
    };

    return formValueInput;
  }
}
