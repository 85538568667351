<app-document-list
  fxFlex
  fxLayout="column"
  class="abs-margin"
  trackingFeature="contact"
  [documentData]="documentData"
  [showLog]="true"
  [showEdit]="true"
/>
<mat-divider></mat-divider>
<div class="abs-padding chips">
  <app-chip-list [chipData]="chipData" (clicked)="onChipClick($event)" [ngClass]="{ 'single-line': singeLineChips }" />
</div>
