<mat-form-field class="tw-flex tw-flex-1">
  <mat-label>{{ label() }}</mat-label>

  <mat-chip-grid #chipList [formControl]="control()">
    @for (entity of control().value; track entity.idtContact; let first = $first) {
      <mat-chip-row (removed)="remove(entity.idtContact)" [value]="entity">
        <mat-icon
          matChipAvatar
          [fontIcon]="entity.type === 'PERSON' ? 'mdi-account' : entity.branch ? 'mdi-office-building' : 'mdi-domain'"
        ></mat-icon>
        {{ entity.name }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip-row>
    }
    <input [placeholder]="placeholder()" #input [matAutocomplete]="auto" [matChipInputFor]="chipList" />
  </mat-chip-grid>
  <mat-autocomplete requireSelection #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="getOptionDisplay">
    @for (contact of searchResults$ | async; track contact.idtContact) {
      <mat-option [value]="contact">
        <div class="option-line abs-text-overflow-ellipsis">
          {{ contact.name }}
        </div>
        @if (contact.type === contactTypes.PERSON && contact.companyName) {
          <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
            {{ contact.companyName }}
          </div>
        }
      </mat-option>
    }

    @if (showCreateNew() && input.value.length > 2) {
      <mat-option (click)="createNew()">Create a new contact</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
