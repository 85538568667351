import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Company } from 'app/modules/common/business/company/model/company.model';
import { CompanyService } from 'app/modules/common/business/company/services/company.service';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { DataFilter } from '../../model/data-filter';

@Component({
  selector: 'app-company-autocomplete',
  templateUrl: './company-autocomplete.component.html',
  styleUrls: ['./company-autocomplete.component.scss'],
})
export class CompanyAutocompleteComponent implements OnInit {
  /**
   * The input label
   */
  @Input()
  label = '';

  @Input()
  control!: FormControl<number | null>;

  /**
   * Adds a filter to show only companies that can be parent companies.
   */
  @Input()
  parentCompaniesOnly = false;

  /**
   * Observable wrapping the companies to display in the autocomplete list.
   */
  companies: Company[] = [];

  /**
   * The search control for the search input field.
   */
  companySearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The inital company name.
   */
  @Input()
  legalName?: string;

  constructor(
    private companyService: CompanyService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setupCompanySearch();

    if (this.legalName) {
      this.companySearchControl.setValue(this.legalName);
    }
  }

  /**
   * Setups the select search field behavior.
   */
  setupCompanySearch(): void {
    this.companySearchControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        debounceTime(1000),
        mergeMap((term) => {
          if (term && term.length > 2) {
            let filter = new DataFilter().like('legalName', `%${term}%`, 'string');

            if (this.parentCompaniesOnly) {
              filter = filter.isNull('idtParentCompany');
            }

            return this.companyService.search(filter);
          }

          return of([]);
        }),
      )
      .subscribe((companies) => {
        this.companies = companies;
        this.cdr.markForCheck();
      });
  }
}
