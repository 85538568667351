@if (id) {
  <app-context-menu #menu [displayText]="displayText" (menuOpen)="loadEntity()" [options]="options()" entity="portfolio" [link]="['portfolio', id]">
    @if (entity(); as portfolio) {
      <div fxFlex fxLayout="column">
        <span class="abs-text-overflow-ellipsis">{{ portfolio.shortName }}</span>
        <span class="abs-text-overflow-ellipsis subtitle">{{ portfolio.ticker }}</span>
      </div>
      <app-output label="Manager" class="abs-align-center">
        <span>{{ portfolio?.manager?.initials | emptyValue }}</span>
      </app-output>
    }
  </app-context-menu>
} @else {
  <span class="abs-text-overflow-ellipsis">{{ displayText }}</span>
}
