import { Component, Input } from '@angular/core';
import { OpportunityViewTO } from '../../model/opportunity.model';

@Component({
  selector: 'app-opportunity-list-item',
  templateUrl: './opportunity-list-item.component.html',
  styleUrls: ['./opportunity-list-item.component.scss'],
})
export class OpportunityListItemComponent {
  @Input()
  opportunity!: OpportunityViewTO;

  @Input()
  dense = false;

  @Input()
  last = false;
}
