<div fxLayout="column" class="abs-padding tw-@container" style="padding-right: 0" [ngClass]="{ inactive: account.inactive }">
  <div fxLayout="row">
    @if (groupBy === 'contactName' || !isConsultant) {
      <app-portfolio-context-menu
        class="abs-bold"
        [displayText]="account.portfolioTicker"
        [id]="account.idtPortfolio"
        fxFlex
      ></app-portfolio-context-menu>
    } @else {
      <app-contact-context-menu class="abs-bold" [displayText]="account.contactName" [id]="account.idtContact" fxFlex></app-contact-context-menu>
    }
  </div>

  @if (account.missingEstimate) {
    <app-alert-box [showIcon]="false" class="abs-margin-bottom">Missing estimates in ABSolute</app-alert-box>
  }

  <div fxLayout="row wrap" fxLayoutAlign="start end">
    <div class="account-name abs-color-faded abs-text-overflow-ellipsis">
      <app-account-balance-info-tooltip [data]="account"></app-account-balance-info-tooltip>

      @if (account.ocio) {
        <mat-chip class="mini abs-margin-left">OCIO</mat-chip>
      }
    </div>

    <div class="data" fxLayoutAlign="start end">
      <div class="mat-caption date" fxLayout="column">
        @if (account.inactive) {
          <div>Inactive since&nbsp;</div>
        }
        <div>{{ (account.inactive ? account.lastReportingMonth : account.referenceDate) | date: 'MM/dd/yy' }}</div>
      </div>

      <!-- Desktop -->
      <div class="balance abs-align-right tw-hidden @xs:tw-block">
        @if (!account.inactive) {
          <span>{{ account.endBalance | number: '0.0-0' }}</span>
          <span [ngClass]="{ 'abs-hidden': !account.endBalance || !account.estimated }">*</span>
        }
      </div>

      <!-- Mobile -->
      @if (!account.inactive) {
        <div class="balance abs-align-right @xs:tw-hidden">
          <span>{{ account.endBalance | nearUnitNumber: '0.0-0' }}</span>
          <span [ngClass]="{ 'abs-hidden': !account.endBalance || !account.estimated }">*</span>
        </div>
      }

      <app-performance-value
        class="performance-value"
        fxFlex
        [value]="account.mtd"
        [estimated]="account.estimated"
        [showType]="false"
      ></app-performance-value>
      <app-performance-value
        class="performance-value"
        fxFlex
        [value]="account.qtd"
        [estimated]="account.estimated"
        [showType]="false"
      ></app-performance-value>
      <app-performance-value
        class="performance-value"
        fxFlex
        [value]="account.ytd"
        [estimated]="account.estimated"
        [showType]="false"
      ></app-performance-value>
      <app-contact-balance-itd class="performance-value" fxFlex [account]="account" [estimated]="account.estimated"></app-contact-balance-itd>
      <app-contact-balance-itd
        class="performance-value"
        fxFlex
        [account]="account"
        [estimated]="account.estimated"
        [cumulative]="true"
      ></app-contact-balance-itd>

      <div fxFlex="18px" fxLayoutAlign="end end" (click)="toggleShowIndex()" class="abs-clickable" matRipple>
        <mat-icon [fontIcon]="showIndex() ? 'mdi-chevron-up' : 'mdi-chevron-down'"></mat-icon>
      </div>
    </div>
  </div>

  <!-- Index data, hidden until expanded -->
  @if (showIndex()) {
    @if (indexData(); as indexData) {
      <div fxLayout="row">
        <div fxFlex class="blue-data abs-align-right abs-text-overflow-ellipsis tw-hidden @xs:tw-flex" [matTooltip]="indexData.name">
          {{ indexData.name }}
        </div>
        <div class="index-data abs-align-right abs-text-overflow-ellipsis">
          <div fxFlex class="blue-data abs-align-right abs-text-overflow-ellipsis index-name @xs:tw-hidden" [matTooltip]="indexData.name">
            {{ indexData.name }}
          </div>
          <div fxFlex class="performance-value">
            <span class="blue-data">{{ (indexData.mtd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            <span class="blue-data">{{ (indexData.qtd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            <span class="blue-data">{{ (indexData.ytd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            <span class="blue-data">{{ (indexData.itd | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            <span class="blue-data">{{ (indexData.itdCumulative | number: '0.2-2') || '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex="18px"></div>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex class="abs-align-right abs-text-overflow-ellipsis tw-hidden @xs:tw-flex">Excess</div>
        <div class="index-data abs-align-right">
          <div fxFlex class="abs-align-right abs-text-overflow-ellipsis index-name @xs:tw-hidden">Excess</div>
          <div fxFlex class="performance-value">
            @if (account.mtd != null && indexData.mtd != null) {
              <span>{{ account.mtd - indexData.mtd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            @if (account.qtd != null && indexData.qtd != null) {
              <span>{{ account.qtd - indexData.qtd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            @if (account.ytd != null && indexData.ytd != null) {
              <span>{{ account.ytd - indexData.ytd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            @if (account.itd != null && indexData.itd != null) {
              <span>{{ account.itd - indexData.itd | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex class="performance-value">
            @if (account.itdCumulative != null && indexData.itdCumulative != null) {
              <span>{{ account.itdCumulative - indexData.itdCumulative | number: '0.2-2' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div fxFlex="18px"></div>
        </div>
      </div>
    }
  }
</div>

<mat-divider></mat-divider>
