import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { UserPermissionEnum } from 'app/modules/routes/login/model/user-permission.enum';
import { AuthService } from '../../../auth/services/auth.service';
import { ContactIndexedDTO, ContactTypeEnum, ContactViewTO } from '../../model/contact.model';

/**
 * Component for contact item of a mat list
 */
@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactItemComponent {
  contact = input.required<ContactViewTO | ContactIndexedDTO>();

  /**
   * Whether to redirect to the contact page on clicks.
   */
  handleClicks = input<boolean>(true);

  /**
   * Whether to show things in a dense form, for mobile use mainly.
   */
  dense = input<boolean>(false);

  active = input<boolean>(false);

  /**
   * Whether to show phone and email data.
   */
  showPhoneAndEmail = input<boolean>(true);

  contactTypeEnum = ContactTypeEnum;

  /**
   * Show investment status column.
   */
  showInvestmentStatus = input<boolean>(true);

  /**
   * Whether to show context menus.
   */
  showContextMenus = input<boolean>(true);

  constructor(
    private authService: AuthService,
    private trackerService: TrackerService,
  ) {}

  /**
   * Verify if the use can see the context menu. Can see if contact is not internal or if it has permission to see internal contacts.
   *
   * @returns true, if allowed to see context menu
   */
  canSeeContextMenu(): boolean {
    return !this.contact().internal || this.authService.hasPermission(UserPermissionEnum.VIEW_INTERNAL_DATA);
  }

  /**
   * Navigate to the contact's email.
   */
  clickEmail(): void {
    this.trackerService.event('contact', 'click_email');
    document.location.href = `mailto:${this.contact().email}`;
  }

  /**
   * Navigate to the contact's phone number.
   */
  clickPhone(): void {
    this.trackerService.event('contact', 'click_phone');
    document.location.href = `tel:${this.contact().phone}`;
  }
}
