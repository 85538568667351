import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Sidenav Service
 */
@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor(private router: Router) {}

  /**
   * Navigates to sidenav
   * @param side
   */
  public navigate(side: unknown[]): void {
    this.router.navigate([{ outlets: { sidenav: side } }]);
  }

  /**
   * Removes sidenav from url to close it
   */
  public close(): void {
    this.router.navigate([{ outlets: { sidenav: null } }]);
  }
}
