<form class="abs-padding-double" fxLayout="column" [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="extension abs-margin-right">{{ getFileExtension() }}</div>
    <span fxFlex class="mat-headline-6 abs-nomargin" fxFlex>{{ getFileName() }}</span>
    @if (removable) {
      <button mat-icon-button (click)="remove()" matTooltip="Delete">
        <mat-icon fontIcon="mdi-delete"></mat-icon>
      </button>
    }
  </div>

  <div fxLayout="column" class="abs-margin-double-left">
    <div fxLayout="column" class="abs-margin-top abs-margin-double-bottom">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="abs-margin-top abs-margin-right"></mat-icon>
        <mat-button-toggle-group
          formControlName="type"
          hideSingleSelectionIndicator
          [ngClass]="{ error: formGroup.touched && formGroup.get('type')?.invalid }"
        >
          @for (type of allDocumentTypes; track type.idtDocumentType) {
            <mat-button-toggle [value]="type.idtDocumentType" [matTooltip]="type.type">
              {{ type.shortName }}
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      </div>
      @if (formGroup.touched && formGroup.get('type')?.invalid) {
        <mat-error class="abs-margin-top" style="margin-left: 28px"> Document type is required </mat-error>
      }
    </div>

    <div fxLayout="row">
      <mat-icon fontIcon="mdi-account-group" class="abs-margin-top abs-margin-right"></mat-icon>
      <app-contact-autocomplete
        class="tw-flex tw-flex-1"
        label="External Contacts"
        [control]="formGroup.controls.contacts"
        placeholder="Add contact..."
        [showCreateNew]="true"
      ></app-contact-autocomplete>
    </div>

    <div fxLayout="row">
      <mat-icon class="abs-margin-top abs-margin-right"></mat-icon>
      <mat-form-field fxFlex>
        <mat-label>Comments</mat-label>
        <textarea cdkTextareaAutosize matInput cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="comments"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>
