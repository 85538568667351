import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { AbstractAutoComplete } from 'app/modules/common/framework/autocomplete/autocomplete';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../user.model';
import { UserService } from '../user.service';
/**
 * Component for a user autocomplete input field.
 */
@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: UserAutocompleteComponent }],
})
export class UserAutocompleteComponent extends AbstractAutoComplete<User, User> implements OnInit {
  private allUsers: User[] = [];

  /**
   * Whether to show the (author) for the first user.
   */
  @Input()
  textToFirst: boolean = false;

  constructor(
    private userService: UserService,
    focusMonitor: FocusMonitor,
    elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() ngControl: NgControl,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    cdr: ChangeDetectorRef
  ) {
    super(focusMonitor, elementRef, ngControl, parentForm, parentFormGroup, cdr);
  }

  ngOnInit(): void {
    this.userService
      .searchEmployeesByTerm('')
      .pipe(
        tap((allUsers) => {
          this.allUsers = allUsers;
        })
      )
      .subscribe();
  }

  /** @inheritdoc */
  searchEntities(term: string): Observable<User[]> {
    if (term && term.length > 2) {
      return this.userService.searchEmployeesByTerm(term);
    }

    return of(this.allUsers.slice());
  }

  /**
   * Remove an entity for the selected list.
   *
   * @param entity the entity to be removed
   */
  remove(entity: User): void {
    this.selectedEntities = this.selectedEntities.filter((e) => e.idtUser !== entity.idtUser);

    if (this.onChange) {
      this.onChange(this.selectedEntities);
    }

    this.cdr.detectChanges();
  }

  /**
   * Verifies if the provided user is already selected.
   *
   * @param user the user to verify
   * @returns true, if the user is already selected
   */
  isIncluded(user: User): boolean {
    return this.selectedEntities.some((u) => u.idtUser === user.idtUser);
  }
}
