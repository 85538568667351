<app-custom-list-item [routerLink]="[{ outlets: { primary: ['contact', account.idtContact], sidenav: ['account', account.idtAccount] } }]">
  <mat-icon class="list-icon" fontIcon="mdi-bank"></mat-icon>

  <div class="abs-margin-left tw-flex tw-flex-1 tw-items-center tw-justify-center tw-overflow-hidden">
    <div class="tw-flex tw-max-w-[75%] tw-flex-1 tw-flex-col">
      <span class="abs-text-overflow-ellipsis">{{ account.name }}</span>
      <span class="abs-text-overflow-ellipsis abs-color-faded">{{ account.clientName }}</span>
    </div>
    <div class="tw-flex tw-max-w-[25%] tw-flex-1">
      <span matTooltip="{{ account.portfolioTicker }}" class="abs-margin-left abs-text-overflow-ellipsis">
        {{ account.portfolioTicker }}
      </span>
    </div>
  </div>

  <mat-divider></mat-divider>
</app-custom-list-item>
