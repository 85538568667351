<form
  id="account-form"
  [formGroup]="formGroup"
  [fxLayout]="useColumns ? 'row' : 'column'"
  [fxLayoutGap]="useColumns ? '8px' : '0'"
  (submit)="onSave()"
>
  <div fxLayout="column" fxFlex>
    <div class="abs-component-title abs-padding">
      <span>Product</span>
    </div>
    <div class="abs-dashed-box" fxLayout="column">
      <mat-form-field>
        <mat-label>Portfolio</mat-label>
        <mat-select formControlName="idtPortfolio">
          <mat-option>
            <ngx-mat-select-search [formControl]="searchPortfolioControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
              <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small"></mat-icon>
            </ngx-mat-select-search>
          </mat-option>

          @for (portfolio of portfolios$ | async; track portfolio.idtPortfolio) {
            <mat-option [value]="portfolio.idtPortfolio">
              {{ portfolio.shortName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Class</mat-label>
        <mat-select formControlName="idtClass">
          <mat-option>Select an option</mat-option>
          @for (class of classes; track class) {
            <mat-option [value]="class.idtClass">{{ class.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Series</mat-label>
        <mat-select formControlName="idtSeries">
          <mat-option>Select an option</mat-option>
          @for (serie of series; track serie) {
            <mat-option [value]="serie.idtClass">{{ serie.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="abs-component-title abs-padding">
      <span>Fees</span>
    </div>
    <div class="abs-dashed-box" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="8px">
        <app-output fxFlex="50" label="Management Fee">
          @if (selectedClass?.managementFee != null) {
            <span [ngClass]="{ strikethrough: selectedClass!.managementFeeWaived }">
              {{ selectedClass!.managementFee! / 100 | percent: '1.2-2' }}
            </span>
          } @else {
            -
          }
        </app-output>

        <mat-form-field fxFlex="50">
          <mat-label>Effective Management Fee</mat-label>
          <input
            matInput
            formControlName="effectiveManagementFee"
            type="text"
            currencyMask
            [options]="{ prefix: '', align: 'right' }"
            class="align-end"
            placeholder="0.00"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="8px">
        <app-output fxFlex="50" label="Incentive Fee">
          @if (selectedClass?.incentiveFee != null) {
            <span>
              {{ selectedClass!.incentiveFee! / 100 | percent: '1.2-2' }}
            </span>
          } @else {
            -
          }
          @if (selectedClass?.incentiveFee != null) {
            <span style="white-space: pre-line">{{ selectedClass! | hurdleData }}</span>
          }
        </app-output>

        <mat-form-field fxFlex="50">
          <mat-label>Effective Incentive Fee</mat-label>
          <input
            matInput
            formControlName="effectiveIncentiveFee"
            type="text"
            currencyMask
            [options]="{ prefix: '', align: 'right' }"
            class="align-end"
            placeholder="0.00"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
    </div>

    @if (!edition) {
      <div class="abs-component-title abs-padding">
        <span>First Trade</span>
      </div>
      <div class="abs-dashed-box" fxLayout="column" formGroupName="transaction">
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex>
            <mat-label>Transaction Pending Status</mat-label>
            <mat-select formControlName="pendingStatus">
              <mat-option>Select an option</mat-option>
              @for (status of transactionPendingStatus; track status) {
                <mat-option [value]="status">
                  {{ status | removeUnderline }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Transaction Type</mat-label>
            <mat-select formControlName="idtClientTransactionType">
              <mat-option>Select an option</mat-option>
              @for (type of transactionTypes$ | async; track type) {
                <mat-option [value]="type.idtClientTransactionType">
                  {{ type.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <!-- Transaction Mode -->
          @if (
            formGroup.get('transaction.idtClientTransactionType')?.value === transactionType.SUBSCRIPTION ||
            formGroup.get('transaction.idtClientTransactionType')?.value === transactionType.REDEMPTION
          ) {
            <mat-form-field fxFlex>
              <mat-label>Mode</mat-label>
              <mat-select formControlName="mode">
                <mat-option>Select an option</mat-option>
                @for (mode of transactionMode; track mode) {
                  <mat-option [value]="mode">
                    {{ mode | titlecase }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex>
            <mat-label>Initial amount</mat-label>
            <input matInput formControlName="valueLocal" type="text" currencyMask [options]="{ prefix: '', align: 'left' }" />
            <span matTextPrefix>$&nbsp;</span>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Currency</mat-label>
            <mat-select formControlName="idtAltCurrency">
              <mat-option>Select an option</mat-option>
              @for (currency of currencies$ | async; track currency) {
                <mat-option [value]="currency.idtCurrency">
                  {{ currency.abbreviation }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          @if (formGroup.get('transaction.idtAltCurrency')?.value !== 1) {
            <mat-form-field fxFlex>
              <mat-label>Initial amount (USD)</mat-label>
              <input matInput formControlName="value" type="text" currencyMask [options]="{ prefix: '', align: 'left' }" />
              <span matTextPrefix>$&nbsp;</span>
            </mat-form-field>
          }
        </div>
        <mat-form-field (click)="picker.open()">
          <mat-label>Effective Date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="transactionDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex>
            <mat-label>NAV</mat-label>
            <input matInput formControlName="nav" type="text" currencyMask [options]="{ prefix: '', align: 'left' }" />
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Shares</mat-label>
            <input matInput formControlName="shares" type="text" currencyMask [options]="{ prefix: '', align: 'left' }" />
          </mat-form-field>
          @if (formGroup.get('transaction.idtAltCurrency')?.value !== 1) {
            <mat-form-field fxFlex>
              <mat-label>Alt NAV</mat-label>
              <input matInput formControlName="altNav" type="text" currencyMask [options]="{ prefix: '', align: 'left' }" />
            </mat-form-field>
          }
        </div>
      </div>

      <div fxLayout="column" fxFlex>
        <div class="abs-component-title abs-padding">
          <span>Consultants</span>
        </div>

        <div class="abs-dashed-box" fxLayout="column">
          <mat-form-field>
            <mat-label>Consultant</mat-label>
            <mat-select formControlName="consultant" [compareWith]="compareConsultant">
              @for (option of consultants$ | async; track option) {
                <mat-option [value]="option">{{ option.consultantName }} - {{ option.consultantCompanyName }}</mat-option>
              }
              <mat-option [value]="0">No consultant</mat-option>
              <mat-option (click)="addNewConsultant()">New consultant</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    }
  </div>

  <div fxLayout="column" fxFlex>
    <div class="abs-component-title abs-padding">
      <span>Investment Configurations</span>
    </div>
    <div class="abs-dashed-box" fxLayout="column">
      <mat-form-field>
        <mat-label>Account Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="idtType">
          <mat-option>Select an option</mat-option>
          @for (option of accountTypes$ | async; track option) {
            <mat-option [value]="option.idtAccountType">
              {{ option.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field (click)="investmentDatePicker.open()">
        <mat-label>Initial Investment Date</mat-label>
        <input matInput [matDatepicker]="investmentDatePicker" formControlName="initialInvestment" />
        <mat-datepicker-toggle matSuffix [for]="investmentDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #investmentDatePicker></mat-datepicker>
      </mat-form-field>

      <app-user-select class="tw-flex tw-flex-1 tw-flex-col" label="Main lead" [control]="formGroup.controls.idtLead"></app-user-select>
      <app-user-select class="tw-flex tw-flex-1 tw-flex-col" label="Backup lead" [control]="formGroup.controls.idtLeadBackup"></app-user-select>

      <mat-form-field>
        <mat-label>New Issue Status</mat-label>
        <mat-select formControlName="newIssueStatus">
          <mat-option>Select an option</mat-option>
          @for (option of finra; track option) {
            <mat-option [value]="option">
              {{ option | removeUnderline | titlecase }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>ERISA Status</mat-label>
        <mat-select formControlName="erisaStatus">
          <mat-option>None</mat-option>
          <mat-option value="ERISA">ERISA</mat-option>
          <mat-option value="IRA">IRA</mat-option>
          <mat-option value="-">-</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Form PF</mat-label>
        <mat-select formControlName="formPf">
          <mat-option>Select an option</mat-option>
          @for (option of formPf$ | async; track option) {
            <mat-option [value]="option.formPf">
              {{ option.formPf }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>FINRA 5131</mat-label>
        <mat-select formControlName="finra5131">
          <mat-option>Select an option</mat-option>
          @for (option of finra; track option) {
            <mat-option [value]="option">
              {{ option | removeUnderline | titlecase }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Blue Sky State</mat-label>
        <mat-select formControlName="blueSkyState">
          <mat-option>Select an option</mat-option>
          @for (option of blueSkyOptions$ | async; track option) {
            <mat-option [value]="option.blueSkyState">
              {{ option.blueSkyState }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Region</mat-label>
        <mat-select formControlName="idtRegion">
          <mat-option>Select an option</mat-option>
          @for (option of regions$ | async; track option) {
            <mat-option [value]="option.idtRegion">
              {{ option.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <app-country-autocomplete [control]="$any(formGroup.get('idtCountry'))"></app-country-autocomplete>

      <div class="tw-flex tw-h-[30px] tw-flex-row tw-justify-between">
        <mat-slide-toggle formControlName="shareRebate">Share Rebate</mat-slide-toggle>

        <mat-slide-toggle formControlName="sideLetter">Side Letter</mat-slide-toggle>

        <mat-slide-toggle formControlName="ocio">Outsourced CIO</mat-slide-toggle>
      </div>

      @if (formGroup.controls.sideLetter.value) {
        <div class="tw-flex tw-flex-row">
          <mat-form-field class="tw-flex-1">
            <mat-label>Side letter summary</mat-label>
            <input matInput formControlName="sideLetterSummary" />
          </mat-form-field>
        </div>
      }
    </div>
  </div>
</form>
