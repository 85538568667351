<div fxLayout="column" fxFlex appDropzone (fileDropped)="onFileDropped($event)">
  <mat-toolbar color="primary">
    <span class="abs-margin-left">Upload Documents</span>
    <span fxFlex></span>
    <button mat-icon-button matDialogClose>
      <mat-icon fontIcon="mdi-close"></mat-icon>
    </button>
  </mat-toolbar>
  <app-progress-bar [loading]="processing || loading()"></app-progress-bar>

  @if (!loading()) {
    <div fxFlex fxLayout="column" class="abs-padding">
      <h3 class="abs-align-center abs-padding">
        You can drop the files here or
        <a (click)="fileDropRef.click()" class="abs-clickable abs-link">click here to browse</a>
      </h3>

      <div fxFlex fxLayout="column" class="abs-scroll-auto">
        @for (group of formGroups; track group; let index = $index) {
          <div fxLayout="column">
            <app-document-form
              [formGroup]="group"
              [removable]="true"
              [documentTypes]="documentTypes$ | async"
              (removed)="removeAt(index)"
            ></app-document-form>
          </div>
        }

        <input type="file" #fileDropRef multiple (change)="onFilesSelected($any($event.target).files)" style="display: none" />
      </div>
    </div>
  }
</div>

@if (formGroups.length > 0) {
  <div mat-dialog-actions>
    <span class="tw-flex-1"></span>
    <button mat-raised-button color="primary" (click)="upload()" [disabled]="processing">Upload</button>
  </div>
}
