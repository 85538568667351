

<app-custom-list-item [selected]="selected">
  <div fxFlex="100" fxLayout="row">
    <div fxFlex="70" fxLayout="column" class="column-name">
      <span>
        <div style="display: flex" class="abs-bold">
          @if (opportunity.idtContact) {
            <app-contact-context-menu
              class="abs-text-overflow-ellipsis"
              [displayText]="opportunity.contactName"
              [id]="opportunity.idtContact"
              [matTooltip]="opportunity.displayName"
            ></app-contact-context-menu>
          } @else {
            <span>-</span>
          }
        </div>
      </span>
      <span>
        <div style="display: flex" class="abs-color-faded">
          @if (opportunity.idtConsultantCompany) {
            <app-contact-context-menu
              class="abs-text-overflow-ellipsis"
              [displayText]="opportunity.consultantCompanyName"
              [id]="opportunity.idtConsultantCompany"
            ></app-contact-context-menu>
          } @else {
            <span>-</span>
          }
        </div>
      </span>
    </div>
    <div fxFlex="30" fxLayout="column" fxLayoutAlign="center">
      <div fxLayout="row" fxLayoutAlign="end">
        <span class="abs-bold">{{ opportunity.size | nearUnitNumber: '0.0-0' }}</span>
      </div>

      <div fxLayout="row" fxLayoutAlign="end">
        <span class="abs-color-faded abs-text-overflow-ellipsis">
          {{ opportunity.portfolio }}
        </span>
      </div>
    </div>

    <div fxFlex="60px" class="status" fxLayoutAlign="end center">
      <app-opportunity-status [status]="opportunity.status"></app-opportunity-status>
    </div>
  </div>
  <mat-divider></mat-divider>
</app-custom-list-item>
