<h1 mat-dialog-title>Add consultant</h1>
<div mat-dialog-content>
  <form id="consultantForm" (submit)="save()">
    <app-contact-consultant-control fxLayout="column" [formGroup]="formGroup"></app-contact-consultant-control>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button type="submit" form="consultantForm">Save</button>
</div>
