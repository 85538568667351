import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SuperSearchPageableResult } from 'app/modules/common/framework/pagination/pageable';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LogDetailViewTO } from '../../../log/model/log.model';
import { LogService } from '../../../log/services/log.service';
import { SearchService } from '../../services/search.service';

/**
 * Responsible to show the log list and log detail on sidenav
 */
@Component({
  selector: 'app-search-log-sidenav',
  templateUrl: './search-log-sidenav.component.html',
  styleUrls: ['./search-log-sidenav.component.scss'],
})
export class SearchLogSidenavComponent implements OnInit {
  logData!: PageableSuperSearchDataSource<LogDetailViewTO>;
  logDetails: LogDetailViewTO | null = null;

  idtLog: number | null = null;
  searchTerm!: string;
  index: number | null = null;

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private logService: LogService,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(
        tap((params) => {
          this.idtLog = Number(params.get('sidenav.idtLog'));
          this.index = Number(params.get('sidenav.index'));

          const searchTerm = params.get('sidenav.searchTerm');

          // eslint-disable-next-line eqeqeq
          if (searchTerm != this.searchTerm) {
            this.searchTerm = searchTerm || '';
          }

          if (this.idtLog) {
            this.showLogDetails();
          } else {
            this.logDetails = null;
          }
        }),
      )
      .subscribe();
  }

  /**
   * Searches for logs
   * @param term the term to search for
   */
  searchLogs(term: string | null): void {
    const self = this;
    const limit = 25;
    const page = Math.floor((this.index || 0) / limit);
    const searchTerm = term ?? '*';

    this.logData = new (class extends PageableSuperSearchDataSource<LogDetailViewTO> {
      fetch(page: number, limit: number): Observable<SuperSearchPageableResult<LogDetailViewTO>> {
        return self.searchService.executeLogSearch(searchTerm, page, limit).pipe(finalize(() => self.showLogDetails()));
      }
    })(limit, page);
  }

  /**
   * Shows log details when the click comes from outside the sidenav
   */
  showLogDetails(): void {
    if (this.idtLog) {
      this.logDetails = this.logService.getDetailsFromDatasource(this.idtLog, this.logData);
    }
  }
}
