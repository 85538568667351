<h1 mat-dialog-title>Create new balance</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup" id="newBalanceForm" fxLayout="column" (submit)="formGroup.valid && save()">
    <mat-form-field class="abs-clickable" (click)="reportingMonthPicker.open()">
      <mat-label>Reporting month</mat-label>
      <input matInput [matDatepicker]="reportingMonthPicker" class="abs-clickable" formControlName="reportingMonth" [max]="maxDate" />
      <mat-datepicker-toggle matSuffix [for]="reportingMonthPicker"></mat-datepicker-toggle>
      <mat-datepicker #reportingMonthPicker startView="year" (monthSelected)="chosenMonthHandler($event, reportingMonthPicker)"></mat-datepicker>
    </mat-form-field>

    @if (lastBalance$ | async; as balance) {
      <app-output label="Begin balance" class="abs-margin-double-bottom">
        {{ (edition ? balance.startBalance : balance.endBalance) | currency }}
      </app-output>
    } @else {
      <app-output label="Begin balance" class="abs-margin-double-bottom">{{ 0 | currency }}</app-output>
    }


    <mat-form-field>
      <mat-label>End balance</mat-label>
      <input
        matInput
        formControlName="endBalance"
        type="text"
        currencyMask
        [options]="{ prefix: '', align: 'right' }"
        class="align-end"
        placeholder="0.00"
        />
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between" class="abs-padding">
        <button mat-button mat-dialog-close color="warn" type="button">Cancel</button>
        <button mat-raised-button type="submit" color="primary" form="newBalanceForm">Save</button>
      </div>
    </form>
  </div>
