import { Component, DestroyRef } from '@angular/core';
import { Router } from '@angular/router';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { AuthService } from '../../business/auth/services/auth.service';
import { ContactNewDialogResponse } from '../../business/contact/components/create-new-contact/contact-new/contact-new.component';
import { ContactService } from '../../business/contact/services/contact.service';
import { DocumentService } from '../../business/document/services/document.service';
import { ResponsiveComponent } from '../components/responsive/responsive.component';
import { BreakpointService } from '../services/breakpoint.service';
import { NavigationHistoryService } from '../services/navigation-history.service';

/**
 * Sidenav component
 */
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends ResponsiveComponent {
  openSidebar = false;

  /**
   * The navigation history array.
   */
  history = this.navigationHistoryService.history;

  /**
   * Observable to identify if user is logged in.
   */
  public loggedIn$ = this.authService.loggedIn$;

  constructor(
    public breakpointService: BreakpointService,
    private navigationHistoryService: NavigationHistoryService,
    private authService: AuthService,
    private trackerService: TrackerService,
    private contactService: ContactService,
    private router: Router,
    private documentService: DocumentService,
    destroyRef: DestroyRef,
  ) {
    super(breakpointService, destroyRef);
  }

  /**
   * Navigate to a route, tracking the action.
   * @param item the identification of the item to be tracked
   */
  track(item: string): void {
    this.trackerService.event('footer_menu', 'select', { item });
  }

  /**
   * Handle resizes in the main body content. Sets the active size breakpoints in the breakpoint service.
   *
   * @param event the resize event
   */
  onResize(event: ResizeObserverEntry): void {
    this.breakpointService.setAbsBreakpoints(event.contentRect.width);
  }

  /**
   * On primary router outlet change, trigger history service.
   */
  onRouteChange(): void {
    this.navigationHistoryService.navigationComplete();
  }

  /**
   * Open dialog to create a new contact.
   */
  addContact(): void {
    this.track('New Contact');
    this.contactService
      .openCreateContactDialog()
      .afterClosed()
      .subscribe((data?: ContactNewDialogResponse) => {
        if (data?.idtContact) {
          if (data.new) {
            // Redirect to contact overview page with contact editing sidenav
            this.router.navigate([{ outlets: { sidenav: ['contact', data.idtContact, 'info'], primary: ['contact', data.idtContact] } }]);
          } else {
            // If existing contact, just go to contact overview page
            this.router.navigate(['contact', data.idtContact]);
          }
        }
      });
  }

  /**
   * Open dialog to upload a document.
   */
  addDocument(): void {
    this.track('Upload Doc');

    this.documentService.openUploadDocumentDialog();
  }
}
