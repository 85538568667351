@if (log(); as log) {
  <div fxFlex fxLayout="column">
    <mat-toolbar [color]="toolbarColor">
      @if (back.observed) {
        <button mat-icon-button (click)="onBack()" matTooltip="Go back">
          <mat-icon fontIcon="mdi-arrow-left"></mat-icon>
        </button>
      }
      <span fxFlex class="abs-margin-left">Log details</span>
      <button mat-icon-button (click)="edit()" matTooltip="Edit log">
        <mat-icon fontIcon="mdi-pencil"></mat-icon>
      </button>
      @if (log.opportunityCount > 0) {
        <button
          mat-icon-button
          matTooltip="Linked opportunities"
          [matBadge]="log.opportunityCount"
          matBadgePosition="below after"
          matBadgeSize="small"
          matBadgeColor="accent"
          (click)="openOpportunities()"
        >
          <mat-icon fontIcon="mdi-cash-100"></mat-icon>
        </button>
      }
      @if (log.documentCount > 0) {
        <button
          mat-icon-button
          matTooltip="Linked documents"
          [matBadge]="log.documentCount"
          matBadgePosition="below after"
          matBadgeSize="small"
          matBadgeColor="accent"
          (click)="openDocuments()"
        >
          <mat-icon fontIcon="mdi-file-document"></mat-icon>
        </button>
      }
      @if (closed.observed) {
        <button mat-icon-button (click)="onClose()" matTooltip="Close the log details">
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      }
    </mat-toolbar>
    @if (!contentExpanded()) {
      <div fxLayout="column" class="abs-padding">
        <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin">
          <mat-icon fontIcon="mdi-calendar" class="abs-margin-right"></mat-icon>
          <div fxFlex>
            <span>{{ log.reportedDate | date: 'MM/dd/yyyy' }}</span>
            @if (log.type && log.reportedDate) {
              <span class="abs-margin-half-horizontal">-</span>
            }
            <span>{{ log.type | removeUnderline | titlecase }}</span>
          </div>
          @if (log.highValueAsset) {
            <mat-icon fontIcon="mdi-star" class="abs-margin-half-left"></mat-icon>
          }
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin">
          <mat-icon fontIcon="mdi-account-multiple" class="abs-margin-right"></mat-icon>
          <div>
            <span class="abs-bold">{{ log.author }}</span>
            <span class="abs-margin-half-left">(author)</span>
            @for (participant of log.participants; track participant) {
              <span>, {{ participant.name }}</span>
            }
          </div>
        </div>
        @if (log.portfolios && log.portfolios.length > 0) {
          <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin">
            <mat-icon fontIcon="mdi-briefcase" class="abs-margin-right"></mat-icon>
            <mat-chip-listbox fxFlex>
              @if (log.customPortfolio) {
                <mat-chip>Custom portfolio</mat-chip>
              }
              @for (portfolio of log.portfolios; track portfolio.idtPortfolio) {
                <mat-chip>
                  {{ portfolio.shortName }}
                </mat-chip>
              }
            </mat-chip-listbox>
          </div>
        }
        @if (log.contacts && log.contacts.length > 0) {
          <app-log-contact-chips label="External Contacts" [contacts]="log.contacts" (selected)="navigateContact($event)" />
        }
        @if (log.relatedContacts && log.relatedContacts.length > 0) {
          <app-log-contact-chips label="Related Contacts" [contacts]="log.relatedContacts" (selected)="navigateContact($event)" />
        }
      </div>
    }
    <mat-divider class="abs-margin-bottom"></mat-divider>
    <div fxLayout="row" class="abs-margin-horizontal abs-padding-left">
      <div fxFlex>
        <div fxLayout="row" class="abs-margin-top">
          <span class="mat-subtitle-2">{{ log.subject }}</span>
        </div>
      </div>
      @if (contentExpanded()) {
        <button mat-icon-button (click)="toggleExpand()" matTooltip="Collapse content">
          <mat-icon fontIcon="mdi-arrow-collapse"></mat-icon>
        </button>
      } @else {
        <button mat-icon-button (click)="toggleExpand()" matTooltip="Expand content">
          <mat-icon fontIcon="mdi-arrow-expand"></mat-icon>
        </button>
      }
    </div>

    <div fxFlex fxLayout="column" class="abs-padding-horizontal abs-margin-bottom">
      <div fxFlex fxLayout="column" class="abs-padding abs-scroll-auto-y" [innerHtml]="content()" [ngClass]="{ 'body-content': pureText() }"></div>
    </div>
  </div>
}
