<div class="tab-tools abs-margin-right" fxLayoutAlign="end center">
  @if (!selectedLog) {
    @if (showChip) {
      <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
        @if (portfolio) {
          <mat-chip (removed)="removePortfolio()">
            {{ portfolio.ticker }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-chip>
        }
        @if (client) {
          <mat-chip (removed)="removeClient()">
            {{ client.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-chip>
        }
        @if (consultant) {
          <mat-chip (removed)="removeConsultant()">
            {{ consultant.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-chip>
        }
        @if (opportunity) {
          <mat-chip (removed)="removeOpportunity()">
            Opportunity for {{ opportunity.contactName || opportunity.consultantCompanyName }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-chip>
        }
        @if (opportunityConsultant) {
          <mat-chip (removed)="removeOpportunity()">
            Opportunities for {{ opportunityConsultant.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close"></mat-icon>
            </button>
          </mat-chip>
        }
      </mat-chip-listbox>
    }
    <div fxFlex></div>
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Filter logs">
      <mat-icon fontIcon="mdi-filter-variant"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="setLogType()" fxFlex mat-menu-item>
        <div fxFlex class="abs-margin-right">All Logs</div>
        <span>({{ logCount }})</span>
      </button>
      @for (logTotal of $logTotals | async; track logTotal) {
        <button (click)="setLogType(logTotal.type)" fxFlex mat-menu-item>
          <div fxFlex class="abs-margin-right">{{ getLogTypeLabel(logTotal.type) }}</div>
          <span>({{ logTotal.total }})</span>
        </button>
      }
    </mat-menu>
  }
</div>

<app-log-list fxFlex fxLayout="column" [logData]="logData" (clicked)="onLogClick($event)" [fxShow]="!selectedLog"></app-log-list>

@if (selectedLog) {
  <app-log-details fxFlex fxLayout="column" [log]="selectedLog" (closed)="closeLog()" />
}
