import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PageableSuperSearchDataSource } from 'app/modules/common/framework/pagination/pageable-super-search-datasource';
import { RemoveUnderlinePipe } from 'app/modules/common/framework/pipe/remove-underline.pipe';
import { SidenavService } from 'app/modules/common/framework/services/sidenav.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LogDetailViewTO } from '../../model/log.model';

/**
 * Generics Log sidenav component
 */
@Component({
  selector: 'app-log-sidenav',
  templateUrl: './log-sidenav.component.html',
  styleUrls: ['./log-sidenav.component.scss'],
  providers: [RemoveUnderlinePipe],
})
export class LogSidenavComponent {
  @Input()
  logData?: PageableSuperSearchDataSource<LogDetailViewTO>;

  @Input()
  logDetails: LogDetailViewTO | null = null;

  /**
   * The log type filtered by.
   */
  @Input()
  type?: string | null;

  /**
   * Emits the value typed on search field
   */
  @Output()
  searchTerm = new EventEmitter<string>();

  /**
   * Event emitter that emits when the filtered type is removed.
   */
  @Output()
  searchTypeRemoved = new EventEmitter<void>();

  /**
   * The search term provided from parent component.
   */
  private _term?: string;

  /**
   * Getter for the search term provided from the parent component.
   */
  get term() {
    return this._term;
  }

  /**
   * Setter for the term provided by the parent component.
   */
  @Input()
  set term(value: string | undefined) {
    this._term = value;

    this.searchField.setValue(this.term || '');
  }

  searchField = new FormControl<string>('', { nonNullable: true });

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private destroyRef: DestroyRef,
  ) {
    this.searchField.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000), distinctUntilChanged()).subscribe((term) => {
      this.searchTerm.emit(term);
    });
  }

  /**
   * Closes the sidenav
   */
  onCloseClick(): void {
    this.sidenavService.close();
  }

  /**
   * Shows log details by click
   * @param eventData
   */
  onLogClick(eventData: { log: LogDetailViewTO; index: number }): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { 'sidenav.idtLog': eventData.log.idtLog },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Sets the searchField to empty
   */
  cleanUp(): void {
    this.searchField.setValue('');
  }

  /**
   * Sets the log with its details
   * @param log the log to show its content
   */
  setLogDetails(log: LogDetailViewTO): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { 'sidenav.idtLog': log.idtLog },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Return to the list of logs.
   */
  backToList(): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { 'sidenav.idtLog': null },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Remove log type filter.
   */
  removeTypeFilter(): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { 'sidenav.logType': null } });
  }
}
