<div class="search-field" fxFlex fxLayoutAlign="end" fxLayoutAlign.gt-xs="center">
  <mat-form-field fxHide [fxShow]="showSearch" fxShow.gt-xs ngClass.xs="full-width" subscriptSizing="dynamic">
    @if (!searchField.value) {
      <mat-icon matPrefix fontIcon="mdi-magnify"></mat-icon>
    }
    @if (searchField.value) {
      <mat-icon matPrefix (click)="close()" fontIcon="mdi-close" class="abs-clickable"></mat-icon>
    }
    <input matInput type="text" placeholder="Search" [formControl]="searchField" #searchInput (focusout)="focusout()" />
  </mat-form-field>

  <button mat-icon-button (click)="displaySearch()" fxShow [fxHide]="showSearch" fxHide.gt-xs>
    <mat-icon fontIcon="mdi-magnify"></mat-icon>
  </button>
</div>
