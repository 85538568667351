import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LogDetailViewTO } from '../model/log.model';

/**
 * Service to get logs for a single contact. Allows trigerring refreshes from any component.
 */
@Injectable({
  providedIn: 'root',
})
export class LogContactService {
  constructor(private http: HttpClient) {}

  /**
   * Gets a list of Logs for the provided contact.
   *
   * @param id contact id
   * @param page the page to get
   * @param limit the size of the page
   * @returns an observable that resolves to the page receivied from the server
   */
  public findLogs(id: number, page: number = 0, limit: number = 10): Observable<Pageable<LogDetailViewTO>> {
    return this.http.get<Pageable<LogDetailViewTO>>(`${environment.apiUrl}/contact/${id}/logs`, {
      params: {
        page: page.toString(),
        limit: limit.toString(),
      },
    });
  }
}
