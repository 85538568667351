@if (contact(); as contact) {
  <app-custom-list-item [routerLink]="handleClicks() ? ['/contact', contact.idtContact] : []" class="contact" [ngClass]="{ active: active() }">
    <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="abs-scroll-hidden">
      <!-- Avatar -->
      <div class="tw-w-[30px] tw-flex-none">
        @if (contact.picture) {
          <app-contact-image matListItemAvatar [idtContact]="contact.idtContact"></app-contact-image>
        } @else {
          @if (contact.type === contactTypeEnum.PERSON && contact.name) {
            <div matListItemAvatar class="text-avatar">
              {{ contact.name | acronym }}
            </div>
          }
          @if (contact.type === 'COMPANY') {
            <app-company-icon matListItemAvatar [isParentCompany]="!contact.idtParentCompany"></app-company-icon>
          }
        }
      </div>

      <!-- Name/Company -->
      <div class="abs-margin-left abs-scroll-hidden tw-flex tw-flex-1 tw-flex-col">
        <app-contact-context-menu
          [displayText]="contact.name"
          [id]="contact.idtContact"
          [disabled]="!canSeeContextMenu() || !showContextMenus()"
        ></app-contact-context-menu>

        <div fxLayout="column" class="mat-caption">
          @if (contact.type === 'PERSON' && contact.companyName) {
            <app-contact-context-menu
              [displayText]="contact.companyName"
              [id]="contact.idtCompanyContact"
              [disabled]="!showContextMenus()"
            ></app-contact-context-menu>
          }
          @if (contact.businessRole) {
            <span class="abs-text-overflow-ellipsis">{{ contact.businessRole }}</span>
          }
          @if ($any(contact).relationship) {
            <span>{{ $any(contact).relationship }}</span>
          }
        </div>
      </div>

      <!-- Email/Phone -->

      @if (showPhoneAndEmail()) {
        <!-- Mobile version -->
        @if (dense()) {
          <div class="abs-margin-half-left abs-scroll-hidden tw-flex tw-w-[100px] tw-flex-row" (click)="$event.stopPropagation()" fxLayoutAlign="end">
            @if (contact.email) {
              <a mat-icon-button [routerLink]="" (click)="clickEmail()">
                <mat-icon fontIcon="mdi-email"></mat-icon>
              </a>
            }
            @if (contact.phone) {
              <a mat-icon-button [routerLink]="" (click)="clickPhone()">
                <mat-icon fontIcon="mdi-phone"></mat-icon>
              </a>
            }
          </div>
        }

        <!-- Regular version -->
        @if (!dense()) {
          <div class="abs-margin-half-left abs-scroll-hidden tw-flex tw-min-w-[100px] tw-flex-1 tw-flex-col" (click)="$event.stopPropagation()">
            @if (contact.email) {
              <a [routerLink]="" (click)="clickEmail()" fxLayoutAlign="start start" class="abs-margin-half-bottom">
                <mat-icon fxFlex="none" class="abs-secondary abs-margin-half-right" fontIcon="mdi-email"></mat-icon>
                <span matTooltip="{{ contact.email }}" class="abs-text-overflow-ellipsis">
                  {{ contact.email }}
                </span>
              </a>
            }
            @if (contact.phone) {
              <a [routerLink]="" (click)="clickPhone()" fxLayoutAlign="start start">
                <mat-icon fxFlex="none" class="abs-secondary abs-margin-half-right" fontIcon="mdi-phone"></mat-icon>
                <span class="abs-text-overflow-ellipsis">{{ contact.phone }}</span>
              </a>
            }
          </div>
        }
      }

      @if (showInvestmentStatus()) {
        <div class="tw-w-[70px] tw-flex-none">
          @if (contact.investmentStatus && contact.investmentStatus !== 'Not Invested') {
            <span
              [ngClass]="{
                'abs-color-positive': contact.investmentStatus === 'Invested',
                'abs-color-faded': contact.investmentStatus === 'Former Investor',
              }"
            >
              {{ contact.investmentStatus }}
            </span>
          }
        </div>
      }

      <!-- Flag key contact icon-->
      <div class="icon-wrapper abs-margin-half-left tw-flex-none tw-items-end tw-justify-center">
        @if (contact.keyContact) {
          <mat-icon fontIcon="mdi-star"></mat-icon>
        }
      </div>
    </div>
    <mat-divider></mat-divider>
  </app-custom-list-item>
}
