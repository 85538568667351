<div fxFlex>
  <div fxLayoutAlign="start center" class="icon-button">
    @if (isConsultant) {
      <button
        mat-icon-button
        (click)="onIconClick(accountBalanceGroupBy.contactName)"
        [ngClass]="{ 'icon-button-active': groupBy === accountBalanceGroupBy.contactName }"
        matTooltip="List by client"
      >
        <mat-icon fontIcon="mdi-account-group"></mat-icon>
      </button>
    }
    <button
      mat-icon-button
      (click)="onIconClick(accountBalanceGroupBy.portfolioTicker)"
      class="abs-margin-left"
      [ngClass]="{ 'icon-button-active': groupBy === accountBalanceGroupBy.portfolioTicker }"
      matTooltip="List by portfolio"
    >
      <mat-icon fontIcon="mdi-briefcase"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onIconClick(undefined)"
      class="abs-margin-left"
      [ngClass]="{ 'icon-button-active': groupBy == undefined }"
      matTooltip="List by account"
    >
      <mat-icon fontIcon="mdi-view-list"></mat-icon>
    </button>
  </div>

  <mat-form-field (click)="picker.open()" class="abs-clickable form-field-date abs-margin-left" subscriptSizing="dynamic">
    <mat-label>Select Month</mat-label>
    <input matInput [matDatepicker]="picker" [formControl]="reportingMonthDateField" [matDatepickerFilter]="maxFilter" />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="year" (monthSelected)="chosenMonthHandler($event, picker)"></mat-datepicker>
  </mat-form-field>

  <span fxFlex></span>

  @if (totalAum) {
    <span fxLayoutAlign="center center" class="abs-bold">
      <span>Total AUM:</span>
      @if (!missingEstimates) {
        <span class="abs-color-positive abs-container-xs">&nbsp;{{ totalAum | nearUnitNumber: '0.0-0' }}{{ hasEstimates ? '*' : '' }}</span>
        <span class="abs-color-positive abs-container-gt-xs">&nbsp;{{ totalAum | number: '0.0-0' }}{{ hasEstimates ? '*' : '' }}</span>
      } @else {
        <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn abs-margin-left" matTooltip="Missing estimates in ABSolute"></mat-icon>
      }
    </span>
  }
</div>
