@if (displayedColumns$ | async; as displayedColumns) {
  <table mat-table matSort [dataSource]="dataSource" (matSortChange)="onSortChange($event)" class="single-line-table">
    @for (column of displayedColumns; track column) {
      <ng-container [matColumnDef]="'filter-' + column.field" [sticky]="isPinned(column)">
        <th mat-header-cell *matHeaderCellDef class="filter-cell">
          @if (column.filterable) {
            <app-entity-table-filter
              [column]="column"
              (valueChanged)="filterChanged($event)"
              [portfolioId]="portfolioFilter"
              [classId]="classFilter"
              [type]="type"
              [filter]="buildFilter(column.field)"
            ></app-entity-table-filter>
          }
        </th>
      </ng-container>
      <ng-container [matColumnDef]="column.field" [sticky]="isPinned(column)">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sortable" [arrowPosition]="isNumeric(column) ? 'before' : 'after'">
          {{ column.fieldName }}
          <mat-icon
            class="pin"
            [ngClass]="{ pinned: isLastPinned(column) }"
            [fontIcon]="isLastPinned(column) ? 'mdi-pin-off' : 'mdi-pin'"
            (click)="togglePin($event, column)"
          ></mat-icon>
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'align-end': isNumeric(column) }">
          @if (getValue(element, column); as value) {
            <span [matTooltipShowDelay]="2000" [matTooltip]="value" matTooltipClass="multline-tooltip">
              <!-- context menu link -->
              @if (column.contextMenu) {
                <!-- contact context menu -->
                @if (column.contextMenu === 'contact') {
                  <app-contact-context-menu [displayText]="value" [id]="+getId(element, column)"></app-contact-context-menu>
                }
                @if (column.contextMenu === 'portfolio') {
                  <app-portfolio-context-menu [displayText]="value" [id]="+getId(element, column)"></app-portfolio-context-menu>
                }
              } @else {
                <!-- regular link -->
                @if (column.link) {
                  <a [routerLink]="[getPath(element, column), getId(element, column)]" class="abs-link">
                    {{ value }}
                  </a>
                }
                <!-- sidenav link -->
                @if (column.sidenav) {
                  <a [routerLink]="['', { outlets: { sidenav: [column.sidenav, getId(element, column)] } }]" class="abs-link">
                    {{ value }}
                  </a>
                }
                <!-- value -->
                @if (!(column.link || column.sidenav)) {
                  <span>{{ value }}</span>
                }
              }
            </span>
          }
        </td>
        <td mat-footer-cell *matFooterCellDef [ngClass]="{ 'align-end': isNumeric(column) }" class="mat-body-strong">
          {{ column.summable && !!totals && totals[column.field] != null ? transformValue(totals[column.field], column) : '' }}
        </td>
      </ng-container>
    }
    <ng-container matColumnDef="filter-actions" [sticky]="pinIndex != null">
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="actions" [sticky]="pinIndex != null">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="goToDetails(element)" matTooltip="Go to details" matTooltipPosition="right">
          <mat-icon fontIcon="mdi-dots-horizontal-circle-outline"></mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    @if (showFilters) {
      <tr mat-header-row *matHeaderRowDef="displayedFilterColumns; sticky: true" class="filter-row"></tr>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumnsPaths; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsPaths"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumnsPaths; sticky: true"></tr>
  </table>
}

@if (dataSource && !dataSource.loading && dataSource.entities.length === 0) {
  <div fxLayout="row" fxLayoutAlign="center" class="abs-padding">No accounts found matching search criteria</div>
}
