<mat-toolbar class="abs-bold footer-toolbar">
  <mat-toolbar-row>
    <div class="tw-w-1/2">
      @if (index()! > 0) {
        <div class="abs-clickable tw-flex tw-flex-row tw-items-center" (click)="navigate.emit(index()! - 1)">
          <mat-icon fontIcon="mdi-chevron-left" class="tw-ml-0.5 tw-mr-0.5"></mat-icon>
          <span>{{ list()[index() - 1][displayProperty()] }}</span>
        </div>
      }
    </div>
    <div class="tw-w-1/2">
      @if (index()! < list().length - 1) {
        <div class="abs-clickable tw-flex tw-flex-row tw-items-center tw-justify-end" (click)="navigate.emit(index()! + 1)">
          <span>{{ list()[index() + 1][displayProperty()] }}</span>
          <mat-icon fontIcon="mdi-chevron-right" class="tw-ml-0.5 tw-mr-0.5"></mat-icon>
        </div>
      }
    </div>
  </mat-toolbar-row>
</mat-toolbar>
