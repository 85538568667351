import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-opportunity-list-header',
  templateUrl: './opportunity-list-header.component.html',
  styleUrls: ['./opportunity-list-header.component.scss'],
})
export class OpportunityListHeaderComponent {
  @Input()
  dense = false;
}
