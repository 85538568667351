import { Injectable } from '@angular/core';
import { Chip } from '../model/chip.model';
import { TotalType } from '../model/total-type.model';

/**
 * Service class to handle with chips
 */
@Injectable({
  providedIn: 'root',
})
export class ChipService {
  /**
   * Gets the list of chips from total type
   * @param totalType the list of total by type
   * @returns Chip list | []
   */
  public getChipListOfTotalType(totalType: TotalType[]): Chip[] {
    if (totalType.length === 0 || totalType[0].total === 0) {
      return [];
    }
    return totalType.map((item) => ({
      prefix: item.total,
      label: item.type,
      value: item.value,
    }));
  }
}
