import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Company } from '../model/company.model';

/**
 * Service to handle with the api related to company
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  /**
   * Finds the companies by legal name
   *
   * @param legalName the legal name to be searched
   * @returns the list of found companies
   */
  public search(filter: DataFilter): Observable<Company[]> {
    return this.http.get<Company[]>(`${environment.apiUrl}/company`, {
      params: { filter: filter.encode() },
    });
  }
}
