<app-custom-list fxFlex fxLayout="column">
  <div class="draft-list abs-scroll-auto">
    @for (draft of draftData | async; track draft) {
      <app-log-list-draft-item
        class="abs-opacity"
        [draft]="draft"
        (discard)="onDraftDiscarded($event)"
        [routerLink]="['', { outlets: { sidenav: ['log', 'draft-edit', draft.id] } }]"
      ></app-log-list-draft-item>
    }
  </div>

  @if (logData && !logData.hasData) {
    <app-no-data [entity]="'Logs'"></app-no-data>
  }

  <cdk-virtual-scroll-viewport
    fxFlex
    fxLayout="column"
    itemSize="80"
    [ngClass]="{ 'abs-scroll-hidden': !logData || (logData.loading && logData.totalElements === 0) }"
  >
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader
      [lines]="4"
      [height]="80"
      [items]="20"
      [loading]="!logData || (logData.loading && logData.totalElements === 0)"
    ></app-list-skeleton-loader>

    <app-log-list-item *cdkVirtualFor="let log of logData; let index = index" [log]="log" (click)="onClick(log, index)"></app-log-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
