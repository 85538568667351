<div mat-dialog-content fxLayout="column">
  <form [formGroup]="formGroup" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="abs-margin-right">Set the end date for this job</div>
      <mat-form-field>
        <mat-label>End</mat-label>
        <input matInput [matDatepicker]="end" formControlName="endDate" />
        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
        <mat-datepicker #end disabled="false"></mat-datepicker>
        <mat-error>Invalid Date</mat-error>
      </mat-form-field>
    </div>

    <mat-divider class="abs-margin-bottom"></mat-divider>

    <div fxLayout="column">
      @if (emailGroups && emailGroups.length > 0) {
        <p class="abs-margin-top">This contact is a member of these groups</p>
        <div fxLayout="column">
          <div fxLayout="row" class="abs-padding-horizontal" style="margin-right: 6px">
            <div class="mat-header-cell" fxLayout="column" fxFlex="50">
              <div>Group name</div>
              <div>Tags</div>
            </div>
            <div class="mat-header-cell" fxLayout="column" fxFlex="50">
              <div>Portfolio</div>
              <div>Class</div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <app-custom-list [selectable]="false" mode="dense" class="abs-margin-bottom" class="abs-scroll-auto" style="max-height: 300px">
            @for (emailGroup of emailGroups; track emailGroup) {
              <app-custom-list-item>
                <div fxLayout="column" fxFlex="50">
                  <div class="abs-text-overflow-ellipsis" [matTooltip]="emailGroup.name">{{ emailGroup.name }}</div>
                  <div class="abs-text-overflow-ellipsis abs-color-faded">{{ emailGroup.tags.join(', ') || '-' }}</div>
                </div>
                <div fxLayout="column" fxFlex="50">
                  <div class="abs-text-overflow-ellipsis" [matTooltip]="emailGroup.portfolio || ''">
                    {{ emailGroup.portfolio || '-' }}
                  </div>
                  <div class="abs-text-overflow-ellipsis abs-color-faded">{{ emailGroup.portfolioClass || '-' }}</div>
                </div>
                <mat-divider></mat-divider>
              </app-custom-list-item>
            }
          </app-custom-list>
        </div>
      }

      @if (emailData || phoneData) {
        <div class="abs-margin-vertical">
          {{ !!emailGroups && emailGroups.length > 0 ? 'And its' : "This contact's" }} primary
          @if (emailData) {
            <span>email</span>
          }
          @if (emailData && phoneData) {
            <span>&nbsp;and&nbsp;</span>
          }
          @if (phoneData) {
            <span>phone</span>
          }
          {{ emailData && phoneData ? 'are' : 'is' }}
        </div>
        @if (emailData) {
          <div>
            <span class="abs-bold">{{ emailData.type }}</span>
            email
            <span class="abs-bold">{{ emailData.value }}</span>
          </div>
        }
        @if (phoneData) {
          <div>
            <span class="abs-bold">{{ phoneData.type }}</span>
            {{ phoneData.channel | lowercase }}
            <span class="abs-bold">{{ phoneData.value }}</span>
          </div>
        }
      }
    </div>

    @if (hasEmailData()) {
      <p class="abs-margin-top">
        You can select to delete its email, phone and remove the contact from the listed dist. groups, besides setting the end date.
      </p>
      <p class="abs-align-center abs-padding abs-bold">Which action do you want to perform?</p>
    }
  </form>
  <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center" fxLayoutGap="0" fxLayoutGap.gt-sm="32px">
    <button mat-stroked-button mat-dialog-close color="primary" [ngClass.gt-sm]="'abs-margin-top'">Cancel</button>
    <button mat-raised-button color="primary" (click)="confirm(false)" class="abs-margin-top">
      {{ hasEmailData() ? 'Set end date only' : 'Set end date' }}
    </button>

    @if (hasEmailData()) {
      <button mat-raised-button color="primary" (click)="confirm(true)" class="abs-margin-top">Remove from dist. groups</button>
    }
  </div>
  @if (hasEmailData()) {
    <div class="abs-align-center abs-margin-top" fxLayoutAlign="center" fxLayoutAlign.gt-sm="end" fxLayout="row">
      <span fxFlex.gt-sm="33">System will delete primary email, phone and remove from distribution groups</span>
    </div>
  }
</div>
