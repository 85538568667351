import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { BasePortfolio, PortfolioTO, customPortfolioEntity } from '../../model/portfolio.model';
import { PortfolioService } from '../../services/portfolio.service';

/**
 * Component for a portfolio autocomplete form field.
 */
@Component({
  selector: 'app-portfolio-autocomplete',
  templateUrl: './portfolio-autocomplete.component.html',
  styleUrls: ['./portfolio-autocomplete.component.scss'],
})
export class PortfolioAutocompleteComponent implements OnInit {
  /**
   * The form control holding the selected values.
   */
  @Input()
  control!: FormControl<PortfolioTO[]>;

  /**
   * Whether to include "custom portfolio" option.
   */
  @Input()
  includeCustomPortfolio = false;

  /**
   * Form control for the search input.
   */
  searchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The list of portfolios options.
   */
  portfolios$?: Observable<BasePortfolio[]>;

  /**
   * Element reference to the input field.
   */
  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;

  constructor(
    private portfolioService: PortfolioService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.portfolioService.findAll('shortName,ASC', new DataFilter().equals('inactive', false, 'boolean')).subscribe((allPortfolios) => {
      if (this.includeCustomPortfolio) {
        allPortfolios.push(customPortfolioEntity as PortfolioTO);
      }

      this.portfolios$ = this.searchControl.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(''),
        debounceTime(500),
        map((searchTerm) => {
          return searchTerm ? allPortfolios.filter((p) => p.shortName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) : allPortfolios;
        }),
      );
    });
  }

  /**
   * Remove a portfolio from the selected list.
   *
   * @param portfolio the portfolio to be removed
   */
  remove(portfolio: PortfolioTO): void {
    this.control.setValue(this.control.value.filter((p) => p.idtPortfolio !== portfolio.idtPortfolio));
  }

  /**
   * Adds a portfolio to the selected list when an option is selected.
   *
   * @param event the event that triggered the selection
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    this.input.nativeElement.value = '';
    this.searchControl.setValue('');

    // Hack to keep the autocomplete panel open after selecting a tag
    setTimeout(() => {
      this.input.nativeElement.blur();
      this.input.nativeElement.focus();
    });

    if (!this.control.value.some((p) => p.idtPortfolio === event.option.value.idtPortfolio)) {
      this.control.setValue([...this.control.value, event.option.value]);
    }
  }
}
