<app-custom-list [selectable]="false" mode="dense" fxFlex>
  @for (group of groups; track group) {
    <app-custom-list-item>
      <span fxFlex class="abs-margin-half-right">
        {{ group.description }}
      </span>
      @if (wasInGroup(group.idtEmailGroup)) {
        <mat-icon fontIcon="mdi-alert-outline" matTooltip="This contact was once a group member" class="abs-color-warn"></mat-icon>
      } @else {
        <mat-icon></mat-icon>
      }
      <div fxFlex="70px" fxLayoutAlign="end">
        @if (isInGroup(group.idtEmailGroup)) {
          <button mat-button color="primary" (click)="removeFromGroup(group.idtEmailGroup)">Remove</button>
        } @else {
          <button mat-button color="primary" (click)="addToGroup(group.idtEmailGroup)">Add</button>
        }
      </div>
      <mat-divider></mat-divider>
    </app-custom-list-item>
  }
</app-custom-list>
