<div fxFlex fxLayout="column">
  <!-- logs list -->
  <div fxFlex fxLayout="column" fxShow="{{ !logDetails }}">
    <mat-toolbar color="primary">
      <span fxFlex class="abs-margin-left">Logs</span>
      <button mat-icon-button (click)="onCloseClick()" matTooltip="Close the sidebar">
        <mat-icon fontIcon="mdi-close"></mat-icon>
      </button>
      <button mat-mini-fab color="white" [routerLink]="['', { outlets: { sidenav: ['log', 'new'] } }]" class="bottom-right" matTooltip="Create log">
        <mat-icon fontIcon="mdi-plus"></mat-icon>
      </button>
    </mat-toolbar>
    @if (logData?.loading) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }

    @if (type) {
      <mat-chip-listbox class="abs-padding">
        <mat-chip (removed)="removeTypeFilter()">
          {{ type | titlecase }}
          <button matChipRemove>
            <mat-icon fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-chip>
      </mat-chip-listbox>
    }

    <mat-form-field class="abs-margin vertical-align">
      @if (!searchField.value) {
        <mat-icon matPrefix fontIcon="mdi-magnify"></mat-icon>
      }
      @if (searchField.value) {
        <mat-icon matPrefix (click)="cleanUp()" fontIcon="mdi-close" class="abs-clickable"></mat-icon>
      }
      <input matInput type="text" placeholder="Search for logs" [formControl]="searchField" />
    </mat-form-field>
    <ng-container>
      <app-log-list fxFlex fxLayout="column" [showDrafts]="true" [logData]="logData" (clicked)="onLogClick($event)"></app-log-list>
    </ng-container>
  </div>
  <!-- log detail -->
  @if (logDetails) {
    <div fxFlex fxLayout="column">
      <app-log-details fxFlex fxLayout="column" toolbarColor="primary" [log]="logDetails" (back)="backToList()" (closed)="onCloseClick()" />
      <app-log-list-navigator [items]="logData!" [current]="logDetails" (body)="setLogDetails($event)"></app-log-list-navigator>
    </div>
  }
</div>
