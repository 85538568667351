<div fxFlex fxLayout="column">
  <mat-toolbar color="primary">
    @if (showBackButton()) {
      <button mat-icon-button (click)="goBack()">
        <mat-icon fontIcon="mdi-arrow-left" class="abs-margin-right"></mat-icon>
      </button>
    }
    <div class="tw-ml-1 tw-flex tw-flex-1 tw-flex-col">
      <span>{{ header() }}</span>
      <span class="mat-subtitle-1">{{ subheader() }}</span>
    </div>

    <!-- Optional content for toolbar buttons -->
    <ng-content select="[toolbar]"></ng-content>

    <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
      <mat-icon fontIcon="mdi-close"></mat-icon>
    </button>
  </mat-toolbar>
  <app-progress-bar [loading]="loading()"></app-progress-bar>

  <div class="abs-scroll-auto tw-flex tw-flex-1 tw-flex-col">
    <ng-content></ng-content>
  </div>

  <ng-content select="[footer]"></ng-content>
</div>
