import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to join an array with the provided separator.
 */
@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(input: string[], separator: string = ''): string {
    if (!Array.isArray(input)) {
      return input;
    }
    const values = input.filter((s) => s?.length > 0);
    return values.join(separator);
  }
}
