import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Balance } from '../../client/model/client-balance.model';
import { ClientTransactionViewTO } from '../../client/model/client.model';
import { Column } from '../../entity-table/model/column.model';
import { CustomTableService } from '../../entity-table/services/custom-table';
import { Account, AccountIndexedDTO, AccountType, Category } from '../model/account.model';

/**
 * Service to interact with the server for account related
 */
@Injectable({
  providedIn: 'root',
})
export class AccountService implements CustomTableService<{ [key: string]: any }> {
  constructor(private http: HttpClient) {}

  /**
   * Search for accounts based on the provided filters.
   */
  getPage(page: number, size: number, sort: string, filter: string, selectedColumns: string[]): Observable<Pageable<{ [key: string]: any }>> {
    return this.http.post<Pageable<{ [key: string]: any }>>(`${environment.apiUrl}/account/search?page=${page}&size=${size}&sort=${sort}`, {
      filter,
      selectedColumns,
    });
  }

  /**
   * Get all distinct values for the provided column (groupBy param) applying the provided filter expression.
   *
   * @param filter a string formatted to apply multiple filters (Example: portfolio.shortName|eq|thename|string)
   * @param groupBy the column to get
   * @param sumColumns the columns to get the sum
   * @returns an observable that resolves to a list of objects with the values of the column and calculated sums
   */
  getGroups(filter: string, groupBy: string, sumColumns: string[] = []): Observable<{ [key: string]: any }[]> {
    return this.http.post<{ [key: string]: any }[]>(`${environment.apiUrl}/account/searchGroup`, {
      filter,
      groupBy,
      sumColumns,
    });
  }

  /**
   * Get the total values for a few numeric columns.
   *
   * @param filter the filters to apply to the query
   * @returns observable that resolves to the server response and immediatelly completes
   */
  getBalanceAndCount(filter: string = ''): Observable<{ [key: string]: number }> {
    return this.http.post<{ [key: string]: number }>(`${environment.apiUrl}/account/search/balance`, { filter });
  }

  /**
   * Get the total values for the provided columns.
   *
   * @param filter the filters to apply to the query
   * @param sumColumns the path to the columns to be summed
   * @returns an observable that resolves to an object with the totals for each provided column
   */
  getTotals(filter: string = '', sumColumns: string[] = []): Observable<{ [key: string]: number }> {
    return this.http.post<{ [key: string]: number }>(`${environment.apiUrl}/account/search/totals`, {
      filter,
      sumColumns,
    });
  }

  /**
   * Gets the latest reporting month for all clients.
   *
   * @return reporting month.
   */
  getLatestDate(): Observable<DateTime> {
    return this.http.get<string>(`${environment.apiUrl}/account/latest-date`).pipe(map((date) => DateTime.fromFormat(date, 'yyyy-MM-dd')));
  }

  /**
   * Request to export accounts to an excel file.
   *
   * @param filter the filters to apply to the query
   * @param selectedColumns the solumns to select
   * @param sort the column and direction to sort
   */
  export(filter: string, selectedColumns: string[], sort: string): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.apiUrl}/account/search/export`,
      { selectedColumns, filter },
      { params: { sort }, responseType: 'blob' as 'json' },
    );
  }

  /**
   * Get all available categories.
   *
   * @returns the list of categories found wrapped in an observable
   */
  getCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.apiUrl}/account/category`);
  }

  /**
   * Get all available subcategories.
   *
   * @returns the list of subcategories found wrapped in an observable
   */
  getSubcategories(): Observable<Category[]> {
    return this.http.get<Category[]>(`${environment.apiUrl}/account/subcategory`);
  }

  /**
   * Gets a single account from the server.
   *
   * @param id the id of the account
   * @returns the account found wrapped in an observable
   */
  getOne(id: number): Observable<Account> {
    return this.http.get<Account>(`${environment.apiUrl}/account/${id}`);
  }

  /**
   * Save an account with a initial transaction.
   *
   * @param account the account data
   * @param transaction the initial transaction
   * @param consultant the consultant data
   * @returns the account created wrapped in an observable
   */
  create(
    account: Account,
    transaction: ClientTransactionViewTO,
    consultant: { idtConsultant?: number; idtExternalRep?: number },
  ): Observable<Account> {
    return this.http.post<Account>(`${environment.apiUrl}/account`, { account, transaction, consultant });
  }

  /**
   * Save an edited account.
   *
   * @param account the account data
   * @param consultants the consultants for the client/portfolio
   * @returns the saved account entity
   */
  update(account: Account): Observable<Account> {
    return this.http.put<Account>(`${environment.apiUrl}/account/${account.idtAccount}`, { account });
  }

  /**
   * Gets the current balance.
   *
   * @param idtAccount the id of the account
   * @param refDate optional reference date to use as maximum reporting month
   * @returns the latest balance wrapped in an observable
   */
  getLatestBalance(idtAccount: number, refDate?: DateTime): Observable<Balance | null> {
    return this.http.get<Balance>(`${environment.apiUrl}/account/${idtAccount}/balance`, {
      params: {
        ...(refDate && { refDate: refDate?.toISODate() }),
      },
    });
  }

  /**
   * Manually creates a balance for the provided account in the provided month.
   *
   * @param idtAccount the account id
   * @param data the balance data
   * @returns an observable that emits void when the server responds
   */
  createNewBalance(idtAccount: number, data: { reportingMonth: DateTime; endBalance: number }): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/account/${idtAccount}/balance`, {
      reportingMonth: data.reportingMonth.endOf('month').toISODate(),
      endBalance: data.endBalance,
    });
  }

  /**
   * Get the column definitions form the server.
   *
   * @returns the column definitions
   */
  getColumnsDefinitions(): Observable<Column[]> {
    return this.http.get<Column[]>(`${environment.apiUrl}/account/columns`).pipe(shareReplay());
  }

  /**
   * Get all account types.
   *
   * @returns the list of account types wrapped in an observable
   */
  getTypes(): Observable<AccountType[]> {
    return this.http.get<AccountType[]>(`${environment.apiUrl}/account/type`);
  }

  /**
   * Search for accounts based on the provided criteria.
   *
   * @param filters the filters to apply
   * @returns a list of accounts found
   */
  getByClient(idtClient: number, inactive: boolean = false): Observable<Account[]> {
    return this.http.get<Account[]>(`${environment.apiUrl}/account`, {
      params: {
        idtClient: idtClient.toString(),
        inactive: inactive.toString(),
      },
    });
  }

  /**
   * Get all possible values for the provided column.
   *
   * @param filter filter logic to apply
   * @param column the column to get the values
   * @returns a list of values found
   */
  getOptions(filter: string, column: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/account/options`, { params: { filter, column } });
  }

  /**
   * Search for active accounts in the index server.
   *
   * @param term the term to search for
   * @returns the page of accounts found wrapped in an observable
   */
  public searchIndexedAccounts(term: string): Observable<AccountIndexedDTO[]> {
    return this.http.get<AccountIndexedDTO[]>(`${environment.apiUrl}/account/search/indexed`, {
      params: {
        client: true,
        term,
      },
    });
  }
}

/**
 * Resolve service to get an account based on the route id.
 */
export const accountResolver: ResolveFn<Account> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  accountService: AccountService = inject(AccountService),
  snackBar: MatSnackBar = inject(MatSnackBar),
) => {
  return accountService.getOne(Number(route.paramMap.get('idtAccount'))).pipe(
    catchError((err) => {
      if (err.status === 403) {
        snackBar.open(err.error.detail, 'Close', { duration: 10000 });
      }

      return EMPTY;
    }),
  );
};
