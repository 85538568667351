<mat-sidenav-container fxFlex class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport role="navigation" mode="over" [opened]="false">
    @if (user(); as user) {
      <div fxLayout="row" class="user-info abs-padding-double">
        <div fxLayout="column" fxFlex>
          <div class="abs-bold user-name abs-padding-half-vertical">{{ user.name }}</div>
          <div>{{ user.email }}</div>
        </div>
        <button mat-icon-button (click)="close()">
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </div>

      <app-custom-list>
        <app-custom-list-item [routerLink]="['inbox', user.id]" (click)="clicked('Inbox')">
          <mat-icon
            class="abs-margin-right list-icon"
            fontIcon="mdi-inbox-arrow-down"
            [matBadge]="pendingMessagesCount()"
            matBadgePosition="below after"
            matBadgeSize="small"
            matBadgeColor="accent"
            [matBadgeHidden]="pendingMessagesCount() === 0"
          ></mat-icon>
          <span class="tw-leading-[18px]">Inbox</span>
        </app-custom-list-item>
      </app-custom-list>

      <mat-divider></mat-divider>

      <app-custom-list>
        <app-custom-list-item routerLink="contacts" (click)="clicked('Contacts')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-account"></mat-icon>
          <span class="tw-leading-[18px]" fxFlex>Contacts</span>
          <button mat-button (click)="navigateToNewContact($event)" color="accent">Add</button>
        </app-custom-list-item>
        <app-custom-list-item routerLink="accounts" (click)="clicked('Accounts')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-bank"></mat-icon>
          <span class="tw-leading-[18px]">Accounts</span>
        </app-custom-list-item>
        <app-custom-list-item routerLink="transactions" (click)="clicked('Transactions')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-cash-multiple"></mat-icon>
          <span class="tw-leading-[18px]">Transactions</span>
        </app-custom-list-item>
        <app-custom-list-item routerLink="opportunities" (click)="clicked('Opportunities')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-cash-100"></mat-icon>
          <span class="tw-leading-[18px]">Opportunities</span>
        </app-custom-list-item>
        <app-custom-list-item routerLink="cash-flow" (click)="clicked('CashFlow')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-swap-horizontal"></mat-icon>
          <span class="tw-leading-[18px]">Cash Flow</span>
        </app-custom-list-item>
      </app-custom-list>

      <mat-divider></mat-divider>

      <app-custom-list>
        @if (authService.hasPermission('VIEW_GROUPS')) {
          <app-custom-list-item routerLink="mailing/group" (click)="clicked('Groups')">
            <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-account-group"></mat-icon>
            <span class="tw-leading-[18px]">Groups</span>
          </app-custom-list-item>
        }
        @if (authService.hasPermission('VIEW_CAMPAIGNS')) {
          <app-custom-list-item routerLink="mailing/campaigns" (click)="clicked('Campaigns')">
            <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-email"></mat-icon>
            <span class="tw-leading-[18px]">Campaigns</span>
          </app-custom-list-item>
        }
      </app-custom-list>

      @if (authService.hasPermission('IMPORT_DATA')) {
        <mat-divider></mat-divider>
        <app-custom-list>
          <app-custom-list-item routerLink="import" (click)="clicked('Imports')">
            <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-upload"></mat-icon>
            <span class="tw-leading-[18px]">Imports</span>
          </app-custom-list-item>
        </app-custom-list>
      }

      <mat-divider></mat-divider>

      <app-custom-list>
        <app-custom-list-item routerLink="logs/report" (click)="clicked('Log Report')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-file-document"></mat-icon>
          <span class="tw-leading-[18px]">Log Report</span>
        </app-custom-list-item>
        <app-custom-list-item routerLink="opportunity/report" (click)="clicked('Opportunity Report')">
          <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-file-document"></mat-icon>
          <span class="tw-leading-[18px]">Opportunities Summary Report</span>
        </app-custom-list-item>
      </app-custom-list>

      @if (authService.hasPermission('MANAGE_FEES_AND_FLOWS')) {
        <mat-divider></mat-divider>
        <app-custom-list>
          <app-custom-list-item routerLink="fees-flows" (click)="clicked('Fees and Flows')">
            <mat-icon class="abs-margin-right list-icon" fontIcon="mdi-account-cash"></mat-icon>
            <span class="tw-leading-[18px]">Fees and Flows</span>
          </app-custom-list-item>
        </app-custom-list>
      }
    }
  </mat-sidenav>
  <mat-sidenav-content fxFlex fxLayout="column">
    <div class="abs-dark-theme">
      <mat-toolbar color="primary" class="header-toolbar toolbar mat-elevation-z4">
        <div fxFlex="15" fxLayout="row" fxLayoutAlign="start center">
          <div class="button-spacer">
            @if (loggedIn$ | async) {
              <button type="button" mat-icon-button (click)="open()">
                <mat-icon fontIcon="mdi-menu"></mat-icon>
              </button>
            }
          </div>
          <a routerLink="" (click)="home()" fxLayoutAlign="start center" class="system-link">
            <div class="system-logo" fxFlex="32px"></div>
            <div class="system-title" fxHide fxShow.gt-xs>ARMS</div>
          </a>
        </div>
        @if (loggedIn$ | async) {
          <div fxFlex fxLayout="column">
            <app-super-search-field></app-super-search-field>
          </div>
          <div fxFlex="none" fxFlex.gt-xs="15" fxLayout="row" fxLayoutAlign="end center">
            <div>
              <div class="user-name abs-margin abs-clickable" [routerLink]="['', { outlets: { sidenav: ['task'] } }]">
                {{ user()?.initials }}
              </div>
            </div>
            <app-task-list></app-task-list>
            <button mat-icon-button (click)="logout()" matTooltip="Logout">
              <mat-icon fontIcon="mdi-power-standby"></mat-icon>
            </button>
          </div>
        }
      </mat-toolbar>
    </div>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
