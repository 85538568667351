import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { BalanceHistoryService } from 'app/modules/common/business/contact/services/balance.service';
import { MONTH_YEAR_DATE_FORMATS } from 'app/modules/common/framework/date-format/custom-date-format';
import { DataFilter, DataFilterOperator } from 'app/modules/common/framework/model/data-filter';
import { CommonDataSource } from 'app/modules/common/framework/pagination/common-datasource';
import { FileService } from 'app/modules/common/framework/services/file.service';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs';
import { BalanceWithTransactionSumViewTO } from '../../../client/model/client-balance.model';
import { CreateBalanceComponent } from '../create-balance/create-balance.component';

/**
 * Component to display table with balance history data.
 */
@Component({
  selector: 'app-account-balance-history',
  templateUrl: './account-balance-history.component.html',
  styleUrls: ['./account-balance-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMATS }],
})
export class AccountBalanceHistoryComponent implements OnInit {
  /**
   * The id of the account.
   */
  @Input({ required: true })
  idtAccount!: number;

  /**
   * If the account is inactive.
   */
  @Input({ required: true })
  inactive!: boolean;

  /**
   * The name of the account.
   */
  @Input()
  name?: string;

  /**
   * The initial date for the search interval.
   */
  startDateControl = new FormControl<DateTime>(DateTime.now().minus({ year: 1 }));

  /**
   * The final data for the search interval.
   */
  endDateControl = new FormControl<DateTime>(DateTime.now());

  /**
   * The datasource holding the search results.
   */
  dataSource!: CommonDataSource<BalanceWithTransactionSumViewTO>;

  /**
   * The columns to be displayed in the table.
   */
  displayedColumns = ['reportingMonthDate', 'endBalance', 'nav', 'shares', 'transactionsValue', 'monthlyReturn', 'qtd', 'ytd', 'itd'];

  loading = signal(false);

  constructor(
    private balanceHistoryService: BalanceHistoryService,
    private destroyRef: DestroyRef,
    private dialog: MatDialog,
    private fileService: FileService,
  ) {}

  ngOnInit(): void {
    this.buildDataSource();

    this.startDateControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.startDateControl.value && this.endDateControl.value) {
        this.buildDataSource();
      }
    });

    this.endDateControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.startDateControl.value && this.endDateControl.value) {
        this.buildDataSource();
      }
    });
  }

  /**
   * Builds the filter object to be sent to the server.
   *
   * @returns the filter object to apply to the query
   */
  private buildFilter(): DataFilter {
    return new DataFilter()
      .where(DataFilterOperator.EQUALS, 'idtAccount', this.idtAccount, 'long')
      .where(DataFilterOperator.GREATER_OR_EQUAL, 'reportingMonthDate', this.startDateControl.value!.toISODate(), 'local-date')
      .where(DataFilterOperator.LESS_OR_EQUAL, 'reportingMonthDate', this.endDateControl.value!.toISODate(), 'local-date');
  }

  /**
   * Builds the datasource object to load in the table.
   */
  buildDataSource(): void {
    this.dataSource = new CommonDataSource(
      (page: number, size: number, sort: string | string[], filter: string) => this.balanceHistoryService.getPage(page, size, sort, filter),
      'reportingMonthDate,DESC',
      40,
      this.buildFilter().encode(),
    );

    this.dataSource.loadMore();
  }

  /**
   * When reaching the end of the scroll load more results.
   */
  onTableScroll(): void {
    this.dataSource.loadMore();
  }

  /**
   * Sets the proper date value when the month is selected, not requiring the day to be selected.
   *
   * @param fullDate the full date tha comes when the client choose a month on calendar
   * @param datepicker responsible for managing the datepicker popup/dialog
   * @param startOrEnd whether the star or end datapicker was changed
   */
  chosenMonthHandler(fullDate: DateTime, datepicker: MatDatepicker<DateTime>, startOrEnd: 'start' | 'end'): void {
    if (startOrEnd === 'start') {
      this.startDateControl.setValue(fullDate.startOf('month'));
    } else if (startOrEnd === 'end') {
      this.endDateControl.setValue(fullDate.endOf('month'));
    }

    datepicker.close();
  }

  /**
   * Request the balances to be exported to an excel file.
   */
  export(): void {
    this.loading.set(true);

    const fileName = `ARMS_BalanceHistory-of_${this.name}_${this.startDateControl.value!.toISODate()} to ${this.endDateControl.value!.toISODate()}.xlsx`;

    this.balanceHistoryService
      .export('reportingMonthDate,DESC', this.buildFilter())
      .pipe(
        finalize(() => {
          this.loading.set(false);
        }),
      )
      .subscribe((response: Blob) => {
        this.fileService.openFile(response, fileName);
      });
  }

  /**
   * Open dialog to create a new balance.
   */
  createBalance(): void {
    this.dialog
      .open(CreateBalanceComponent, {
        data: {
          idtAccount: this.idtAccount!,
        },
      })
      .afterClosed()
      .subscribe((success) => {
        if (success) {
          this.buildDataSource();
        }
      });
  }

  /**
   * Open dialog to edit an existing balance.
   */
  editBalance(): void {
    this.dialog
      .open(CreateBalanceComponent, {
        data: {
          idtAccount: this.idtAccount!,
          edition: true,
        },
      })
      .afterClosed()
      .subscribe((success) => {
        if (success) {
          this.buildDataSource();
        }
      });
  }
}
