import { ChangeDetectionStrategy, Component, input, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { requiredIfValidator } from 'app/modules/common/framework/validations/validation-utils';
import { DateTime } from 'luxon';
import { OpportunityClosedStatusEnum, OpportunityPropabilityTimingEnum, OpportunityTO } from '../../../model/opportunity.model';

/**
 * Component for filling out the opportunity probability data.
 */
@Component({
  selector: 'app-opportunity-probability',
  templateUrl: './opportunity-probability.component.html',
  styleUrls: ['./opportunity-probability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityProbabilityComponent implements OnInit {
  /**
   * Form group to hold the filled data.
   */
  formGroup = this.fb.group({
    status: new FormControl<string | null>(null),
    closedStatus: new FormControl<string | null>(null),
    statusComments: new FormControl<string | null>(null),
    fundingDate: new FormControl<string | DateTime | null>(null),
    lostReason: new FormControl<string | null>(
      null,
      requiredIfValidator(() => this.formGroupGetter.controls.closedStatus.value === OpportunityClosedStatusEnum.LOST),
    ),
    lostWho: new FormControl<string | null>(null),
    knownCompetition: new FormControl<string | null>(null),
    probabilityTiming: new FormControl<string | null>(null, Validators.required),
    size: new FormControl<number | null>(null, Validators.required),
    finalPitch: new FormControl<string | DateTime | null>(null),
  });

  /**
   * Timing value options.
   */
  opportunityTimingEnum = OpportunityPropabilityTimingEnum;

  /**
   * The existing opportunity for editions.
   */
  opportunity = input<OpportunityTO>();

  /**
   * Flag to help uncheck closed status options.
   */
  toggleClosedStatusChanged = signal(false);

  constructor(
    private fb: FormBuilder,
    private trackerService: TrackerService,
  ) {}

  ngOnInit(): void {
    // Replace the datetime objects with just the date string for date fields
    const finalPitchDateControl = this.formGroup.controls.finalPitch;
    finalPitchDateControl?.valueChanges.subscribe((value) => {
      if (value && value instanceof DateTime) {
        finalPitchDateControl.setValue(value.toISODate(), { emitEvent: false });
      }
    });

    const fundingDateControl = this.formGroup.controls.fundingDate;
    fundingDateControl?.valueChanges.subscribe((value) => {
      if (value && value instanceof DateTime) {
        fundingDateControl.setValue(value.toISODate(), { emitEvent: false });
      }
    });

    if (this.opportunity()) {
      this.formGroup.patchValue(this.opportunity()!, { emitEvent: false });
    }
  }

  /**
   * Add one million to the opportunity size.
   */
  addOneMillion(): void {
    const control = this.formGroup.controls.size;
    control?.setValue((control?.value || 0) + 1000000);
    this.trackerService.event('opportunity', 'add_one_million');
  }

  /**
   * Add ten millions to the opportunity size.
   */
  addTenMillions(): void {
    const control = this.formGroup.controls.size;
    control?.setValue((control?.value || 0) + 10000000);
    this.trackerService.event('opportunity', 'add_ten_million');
  }

  /**
   * Handle changing closed status, sets flag to indicate the value was changed.
   */
  closedStatusChange() {
    this.toggleClosedStatusChanged.set(true);
  }

  /**
   * Handle clicking on closed status options, if clicking the already selected option, clear the value.
   */
  onClosedStatusClick() {
    if (!this.toggleClosedStatusChanged()) {
      this.formGroup.controls.closedStatus.setValue(null);
    }

    this.toggleClosedStatusChanged.set(false);
  }

  /**
   * Verifies if the form is valid, mark invalid fields as touched so they are marked in red.
   *
   * @returns true, if valid
   */
  isValid(): boolean {
    this.formGroup.markAllAsTouched();
    this.formGroup.controls.lostReason.updateValueAndValidity();

    return this.formGroup.valid;
  }

  /**
   * Get the current form values.
   *
   * @returns get the current form values
   */
  getValues(): any {
    return this.formGroup.getRawValue();
  }

  /**
   * Created to access the type of a field within the definition of the field itself.
   */
  private get formGroupGetter(): FormGroup {
    return this.formGroup;
  }
}
