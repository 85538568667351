import { Component, Input } from '@angular/core';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Observable, of } from 'rxjs';
import { ContactCategory } from '../../../contact/model/contact.model';
import { ContactService } from '../../../contact/services/contact.service';
import { OpportunityService } from '../../services/opportunity.service';
import { OpportunityGroupedListComponent } from '../opportunity-grouped-list/opportunity-grouped-list.component';

/**
 * Component to display list of opportunities related to a contact.
 */
@Component({
  selector: 'app-contact-opportunities-list',
  templateUrl: './contact-opportunities-list.component.html',
  styleUrls: ['./contact-opportunities-list.component.scss'],
})
export class ContactOpportunitiesListComponent extends OpportunityGroupedListComponent {
  private _idtContact!: number;

  /**
   * Indicates if there is an opportunity under a blackout period.
   */
  hasBlackoutOpportunity$: Observable<boolean> = of(false);

  /**
   * The id of the contact.
   */
  @Input()
  set idtContact(value: number) {
    this._idtContact = value;
    this.loadContact(value);
  }

  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * Whether to show a dense version of the list to save space. For mobile mainly.
   */
  @Input()
  dense = false;

  constructor(
    private opportunityService: OpportunityService,
    trackerService: TrackerService,
    private contactService: ContactService,
  ) {
    super(trackerService);
  }

  /**
   * Load contact data for the provided contact id.
   *
   * @param idtContact the contact id
   */
  loadContact(idtContact: number): void {
    this.contactService.getInfoById(idtContact).subscribe((contact) => {
      this.isConsultant = contact.category === ContactCategory.CONSULTANT || contact.clients > 0;

      this.hasBlackoutOpportunity$ = this.isConsultant ? of(false) : this.opportunityService.hasBlackoutOpportunity(contact.idtContact);

      const idtContacts = [idtContact, contact!.idtCompanyContact] as number[];
      this.setOpportunities(this.opportunityService.getByContacts(idtContacts));
    });
  }
}
