@if (!selecting()) {
  <div mat-dialog-content class="tw-@container">
    <div class="tw-flex tw-flex-col tw-items-center tw-gap-1 @xs:tw-flex-row">
      <div>Select employees that work at this company:</div>
      <button mat-raised-button (click)="selecting.set(true)">Select employees</button>
    </div>

    <app-scrollable class="abs-margin-top tw-flex tw-flex-1 tw-flex-col">
      <app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
        @for (contact of selectedEmployees(); track contact.idtContact) {
          <div class="tw-flex tw-flex-row tw-items-center">
            <app-contact-item
              [contact]="contact"
              (click)="selectContact(contact)"
              [handleClicks]="false"
              class="tw-flex-1"
              [showContextMenus]="false"
            ></app-contact-item>
            <button mat-icon-button (click)="removeContact(contact.idtContact)"><mat-icon fontIcon="mdi-delete"></mat-icon></button>
          </div>
        }
      </app-custom-list>
    </app-scrollable>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="end()">Cancel</button>
    <span class="tw-flex-1"></span>
    @if (selectedEmployees().length > 0) {
      <button mat-raised-button color="accent" (click)="save()">Save</button>
    }
  </div>
} @else {
  <div mat-dialog-content>
    <!-- List to search for contact -->
    <div class="tw-flex tw-flex-1 tw-flex-col">
      <!-- Contact Search field -->
      <mat-form-field class="abs-margin vertical-align">
        <input matInput type="search" placeholder="Insert the contact name" [formControl]="searchField" trim="blur" #searchInput />
      </mat-form-field>
      <span class="abs-bold abs-margin-left">Select contact</span>
      <!-- Contact Search result -->
      <app-scrollable class="abs-padding tw-flex tw-flex-1 tw-flex-col" style="min-height: 150px">
        @if (contactData$ | async; as contacts) {
          <app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
            @for (contact of contacts; track contact.idtContact) {
              <app-contact-item
                [contact]="contact"
                (click)="selectContact(contact)"
                [handleClicks]="false"
                [showContextMenus]="false"
              ></app-contact-item>
            } @empty {
              <div class="tw-text-center">No contacts found</div>
            }
          </app-custom-list>
        }
      </app-scrollable>
    </div>

    <div>
      <div class="abs-bold abs-margin">Or create a new contact</div>

      <app-contact-new-basic-data-form
        (submitted)="createNewContact($event)"
        [type]="ContactTypeEnum.PERSON"
        #contactForm
      ></app-contact-new-basic-data-form>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="selecting.set(false)">Cancel</button>
    <span class="tw-flex-1"></span>
    <button mat-raised-button type="submit" [attr.form]="contactForm.id" color="accent">Save</button>
  </div>
}
