import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserPermissionEnum } from 'app/modules/routes/login/model/user-permission.enum';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { PortfolioAllocation } from '../../../portfolio/model/portfolio-allocation.model';
import { ClientAllocation } from '../../model/client-allocation.model';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-client-allocation',
  templateUrl: './client-allocation.component.html',
  styleUrls: ['./client-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAllocationComponent {
  /**
   * Client allocation list.
   */
  clients$?: Observable<ClientAllocation[]>;

  /**
   * The selected portfolio to filter for.
   */
  private _portfolio?: PortfolioAllocation;

  get portfolio(): PortfolioAllocation | undefined {
    return this._portfolio;
  }

  @Input()
  set portfolio(value: PortfolioAllocation | undefined) {
    this._portfolio = value;
    this.getAllocations();
  }

  /**
   * The reference date to use for searching.
   */
  private _asOf?: DateTime;

  get asOf(): DateTime | undefined {
    return this._asOf;
  }

  @Input()
  set asOf(date: DateTime | undefined) {
    this._asOf = date;
    this.getAllocations();
  }

  /**
   * Emits the select event.
   */
  @Output()
  selectClient = new EventEmitter<ClientAllocation>();

  /*
   * The id of the selected item.
   */
  @Input()
  selectedClient?: ClientAllocation;

  /**
   * Whehter to show selection filter chips.
   */
  @Input()
  showChip = false;

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  portfolioRemoved = new EventEmitter<void>();

  loading = signal(true);

  constructor(
    private clientService: ClientService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  private getAllocations(): void {
    if (this.asOf) {
      this.loading.set(true);
      this.clients$ = this.clientService.getAllocations(this.asOf, this.portfolio?.idtPortfolio).pipe(
        finalize(() => {
          this.loading.set(false);
        })
      );
    }
  }

  /**
   * Track client list by its id.
   * @param index list index
   * @param item client item
   * @returns the client id.
   */
  public trackClient(index: number, item: ClientAllocation): number {
    return item.idtClient;
  }

  /**
   * Select client and emit an event to the parent component.
   * @param client client id.
   */
  public select(client?: ClientAllocation): void {
    if (this.selectedClient?.idtClient === client?.idtClient) {
      this.selectClient.emit(undefined);
    } else {
      if (!client?.internal || this.authService.hasPermission(UserPermissionEnum.VIEW_INTERNAL_DATA)) {
        this.selectClient.emit(client);
      } else {
        this.snackBar.open('Not allowed to see internal user data', 'Close', { duration: 10000 });
      }
    }
  }

  removePortfolio(): void {
    this.portfolioRemoved.emit();
  }
}
