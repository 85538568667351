<mat-form-field fxFlex>
  <mat-label>Portfolios</mat-label>

  <mat-chip-grid #chipList>
    @for (portfolio of control.value; track portfolio) {
      <mat-chip-row (removed)="remove(portfolio)">
        {{ portfolio.shortName }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip-row>
    }
    <input placeholder="Select portfolio" #input [formControl]="searchControl" [matAutocomplete]="auto" [matChipInputFor]="chipList" />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (portfolio of portfolios$ | async; track portfolio) {
      <mat-option [value]="portfolio">
        {{ portfolio.shortName }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
