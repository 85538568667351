@if (!showChip) {
  <div class="tab-tools abs-margin-right" fxLayoutAlign="end center"></div>
}

@if (showChip) {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close"></mat-icon>
        </button>
      </mat-chip>
    }
    @if (!!asOf) {
      <mat-chip-option>as of {{ asOf.toLocaleString() }}</mat-chip-option>
    }
  </mat-chip-listbox>
}

<app-custom-list fxFlex fxLayout="column">
  <cdk-virtual-scroll-viewport fxFlex fxLayout="column" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [loading]="loading()" [items]="20"></app-list-skeleton-loader>

    <app-custom-list-item
      *cdkVirtualFor="let consultant of $consultants | async; trackBy: trackConsultants"
      [selected]="selectedConsultant?.idtExternalRep === consultant.idtExternalRep"
      (click)="select(consultant)"
    >
      <div fxFlex="100" fxLayout="row">
        <div fxFlex fxLayout="column" class="column-name">
          <span>
            <div style="display: flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="consultant.name"
                [displayText]="consultant.name"
                [id]="consultant.idtExternalRep"
              ></app-contact-context-menu>
            </div>
          </span>
          <span class="abs-color-faded" [ngPlural]="consultant.clients">
            <ng-template ngPluralCase="=1">1 client</ng-template>
            <ng-template ngPluralCase="other">{{ consultant.clients }} clients</ng-template>
          </span>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center end" class="column-amount">
        <span>{{ consultant.totalConsultant | currency }}</span>
        <span class="abs-color-faded">{{ consultant.allocation / 100 | percent: '.2' }}</span>
      </div>
      <mat-divider></mat-divider>
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
