<div mat-dialog-content>
  <!-- List to search for contact -->
  <div class="tw-flex tw-flex-1 tw-flex-col">
    <!-- Contact Search field -->
    <mat-form-field class="abs-margin vertical-align">
      <input matInput type="search" placeholder="Insert the company name" [formControl]="searchField" trim="blur" #searchInput />
    </mat-form-field>
    <span class="abs-bold abs-margin-left">Existing Companies</span>
    <!-- Contact Search result -->
    <app-scrollable class="abs-padding tw-flex tw-flex-1 tw-flex-col" style="min-height: 150px">
      @if (contactData$ | async; as contacts) {
        <app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
          @for (contact of contacts; track contact.idtContact) {
            <app-contact-item
              [contact]="contact"
              (click)="selectCompany(contact)"
              [handleClicks]="false"
              [showContextMenus]="false"
            ></app-contact-item>
          } @empty {
            <div class="tw-text-center">No contacts found</div>
          }
        </app-custom-list>
      }
    </app-scrollable>
  </div>

  <div>
    <div class="abs-bold abs-margin">Or create a new company</div>

    <app-contact-new-basic-data-form
      (submitted)="createNewCompany($event)"
      [type]="ContactTypeEnum.COMPANY"
      #contactForm
    ></app-contact-new-basic-data-form>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="accent" type="submit" [attr.form]="contactForm.id">Save</button>
</div>
