<div fxFlex fxLayout="column">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="goBack()" class="abs-margin-right">
      <mat-icon fontIcon="mdi-arrow-left"></mat-icon>
    </button>
    <span fxFlex>Link to an opportunity</span>

    @if (outlet() === 'sidenav') {
      <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
        <mat-icon fontIcon="mdi-close"></mat-icon>
      </button>
    }
  </mat-toolbar>
  <app-progress-bar [loading]="loading() || saving()"></app-progress-bar>

  @if (!loading()) {
    <div fxFlex fxLayout="column" class="abs-padding abs-scroll-auto">
      <!-- Client selection -->
      <div class="abs-component-title abs-margin-bottom">Prospect client</div>
      <app-opportunity-select-client
        [fxFlex]="selectingClient() ? '100' : 'none'"
        [idtLog]="idtLog()"
        #selectClient
        class="abs-margin-bottom"
        (selected)="selectingClient.set($event)"
        [opportunity]="opportunity"
      ></app-opportunity-select-client>
      <!-- Portfolio -->
      <div [fxShow]="!selectingClient()" class="abs-margin-bottom" fxLayout="column" [fxFlex]="selectingPortfolio() ? '100' : 'none'">
        <div class="abs-component-title abs-margin-bottom">Product</div>
        <app-opportunity-select-portfolio
          fxFlex
          (selected)="selectingPortfolio.set($event)"
          [opportunity]="opportunity"
          [idtLog]="idtLog()"
          #portfolioSection
          [contact]="selectClient.getValues().idtContact"
        ></app-opportunity-select-portfolio>
      </div>
      <!-- Probability -->
      <div [fxShow]="!selectingClient() && !selectingPortfolio()" class="abs-margin-bottom" fxLayout="column">
        <div class="abs-component-title abs-margin-bottom">Probability</div>
        <app-opportunity-probability fxFlex #probability [opportunity]="opportunity"></app-opportunity-probability>
      </div>
      <!-- RFP -->
      <div [fxShow]="!selectingClient() && !selectingPortfolio()" fxFlex>
        <app-opportunity-rfp [opportunity]="opportunity" #rfpComponent></app-opportunity-rfp>
        <mat-divider class="abs-margin-vertical"></mat-divider>
      </div>
      @if (!selectingClient() && !selectingPortfolio()) {
        <div fxLayout="row">
          <span fxFlex></span>
          <button mat-raised-button color="primary" (click)="save()">Save</button>
        </div>
      }
    </div>
  }
</div>
