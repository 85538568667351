<mat-form-field fxFlex>
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option>
      <ngx-mat-select-search
        [formControl]="companySearchControl"
        placeholderLabel="Search"
        noEntriesFoundLabel="Nothing found"
        [clearSearchInput]="false"
        >
        <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>

    <mat-option>None</mat-option>

    @for (company of companies; track company) {
      <mat-option [value]="company.idtCompany">
        {{ company.legalName }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
