import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

/**
 * Pipe to transform a date into an interval in days
 */
@Pipe({
  name: 'timeDiffDays',
})
export class TimeDiffDaysPipe implements PipeTransform {
  transform(value: string): any {
    if (value) {
      const days = DateTime.now().startOf('day').diff(DateTime.fromISO(value).startOf('day'), 'days').days;

      const intervals: { [key: string]: number } = {
        year: 365,
        month: 30,
        day: 1,
      };

      if (days === 0) {
        return 'Today';
      }

      if (days > 0) {
        for (const i in intervals) {
          const counter = Math.floor(days / intervals[i]);
          if (counter > 0) {
            return `${counter} ${i}${counter > 1 ? 's' : ''} ago`;
          }
        }
      } else {
        for (const i in intervals) {
          const counter = Math.floor(Math.abs(days) / intervals[i]);
          if (counter > 0) {
            return `in ${counter} ${i}${counter > 1 ? 's' : ''}`;
          }
        }
      }
    }

    return value;
  }
}
