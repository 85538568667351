<div class="abs-margin-right" fxLayoutAlign="end center" [ngClass]="{ 'tab-tools': !dense }" [ngStyle]="{ 'margin-top': dense ? '36px' : 'unset' }">
  @if (dense) {
    <button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
      <mat-icon fontIcon="mdi-filter-variant"></mat-icon>
    </button>
  }

  <mat-menu #menu>
    @for (transactionType of transactionTypes; track transactionType.idtClientTransactionType) {
      <button mat-menu-item (click)="setTransactionType(transactionType)">
        <span>{{ transactionType.type }}</span>
        <span fxFlex></span>
        <span>&nbsp;({{ transactionType.count }})</span>
      </button>
    }
  </mat-menu>

  @if (!dense && !sidenav) {
    <button mat-icon-button color="primary" (click)="expandAccounts()" matTooltip="Expand">
      <mat-icon fontIcon="mdi-book-open"></mat-icon>
    </button>
  }
</div>

<div fxFlex fxLayout="column" [ngClass]="{ 'abs-margin-horizontal': !dense }">
  @if (!dense) {
    <form [formGroup]="filterForm" class="abs-margin-top form-field-filter">
      <mat-form-field fxFlex appearance="fill">
        <mat-label>Account or Portfolio</mat-label>
        <input matInput type="text" formControlName="accountNamePortfolioTicker" />
      </mat-form-field>
      <mat-form-field fxFlex="150px" class="abs-margin-half-left">
        <mat-label>Type</mat-label>
        <mat-select formControlName="idtClientTransactionType" multiple>
          @for (transactionType of transactionTypes; track transactionType.idtClientTransactionType) {
            <mat-option [value]="transactionType.idtClientTransactionType">
              {{ transactionType.type }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="abs-margin-half-left">
        <mat-label>Start</mat-label>
        <input matInput [matDatepicker]="start" formControlName="startDate" />
        <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
        <mat-datepicker #start disabled="false"></mat-datepicker>
        <mat-error>Invalid Date</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="abs-margin-half-left">
        <mat-label>End</mat-label>
        <input matInput [matDatepicker]="end" formControlName="endDate" disable="true" />
        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
        <mat-datepicker #end disabled="false"></mat-datepicker>
        <mat-error>Invalid Date</mat-error>
      </mat-form-field>
      <button
        mat-icon-button
        color="primary"
        (click)="createTransaction()"
        class="abs-margin-half-top"
        *hasPermission="'CREATE_TRANSACTION'"
        matTooltip="New transaction"
      >
        <mat-icon fontIcon="mdi-plus-circle"></mat-icon>
      </button>
    </form>
  }

  <app-transaction-list-header [dense]="dense"></app-transaction-list-header>
  @if (clientTransactionData?.hasData) {
    <app-custom-list fxFlex fxLayout="column">
      <cdk-virtual-scroll-viewport fxFlex fxLayout="column" class="abs-scroll-overlay" itemSize="44">
        <app-transaction-list-item
          *cdkVirtualFor="let transaction of clientTransactionData; trackBy: trackTransaction"
          (click)="openTransactionDetails(transaction)"
          [transaction]="transaction"
          [dense]="dense"
        ></app-transaction-list-item>
      </cdk-virtual-scroll-viewport>
    </app-custom-list>
  } @else {
    <app-no-data entity="Transaction"></app-no-data>
  }
</div>
@if (clientTransactionData?.cachedData?.length) {
  <div fxFlex="none" fxLayout="column">
    <mat-divider></mat-divider>
    <div fxFlex="none" class="footer">
      @if (clientTransactionTotals$ | async; as clientTransactionTotals) {
        <span fxFlex>{{ formatTotalTransactionsByStatus(clientTransactionTotals.transactionsByStatus) }}</span>
        <div fxFlex="10" fxLayout="column" fxLayoutAlign="center end" class="abs-margin-half-left">
          <app-positive-negative [value]="clientTransactionTotals.transactionBalance">
            <span matTooltip="Including pending transactions">
              {{ clientTransactionTotals.transactionBalance | nearUnitNumber: '0.0-0' }}
            </span>
          </app-positive-negative>
        </div>
        <div fxFlex="3" class="abs-margin-left"></div>
      }
    </div>
  </div>
}
