import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { Observable } from 'rxjs';
import { Draft } from '../../../draft/model/draft.model';
import { DraftService } from '../../../draft/services/draft.service';
import { Log, LogDetailViewTO } from '../../model/log.model';

/**
 * Component to list logs
 */
@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss'],
})
export class LogListComponent implements OnInit {
  @Input()
  public logData?: PageableDataSource<LogDetailViewTO>;

  @Input()
  public showDrafts: boolean = false;

  draftData!: Observable<Draft<Log>[]>;

  @Output()
  clicked = new EventEmitter();

  constructor(private draftService: DraftService) {}

  ngOnInit() {
    this.loadDrafts();
  }

  /**
   * Emits the log click
   * @param log
   */
  onClick(log: LogDetailViewTO, index: number) {
    this.clicked.emit({ log, index });
  }

  /**
   * Emits the draft click.
   * @param draft
   */
  onDraftClick(draft: Draft<LogDetailViewTO>) {
    this.clicked.emit({ log: draft.data, index: 0 });
  }

  /**
   * Reload user drafts when a draft is discarded.
   * @param draftId
   */
  onDraftDiscarded(draftId: string) {
    this.loadDrafts();
  }

  /**
   * Load user drafts.
   */
  private loadDrafts() {
    if (this.showDrafts) {
      this.draftData = this.draftService.getUserDrafts<Log>('log');
    }
  }
}
