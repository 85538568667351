import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { mergeMap, tap } from 'rxjs/operators';
import { AuthService } from '../../../auth/services/auth.service';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { TaskTypes, TaskViewTO } from '../../models/task.model';
import { TaskEventEnum } from '../../services/task-event.enum';
import { TaskService } from '../../services/task.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  /**
   * Task type options.
   */
  taskTypes = TaskTypes;

  /**
   * List of tasks to be displayed.
   */
  tasks: TaskViewTO[] = [];

  /**
   * Whether the user has late tasks.
   */
  hasLateTask = false;

  /**
   * Whether the user has task close to the due date.
   */
  hasSoonTask = false;

  /**
   * Whether the task list is open.
   */
  isOpen = false;

  /**
   * The contact to filter tasks for.
   */
  private _idtContact?: number;

  @Input()
  set idtContact(value: number | undefined) {
    this._idtContact = value;
    this.loadTasks();
  }

  get idtContact(): number | undefined {
    return this._idtContact;
  }

  constructor(
    private taskService: TaskService,
    private eventQueueService: EventQueueService,
    private trackerService: TrackerService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loadTasks();

    this.eventQueueService.on(TaskEventEnum.taskSaved).subscribe(() => this.loadTasks());
  }

  /**
   * Load tasks from the server.
   */
  private loadTasks(): void {
    let obs;

    if (this.idtContact) {
      obs = this.taskService.getAllTasks([], null, this.idtContact);
    } else {
      obs = this.authService.getUserInfo().pipe(mergeMap((u) => this.taskService.getAllTasks([u.id])));
    }

    obs
      .pipe(
        tap((tasks) => {
          this.hasLateTask = tasks.some((t) => this.taskService.isLate(t));
          this.hasSoonTask = tasks.some((t) => this.taskService.isDue(t));
        })
      )
      .subscribe((tasks) => {
        this.tasks = tasks;
      });
  }

  /**
   *  Open dialog to create a new task.
   */
  newTask(): void {
    this.isOpen = false;

    this.trackerService.event('task_menu', 'new_task');
  }

  /**
   * Close the overlay.
   */
  close(): void {
    this.isOpen = false;
  }

  /**
   * Open the sidenav to view tasks.
   */
  openSidenav(): void {
    this.router.navigate(['', { outlets: { sidenav: ['task'] } }], {
      queryParams: { 'sidenav.idtContact': this.idtContact },
    });
  }
}
