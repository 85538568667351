import { ChangeDetectorRef, Component, DestroyRef, ElementRef, OnInit, ViewChild, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BasicContact, ContactIndexedDTO, ContactTypeEnum } from 'app/modules/common/business/contact/model/contact.model';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { Observable, combineLatest, debounceTime, fromEvent, mergeMap, of, tap } from 'rxjs';
import { TrackerService } from '../../../tracker/services/tracker.service';
import { ContactNewDialogResponse } from '../create-new-contact/contact-new/contact-new.component';

// FIXME: Change changeDetectonStrategy to OnPush when we can listen to form control status changes, so we can markForCheck properly.
// A fix is scheduled for angular version 18 (https://github.com/angular/angular/issues/10887)

/**
 * Options for which contacts show in the autocomplete.
 */
export enum ContactAutoCompleteMode {
  ALL = 'all',
  PERSON = 'person',
  COMPANY = 'company',
  MAIN_CONSULTANT = 'mainConsultant',
}

/**
 * Component for selecting contacts with autocomplete.
 */
@Component({
  selector: 'app-contact-autocomplete',
  templateUrl: './contact-autocomplete.component.html',
  styleUrls: ['./contact-autocomplete.component.scss'],
})
export class ContactAutocompleteComponent implements OnInit {
  /**
   * The form control instance.
   */
  control = input.required<FormControl<BasicContact[]>>();

  /**
   * The type of contact to filter for.
   */
  mode = input<'all' | 'person' | 'company' | 'mainConsultant'>(ContactAutoCompleteMode.ALL);

  /**
   * The field label.
   */
  label = input<string>('Contact');

  /**
   * Optional placeholder to show in the field.
   */
  placeholder = input<string>('Select contact...');

  /**
   * Whether to show the create new contact option.
   */
  showCreateNew = input<boolean>(false);

  /**
   * All contact types options.
   */
  contactTypes = ContactTypeEnum;

  /**
   * The search input element reference.
   */
  @ViewChild('input', { static: true })
  searchInput!: ElementRef;

  /**
   * The search results.
   */
  searchResults$?: Observable<ContactIndexedDTO[]>;

  constructor(
    private contactService: ContactService,
    private trackerService: TrackerService,
    private cdr: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.configureAutocomplete();

    combineLatest([this.control().valueChanges, this.control().statusChanges])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  /**
   * Configure an input element autocomplete behavior.
   *
   * @param inputRef the input element reference
   * @param filterFunction the function to be called for filtering the values
   */
  private configureAutocomplete() {
    this.searchResults$ = fromEvent(this.searchInput.nativeElement, 'input').pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(500),
      mergeMap(() => this.search(this.searchInput.nativeElement.value.toLowerCase())),
      tap(() => this.cdr.markForCheck()),
    );
  }

  /**
   * Search contact based on the selected mode.
   *
   * @param term the search term
   * @returns observable that emits the results found
   */
  search(term: string): Observable<ContactIndexedDTO[]> {
    if (term && term.length > 2) {
      switch (this.mode()) {
        case ContactAutoCompleteMode.ALL:
          return this.searchAllContacts(term);
        case ContactAutoCompleteMode.PERSON:
          return this.searchPersonContacts(term);
        case ContactAutoCompleteMode.COMPANY:
          return this.searchCompanyContacts(term);
        case ContactAutoCompleteMode.MAIN_CONSULTANT:
          return this.searchMainConsultantContacts(term);
      }
    }

    return of([]);
  }

  /**
   * Search for indexed contacts.
   *
   * @param term the search term
   * @returns observable that emits the contacts found
   */
  searchAllContacts(term: string): Observable<ContactIndexedDTO[]> {
    // The companies whose contacts should be ranked top
    const companies = this.control()
      .value.filter((c) => c.type === ContactTypeEnum.COMPANY)
      .map((c) => c.name);
    return this.contactService.searchIndexedContacts(term, undefined, companies);
  }

  /**
   * Search for indexed contacts, persons only.
   *
   * @param term the search term
   * @returns observable that emits the contacts found
   */
  searchPersonContacts(term: string): Observable<ContactIndexedDTO[]> {
    const companies = this.control()
      .value.filter((c) => c.type === ContactTypeEnum.COMPANY)
      .map((c) => c.name);
    return this.contactService.searchIndexedContacts(term, ContactTypeEnum.PERSON, companies);
  }

  /**
   * Search for indexed contacts, companies only.
   *
   * @param term the search term
   * @returns observable that emits the contacts found
   */
  searchCompanyContacts(term: string): Observable<ContactIndexedDTO[]> {
    return this.contactService.searchIndexedContacts(term, ContactTypeEnum.COMPANY, undefined);
  }

  /**
   * Search for indexed contacts, main consultant companies only.
   *
   * @param term the search term
   * @returns observable that emits the contacts found
   */
  searchMainConsultantContacts(term: string): Observable<ContactIndexedDTO[]> {
    return this.contactService.searchIndexedConsultantContacts(term);
  }

  /**
   * Remove a contact from the selected list.
   *
   * @param entity the contact to be removed
   */
  remove(idtContact: number): void {
    this.control().setValue(this.control().value.filter((e) => e.idtContact !== idtContact));
  }

  /**
   * Adds an etity to the selected list when an option is selected.
   *
   * @param event the event that triggered the selection
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value) {
      this.selectContact(event.option.value);
    }
  }

  /**
   * Adds a contact to the selected list.
   *
   * @param idtContact the contact id
   */
  private selectContact(contact: BasicContact): void {
    if (!this.isIncluded(contact.idtContact)) {
      this.control().setValue([...this.control().value, contact]);
    }

    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.dispatchEvent(new Event('input'));
  }

  /**
   * Get the value to be shown while loading the full contact.
   *
   * @param option the selected option
   * @returns the name of the contact to use as display value
   */
  getOptionDisplay(option: ContactIndexedDTO): string {
    return option?.name;
  }

  /**
   * Verifies if the provided contact is already selected.
   *
   * @param contact the contact to verify
   * @returns true, if the contact is already selected
   */
  isIncluded(idtContact: number): boolean {
    return this.control().value.some((c) => c.idtContact === idtContact);
  }

  /**
   * Open dialog to create a new contact.
   */
  createNew(): void {
    this.trackerService.event('contact_autocomplete', 'new_contact');

    let type;

    if (this.mode() === 'person') {
      type = ContactTypeEnum.PERSON;
    } else if (['company', 'mainConsultant'].includes(this.mode())) {
      type = ContactTypeEnum.COMPANY;
    }

    this.contactService
      .openCreateContactDialog(this.searchInput.nativeElement.value, type)
      .afterClosed()
      .subscribe((data?: ContactNewDialogResponse) => {
        if (data?.idtContact) {
          this.contactService.getInfoById(data.idtContact).subscribe((contact) => {
            this.selectContact(contact);
          });
        }
      });
  }
}
