<mat-form-field class="tw-flex-1">
  <mat-label>Email</mat-label>
  <input matInput [formControl]="control()" type="email" />

  @if (searchingDuplicatedEmails()) {
    <mat-progress-spinner matSuffix mode="indeterminate" diameter="20" class="tw-mr-1"></mat-progress-spinner>
  } @else if (duplicatedEmail()) {
    <button matSuffix color="warn" mat-icon-button type="button" matTooltip="Duplicated email" [matMenuTriggerFor]="duplicatedEmailsMenu">
      <mat-icon fontIcon="mdi-alert-outline"></mat-icon>
    </button>
  } @else if (!!control().value && control().value === searchedEmail()) {
    <button matSuffix mat-icon-button type="button">
      <mat-icon fontIcon="mdi-check" class="abs-color-positive"></mat-icon>
    </button>
  }

  <mat-error>Invalid email</mat-error>
</mat-form-field>

<!-- Notice, this component is hidden as a hack since we don't want to show the alert icon, we only need the error message.
     The component is used elsewhere and it should show the icon there -->
<app-contact-last-email-error class="tw-hidden" [email]="searchedEmail()" (errorChanged)="errorChanged.emit($event)" />

<mat-menu #duplicatedEmailsMenu>
  <ng-template matMenuContent>
    <app-custom-list class="tw-w-[350px]">
      @for (contact of contactsWithSameEmail(); track contact.idtContact) {
        <app-contact-item [contact]="contact" [handleClicks]="false" [showPhoneAndEmail]="false" [showContextMenus]="false"></app-contact-item>
      }
    </app-custom-list>
  </ng-template>
</mat-menu>
