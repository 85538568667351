import { KeyValue } from '@angular/common';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { groupBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { OpportunityViewTO } from '../../model/opportunity.model';

interface GroupingOption {
  property: 'contactName' | 'consultantCompanyName' | 'portfolio';
  icon: string;
}

/**
 * Abstract class for methods used in opportunities list for contacts.
 */
export abstract class OpportunityGroupedListComponent {
  /**
   * The property to group opportunities by.
   */
  groupBy: 'contactName' | 'consultantCompanyName' | 'portfolio' = 'contactName';

  groupingOptions: GroupingOption[] = [
    { property: 'contactName', icon: 'mdi-account-group' },
    { property: 'consultantCompanyName', icon: 'mdi-account-child-outline' },
    { property: 'portfolio', icon: 'mdi-briefcase' },
  ];

  /**
   * Flag if the context is a consultant.
   */
  isConsultant = false;

  /**
   * If loading data.
   */
  loading = true;

  /**
   * All opportunities.
   */
  allOpportunities: OpportunityViewTO[] = [];

  /**
   * The list of opportunities wrapped in an observable.
   */
  groupedOpportunities?: { [key: string]: OpportunityViewTO[] };

  constructor(protected trackerService: TrackerService) {}

  /**
   * Change the grouping property.
   *
   * @param property the property to group by
   */
  groupByProperty(property: 'contactName' | 'consultantCompanyName' | 'portfolio'): void {
    this.groupBy = property;
    this.groupOpportunities();

    this.trackerService.event('opportunity_sidenav', 'group', {
      property: this.groupBy,
    });
  }

  /**
   * Set the opportunities to display.
   *
   * @param observable The observable that resolves to the opportunities list.
   */
  protected setOpportunities(observable: Observable<OpportunityViewTO[]>): void {
    observable
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((opp) => {
        this.allOpportunities = opp;
        this.groupOpportunities();
      });
  }

  /**
   * Group opportunities.
   */
  protected groupOpportunities(): void {
    this.groupedOpportunities = groupBy(this.allOpportunities, (o) => o[this.groupBy] || 'None');
  }

  /**
   * Sort opportunities groups alphabetically but keep 'None' in the end.
   *
   * @param a a group value
   * @param b another group value
   * @returns a number to hel short the groups
   */
  sortGroups(a: KeyValue<string, OpportunityViewTO[]>, b: KeyValue<string, OpportunityViewTO[]>): number {
    if (a.key === 'None') {
      return Number.MAX_SAFE_INTEGER;
    }

    return a.key === b.key ? 0 : a.key < b.key ? -1 : 1;
  }

  /**
   * Check if it should show the title line depending if the contact is a client or a consultant and the active tab.
   *
   * @returns true, if it should show the title line
   */
  showTitle(): boolean {
    if (this.isConsultant) {
      return this.groupBy !== 'consultantCompanyName';
    } else {
      return this.groupBy !== 'contactName';
    }
  }

  /**
   * Get the proper tooltip to show for the provided grouping button.
   *
   * @param option the grouping option
   * @returns the proper tooltip to show in the button
   */
  getTooltip(option: GroupingOption): string {
    switch (option.property) {
      case 'contactName':
        return this.isConsultant ? 'Group by client' : 'List by opportunities';
      case 'consultantCompanyName':
        return this.isConsultant ? 'List by opportunities' : 'Group by consultant';
      case 'portfolio':
        return 'Group by portfolio';
    }
  }
}
