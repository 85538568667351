import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PortfolioAllocation } from '../../../portfolio/model/portfolio-allocation.model';
import { ConsultantAllocation } from '../../model/consultant-allocation.model';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-consultant-allocation',
  templateUrl: './consultant-allocation.component.html',
  styleUrls: ['./consultant-allocation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultantAllocationComponent {
  /**
   * Consultant allocation list.
   */
  $consultants?: Observable<ConsultantAllocation[]>;

  /**
   * The selected portfolio to filter for.
   */
  private _portfolio?: PortfolioAllocation;

  get portfolio(): PortfolioAllocation | undefined {
    return this._portfolio;
  }

  @Input()
  set portfolio(value: PortfolioAllocation | undefined) {
    this._portfolio = value;
    this.getAllocations();
  }

  /**
   * The reference date to use for searching.
   */
  private _asOf?: DateTime;

  get asOf(): DateTime | undefined {
    return this._asOf;
  }

  @Input()
  set asOf(date: DateTime | undefined) {
    this._asOf = date;
    this.getAllocations();
  }

  /**
   * Emits the select event.
   */
  @Output()
  selectConsultant = new EventEmitter<ConsultantAllocation>();

  /*
   * The id of the selected item.
   */
  @Input()
  selectedConsultant?: ConsultantAllocation;

  /**
   * Whehter to show selection filter chips.
   */
  @Input()
  showChip = false;

  /**
   * Event emitted if the portfolio chip is removed.
   */
  @Output()
  portfolioRemoved = new EventEmitter<void>();

  loading = signal(true);

  constructor(
    private clientService: ClientService,
    private router: Router
  ) {}

  private getAllocations(): void {
    if (this.asOf) {
      this.loading.set(true);

      this.$consultants = this.clientService.getConsultantAllocations(this.asOf, this.portfolio?.idtPortfolio).pipe(
        finalize(() => {
          this.loading.set(false);
        })
      );
    }
  }

  /**
   * Track consultants list by its id.
   * @param index list index
   * @param item consultants item
   * @returns the consultants id.
   */
  public trackConsultants(index: number, item: ConsultantAllocation): number {
    return item.idtExternalRep;
  }

  /**
   * Select consultant and emit an event to the parent component.
   * @param consultant the consultant
   */
  public select(consultant?: ConsultantAllocation): void {
    if (this.selectedConsultant?.idtExternalRep === consultant?.idtExternalRep) {
      this.selectConsultant.emit(undefined);
    } else {
      this.selectConsultant.emit(consultant);
    }
  }

  /**
   * Navigate to default detail page.
   * @param client consultant object
   * @param $event click event
   */

  public goToDetail(consultant: ConsultantAllocation, $event: Event): void {
    $event.stopPropagation();
    this.router.navigate(['/contact', consultant.idtExternalRep]);
  }

  removePortfolio(): void {
    this.portfolioRemoved.emit();
  }
}
