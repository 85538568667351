<mat-toolbar color="primary">
  <span class="abs-margin-left">Set distribution list to contacts</span>
  <span fxFlex></span>
  <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
    <mat-icon fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<app-progress-bar [loading]="loading"></app-progress-bar>

<div fxFlex class="abs-padding">
  <app-add-account-groups fxFlex [idtContact]="idtContact" [idtPortfolio]="idtPortfolio" (loading)="loading = $event"></app-add-account-groups>
</div>
