import { BasicContact } from 'app/modules/common/business/contact/model/contact.model';
import { User } from 'app/modules/common/business/user/user.model';
import { BasePortfolio } from '../../portfolio/model/portfolio.model';

/**
 * Represents the Log view
 */
export interface LogDetailViewTO {
  idtLogEntity: number;
  idtLog: number;
  idtClient: number;
  authorInitials: string;
  author: string;
  contacts: BasicContact[];
  relatedContacts: BasicContact[];
  reportedDate: Date;
  subject: string;
  bodyHtmlContent: string;
  type: LogTypeEnum;
  emailFrom: string;
  emailTo: string;
  highValueAsset: boolean;
  participants?: {
    idtUser: number;
    name: string;
    initials: string;
  }[];
  opportunityCount: number;
  documentCount: number;
  portfolios: BasePortfolio[];
  customPortfolio: boolean;
}

/**
 * Represents a Log model.
 */
export interface Log {
  idtLog: number;
  type: LogTypeEnum;
  reportedDate: string;
  subject: string;
  highValueAsset: boolean;
  bodyHtmlContent: string;
  owner: User;
  participants: User[];
  version: number;
  to: string;
  from: string;
  contacts?: BasicContact[];
  relatedContacts?: BasicContact[];
  portfolios?: number[];
  customPortfolio: boolean;
}

/**
 * Log draft entity, same as a log but all fields might be null.
 */
export interface LogDraft {
  idtLog?: number | null;
  type?: LogTypeEnum | null;
  reportedDate?: string | null;
  subject?: string | null;
  highValueAsset?: boolean | null;
  bodyHtmlContent?: string | null;
  owner?: User | null;
  participants?: User[] | null;
  version?: number | null;
  to?: string | null;
  from?: string | null;
  contacts?: BasicContact[] | null;
  relatedContacts?: BasicContact[] | null;
  portfolios?: number[] | null;
  customPortfolio?: boolean | null;
}

/**
 * A portfolio attached to a log.
 */
export interface LogPortfolio {
  idtPortfolio: number;
  shortName: string;
}

/**
 * Represents the Enum of Log type
 */
export enum LogTypeEnum {
  NOTE = 'NOTE',
  PHONE_CALL = 'PHONE_CALL',
  MEETING = 'MEETING',
  PRESENTATION = 'PRESENTATION',
  EMAIL = 'EMAIL',
  VIDEO_CALL = 'VIDEO_CALL',
  CONFERENCE = 'CONFERENCE',
}

/**
 * Get log type label
 */
export class LogTypeLabel {
  static labels = {
    NOTE: 'Note',
    PHONE_CALL: 'Phone Call',
    MEETING: 'Meeting',
    PRESENTATION: 'Presentation',
    EMAIL: 'Email',
    VIDEO_CALL: 'Video Call',
    CONFERENCE: 'Conference',
  };

  static getValue(value: LogTypeEnum): string {
    if (value) {
      return this.labels[value];
    }

    return '';
  }
}

/**
 * Get icon name
 */
export class LogIcons {
  icons = {
    NOTE: 'mdi-note',
    PHONE_CALL: 'mdi-phone',
    MEETING: 'mdi-account-multiple',
    PRESENTATION: 'mdi-presentation',
    EMAIL: 'mdi-email',
    VIDEO_CALL: 'mdi-video',
    CONFERENCE: 'mdi-human-male-board',
  };

  getIconValue(value: LogTypeEnum): string {
    if (value) {
      return this.icons[value];
    }
    return '';
  }
}

/**
 * An attached entity.
 */
export interface AttachedEntity {
  idtEntity: number;
  name: string;
  type: string;
}

/**
 * Total logs.
 */
export interface LogTotal {
  type: LogTypeEnum;
  total: number;
}

/**
 * Log and contact view.
 */
export interface LogContactViewTO extends BasicContact {
  category: string;
  branch: boolean;
}

/**
 * Log and related contact view.
 */
export type LogRelatedContactViewTO = LogContactViewTO;

/**
 * DTO with the response for log contacts search.
 */
export interface LogContactsDTO {
  contacts: LogContactViewTO[];
  relatedContacts: LogRelatedContactViewTO[];
}

/**
 * Define the proper text and color to use to identify the entity types.
 */
export const AttachedEntityDefinitions: { [key: string]: any } = {
  contact: {
    text: 'CO',
    color: 'green',
  },
  client: {
    text: 'C',
    color: 'blue',
  },
  portfolio: {
    text: 'P',
    color: 'orange',
  },
  account: {
    text: 'A',
    color: 'red',
  },
};

/**
 * Model class for the contact data in log report items.
 */
export interface LogReportContactItem {
  companyName: string;
  balanceAmount: number;
  contacts: string;
  balances?: {
    balanceAmount: string;
    portfolio: string;
  }[];
}

/**
 * Model class for log report items.
 */
export interface LogReportItem {
  idtLog: number;
  date: string;
  title: string;
  portfolio: string;
  author: string;
  participants: string;
  type: string;
  contacts: LogReportContactItem[];
  relatedContacts: LogReportContactItem[];
  text: string;
  externalRepName: string;
  idtExternalRep: string;
  amount: string;
  highValueAsset: string;
}

/**
 * Model class for log quantities by author and type.
 */
export interface LogsByTypeTO {
  user: string;
  meeting: number;
  phone: number;
  presentation: number;
  note: number;
  videoCall: number;
  conference: number;
  email: number;
  currentTotal: number;
}

/**
 * Model class for logs count by type grouped by consultant.
 */
export interface LogsByTypeAndConsultantTO extends LogsByTypeTO {
  idtConsultant?: number;
  consultant: string;
}

/**
 * Model class for logs count by type grouped by contact.
 */
export interface LogsByTypeAndContactTO extends LogsByTypeTO {
  idtContact?: number;
  contact: string;
}

/**
 * Model class for logs by time period.
 */
export interface LogsByPeriodTO {
  name: string;
  wtd: number;
  wtdHva: number;
  mtd: number;
  mtdHva: number;
  qtd: number;
  qtdHva: number;
  ytd: number;
  ytdHva: number;
}

/**
 * Model class for the full log report data.
 */
export interface LogReportData {
  logs: LogReportItem[];
  logsByType: LogsByTypeTO[];
  logsByAuthor: LogsByPeriodTO[];
  logsByPortfolio: LogsByPeriodTO[];
  logsByCategory: LogsByPeriodTO[];
  fromDate: string;
  toDate: string;
  executionDate: string;
  user: string;
  filteredAuthors: string;
  filteredContacts: string;
  logsByTypeAndConsultant: LogsByTypeAndConsultantTO[];
  logsByTypeAndContact: LogsByTypeAndContactTO[];
}
