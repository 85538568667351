<mat-form-field fxFlex>
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option>
      <ngx-mat-select-search [formControl]="countrySearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
        <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    @if (!countrySearchControl.value) {
      @for (option of topCountries; track option) {
        <mat-option [value]="option.idtCountry">
          {{ option.name }}
        </mat-option>
      }
      <mat-divider></mat-divider>
    }
    @for (option of countries$ | async; track option) {
      <mat-option [value]="option.idtCountry">
        {{ option.name }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
