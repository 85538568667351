import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { EmailGroupContactViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { DateTime } from 'luxon';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MailingService } from '../../../mailing/services/mailing.service';
import { ContactChannel } from '../../model/contact-channel.model';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact-end-job-dialog',
  templateUrl: './contact-end-job-dialog.component.html',
  styleUrls: ['./contact-end-job-dialog.component.scss'],
})
export class ContactEndJobDialogComponent {
  formGroup = this.fb.group({
    endDate: new FormControl<DateTime>(DateTime.now(), Validators.required),
  });

  emailData?: ContactChannel;
  phoneData?: ContactChannel;
  emailGroups?: EmailGroupContactViewTO[];

  idtContact: number;

  /**
   * The contact job entity id.
   */
  idtContactJob: number;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; idtContactJob: number },
    private dialogRef: MatDialogRef<ContactEndJobDialogComponent>,
    private dialogService: DialogService,
    private mailingService: MailingService,
  ) {
    this.idtContact = data.idtContact;
    this.idtContactJob = data.idtContactJob;

    this.contactService.getMainEmail(this.idtContact).subscribe((contactChannel) => {
      this.emailData = contactChannel;
    });

    this.contactService.getMainPhone(this.idtContact).subscribe((contactChannel) => {
      this.phoneData = contactChannel;
    });

    const filter = new DataFilter().equals('archived', false, 'boolean').equals('deleted', false, 'boolean');

    this.contactService.findMailingGroupsByIdtContact(this.idtContact, 'name,asc', filter).subscribe((data) => {
      this.emailGroups = data;
    });
  }

  /**
   * Confirm setting the job end date.
   *
   * @param deleteEmailAndGroups whether to also remove the contact email and email groups.
   */
  confirm(deleteEmailAndGroups: boolean): void {
    if (deleteEmailAndGroups) {
      this.mailingService
        .removeContactFromAllGroups(this.idtContact, true)
        .pipe(
          catchError((err) => {
            this.dialogService.showError(err.error?.message || 'Error saving, try again or call support');
            return throwError(() => err);
          }),
        )
        .subscribe(() => {
          this.dialogRef.close(this.formGroup.controls.endDate.value);
        });
    } else {
      this.dialogRef.close(this.formGroup.controls.endDate.value);
    }
  }

  hasEmailData(): boolean {
    return !!this.emailData || !!this.phoneData || (!!this.emailGroups && this.emailGroups.length > 0);
  }
}
