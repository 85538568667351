<mat-toolbar color="primary">
  <span class="tw-ml-1 tw-flex-1">Add contact</span>
  <button mat-icon-button (click)="close()">
    <mat-icon fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<app-progress-bar [loading]="loading()"></app-progress-bar>

<!-- Initial step, search existing contacts -->
@if (currentStep() === CreationState.searchExisting) {
  <app-contact-new-search-existing
    (processing)="loading.set($event)"
    (newSelected)="createNew($event)"
    [initialSearch]="initialName"
    [type]="type"
  ></app-contact-new-search-existing>
}

<!-- Second step, fill new contact basic data -->
@if (currentStep() === CreationState.basicData) {
  <app-contact-new-basic-data
    [name]="initialName"
    [email]="initialEmail"
    [type]="type!"
    (processing)="loading.set($event)"
    (completed)="afterSaved($event)"
    [idtMainCompany]="idtMainCompany"
  ></app-contact-new-basic-data>
}

<!-- Step to assign employees to new company -->
@if (currentStep() === CreationState.associateEmployees) {
  <app-contact-new-associate-employees
    (processing)="loading.set($event)"
    [idtContact]="createdContact()!.idtContact"
  ></app-contact-new-associate-employees>
}
