<app-progress-bar [loading]="saving() || loading()"></app-progress-bar>

<app-dialog-title displayTitle="Consultants">
  <button mat-icon-button type="button" (click)="addContactConsultantForm()" [disabled]="saving()">
    <mat-icon fontIcon="mdi-plus-circle" color="primary"></mat-icon>
  </button>
</app-dialog-title>

<mat-dialog-content>
  <form (submit)="save()" id="consultant-form">
    <div class="tw-flex tw-flex-col">
      @for (consultantForm of formArray.controls; track consultantForm; let i = $index) {
        <app-contact-consultant-form
          [consultantForm]="$any(consultantForm)"
          [index]="i"
          (remove)="remove($event)"
          class="abs-dashed-box abs-margin-bottom"
          [idtContact]="idtContact"
        ></app-contact-consultant-form>
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="consultant-form">Save</button>
</div>
