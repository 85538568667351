<app-custom-list-item [routerLink]="['', { outlets: { sidenav: ['opportunity', opportunity.idtOpportunity] } }]">
  <div [fxFlex]="dense ? 60 : 50" fxLayout="column">
    <div style="display: flex">
      <app-contact-context-menu
        class="abs-bold abs-text-overflow-ellipsis"
        [displayText]="opportunity.displayName"
        [id]="opportunity.idtContact || opportunity.idtConsultantCompany || 0"
      ></app-contact-context-menu>
    </div>
    <span class="mat-caption abs-color-faded">{{ opportunity.portfolio }}</span>
  </div>
  @if (!dense) {
    <div
      fxFlex="10"
      [matTooltip]="(opportunity.probabilityTiming | probabilityTiming) + '\nCreated ' + (opportunity.creationDate | timeAgo)"
      matTooltipClass="multline-tooltip"
      class="timing-column"
      >
      {{ opportunity.probabilityTiming | titlecase }}
    </div>
  }
  <div fxFlex="10">{{ opportunity.size | nearUnitNumber: '0.0-0' }}</div>
  <a fxFlex="20" class="abs-link" [routerLink]="['', { outlets: { sidenav: ['log', opportunity.idtLastLog] } }]" (click)="$event.stopPropagation()">
    {{ opportunity.lastLogDate | date: 'MM/dd/y' }}
  </a>
  <div fxFlex="10">
    <app-opportunity-status [status]="opportunity.status"></app-opportunity-status>
  </div>
  @if (!last) {
    <mat-divider></mat-divider>
  }
</app-custom-list-item>
