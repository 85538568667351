@if (appearance === 'normal') {
  <div
    [ngClass]="{
      'abs-color-positive': investmentStatus === 'Invested',
      'abs-color-warn': investmentStatus === 'Consultant',
      'abs-color-negative': investmentStatus === 'Not Invested' || investmentStatus === 'Former Investor',
      short: short
    }"
  >
    @if (!short) {
      <div class="status">{{ investmentStatus }}</div>
    } @else {
      <div>
        <div class="status">{{ shortInvestmentStatus }}</div>
      </div>
    }
  </div>
} @else if (appearance === 'outline') {
  <div
    class="abs-padding-half"
    [ngClass]="{
      'abs-background-positive': investmentStatus === 'Invested',
      'abs-background-warn': investmentStatus === 'Consultant',
      'abs-background-negative': investmentStatus === 'Not Invested' || investmentStatus === 'Former Investor',
      short: short
    }"
  >
    @if (!short) {
      <div class="status">{{ investmentStatus }}</div>
    } @else {
      <div>
        <div class="status">{{ shortInvestmentStatus }}</div>
      </div>
    }
  </div>
}
