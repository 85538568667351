import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const ABS_CORE_BREAKPOINTS = [
  { code: 'xs', name: 'Xs', max: 600 } /* Deprecated Smartphone */,
  { code: 'gt-xs', name: 'GtXs', min: 601 },
  { code: 'sm', name: 'Sm', min: 600, max: 961 } /* Smartphone and portrait Tablet */,
  { code: 'gt-sm', name: 'GtSm', min: 961 },
  { code: 'lt-md', name: 'LtMd', max: 960 },
  { code: 'md', name: 'Md', min: 961, max: 1025 } /* Landscape Tablet */,
  { code: 'gt-md', name: 'GtMd', min: 1025 },
  { code: 'lg', name: 'Lg', min: 1025, max: 1361 } /* Desktop */,
  { code: 'gt-lg', name: 'GtLg', min: 1601 },
  { code: 'xl', name: 'Xl', min: 1601 } /* Larger than a conventional Desktop */,
];

/**
 * Service to handle breakpoints
 */
@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private breakpointSource = new BehaviorSubject<{ [key: string]: boolean }>({});

  public breakpoints = this.breakpointSource.asObservable();

  /**
   * Sets the abs breakpoints
   * @param width
   */
  public setAbsBreakpoints(width: number): void {
    const breakpoints: { [key: string]: boolean } = {};
    ABS_CORE_BREAKPOINTS.forEach(
      (breakpoint) =>
        (breakpoints[breakpoint.code] = (!breakpoint['min'] || width >= breakpoint.min) && (!breakpoint['max'] || width < breakpoint.max))
    );
    this.breakpointSource.next(breakpoints);
  }
}
