<div fxLayout="row" class="abs-padding" style="margin-right: 6px">
  <div [fxFlex]="dense ? 60 : 50" class="mat-header-cell">Client / Portfolio</div>
  @if (!dense) {
    <div fxFlex="10" class="mat-header-cell timing-column">Timing</div>
  }
  <div fxFlex="10" class="mat-header-cell">Size</div>
  <div fxFlex="20" class="mat-header-cell">Last contact</div>
  <div fxFlex="10" class="mat-header-cell">Status</div>
</div>
<mat-divider></mat-divider>
