<div fxFlex fxLayout="column" class="wrapper">
  <mat-toolbar color="primary">
    <span class="abs-margin-left abs-text-overflow-ellipsis">{{ (accountDetails$ | async)?.name }}</span>
    <span fxFlex></span>
    @if (!(accountDetails$ | async)?.inactive) {
      <button
        mat-icon-button
        [routerLink]="['', { outlets: { sidenav: ['transaction', 'new'] } }]"
        [queryParams]="{ 'sidenav.idtAccount': idtAccount, 'sidenav.idtClient': idtClient }"
        *hasPermission="'CREATE_TRANSACTION'"
        matTooltip="New transaction"
      >
        <mat-icon fontIcon="mdi-cash-multiple"></mat-icon>
      </button>
    }
    <button
      mat-icon-button
      [routerLink]="['', { outlets: { sidenav: ['account', idtClient, 'new'] } }]"
      [queryParams]="{ 'sidenav.cloneFrom': idtAccount }"
      *hasPermission="'CREATE_ACCOUNT'"
      matTooltip="Clone"
    >
      <mat-icon fontIcon="mdi-content-copy"></mat-icon>
    </button>
    <button
      mat-icon-button
      [routerLink]="['', { outlets: { sidenav: ['account', idtClient, idtAccount, 'edit'] } }]"
      *hasPermission="'EDIT_ACCOUNT'"
      matTooltip="Edit"
    >
      <mat-icon fontIcon="mdi-pencil"></mat-icon>
    </button>
    <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
      <mat-icon fontIcon="mdi-close"></mat-icon>
    </button>
  </mat-toolbar>

  <div fxFlex>
    <mat-tab-group mat-stretch-tabs="false" fxFlex class="flex-tab-group tab-tool-header abs-padding-horizontal">
      <mat-tab label="Account Details">
        <div class="tab-tools" fxLayoutAlign="end center"></div>
        <div fxFlex fxLayout="column">
          @if (accountDetails$ | async; as accountDetails) {
            <div class="abs-padding abs-scroll-auto" fxFlex fxLayout="column">
              <app-output-section label="Investment" icon="mdi-briefcase" class="abs-margin-top">
                <app-output fxFlex label="Client">
                  <div class="abs-flex">
                    @if (accountDetails.client?.owner; as owner) {
                      <app-contact-context-menu [displayText]="owner.name" [id]="owner.idtContact"></app-contact-context-menu>
                    }
                  </div>
                </app-output>
                <div fxLayout="row">
                  <app-output fxFlex="50" label="Portfolio">
                    <app-portfolio-context-menu
                      [id]="accountDetails.portfolio!.idtPortfolio"
                      [displayText]="accountDetails.portfolio!.fullName"
                    ></app-portfolio-context-menu>
                  </app-output>
                  <app-output fxFlex label="Class">
                    <span>{{ accountDetails.portfolioClass?.name || '-' }}</span>
                  </app-output>
                  <app-output fxFlex label="Series">
                    <span>{{ accountDetails.portfolioSeries?.name || '-' }}</span>
                  </app-output>
                </div>
                <div fxLayout="row">
                  <app-output fxFlex="50" label="Initial Investment Date">
                    <span>{{ (accountDetails?.initialInvestment | date: 'MM/dd/y') || '-' }}</span>
                  </app-output>
                  <app-output fxFlex="25" label="Balance">
                    <span>{{ ((currentBalance$ | async)?.endBalance | currency) || '-' }}</span>
                  </app-output>
                  <div fxFlex="25" fxLayoutAlign="start center">
                    @if (accountDetails.ocio) {
                      <mat-chip>Outsourced CIO</mat-chip>
                    }
                  </div>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Fees" icon="mdi-currency-usd">
                <div fxLayout="column" fxLayoutGap="8px">
                  <div fxLayout="row">
                    <app-output fxFlex label="Management">
                      <span [ngClass]="{ strikethrough: accountDetails.portfolioClass?.managementFeeWaived }">
                        @if (accountDetails.portfolioClass?.managementFee != null) {
                          {{ accountDetails.portfolioClass!.managementFee! / 100 | percent: '1.2-2' }}
                        } @else {
                          -
                        }
                      </span>
                    </app-output>
                    <app-output fxFlex label="Effective">
                      @if (accountDetails.effectiveManagementFee != null) {
                        <span>
                          {{ accountDetails.effectiveManagementFee / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                    </app-output>
                  </div>
                  <div fxLayout="row">
                    <app-output fxFlex label="Incentive">
                      @if (accountDetails.portfolioClass?.incentiveFee != null) {
                        <span>
                          {{ accountDetails.portfolioClass?.incentiveFee! / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                      @if (accountDetails.portfolioClass?.incentiveFee != null) {
                        <span style="white-space: pre-line">
                          @if (accountDetails.portfolioClass) {
                            {{ accountDetails.portfolioClass | hurdleData }}
                          } @else {
                            -
                          }
                        </span>
                      }
                    </app-output>
                    <app-output fxFlex label="Effective">
                      @if (accountDetails.effectiveIncentiveFee != null) {
                        <span>
                          {{ accountDetails.effectiveIncentiveFee / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                      @if (accountDetails.effectiveIncentiveFee != null) {
                        <span style="white-space: pre-line">
                          @if (accountDetails.portfolioClass) {
                            {{ accountDetails.portfolioClass | hurdleData }}
                          } @else {
                            -
                          }
                        </span>
                      }
                    </app-output>
                  </div>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Consultant" icon="mdi-domain">
                <div fxLayout="row">
                  <app-output fxFlex label="Company">
                    <div class="abs-flex">
                      @if (accountDetails.consultantView?.idtConsultantCompany) {
                        <app-contact-context-menu
                          [displayText]="accountDetails.consultantView!.consultantCompanyName"
                          [id]="accountDetails.consultantView!.idtConsultantCompany"
                        ></app-contact-context-menu>
                      } @else {
                        <span>-</span>
                      }
                    </div>
                  </app-output>
                  <app-output fxFlex label="Name">
                    <div class="abs-flex">
                      @if (accountDetails.consultantView?.idtConsultant) {
                        <app-contact-context-menu
                          [displayText]="accountDetails.consultantView!.consultantName"
                          [id]="accountDetails.consultantView!.idtConsultant"
                        ></app-contact-context-menu>
                      } @else {
                        <span>-</span>
                      }
                    </div>
                  </app-output>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Leads" icon="mdi-account-tie">
                <div fxLayout="row">
                  <app-output fxFlex="25" label="Lead">
                    <span>{{ accountDetails.lead?.name || '-' }}</span>
                  </app-output>
                  <app-output fxFlex="25" label="Backup">
                    <span>{{ accountDetails.leadBackup?.name || '-' }}</span>
                  </app-output>
                  <app-output fxFlex label="Portfolio Manager">
                    <span>{{ accountDetails.portfolio?.manager?.name || '-' }}</span>
                  </app-output>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Legal/Compliance" icon="mdi-gavel">
                <div fxLayout="row">
                  <app-output fxFlex="25" label="Side Letter">
                    <span>{{ accountDetails.sideLetter | yesNo }}</span>
                  </app-output>

                  @if (accountDetails.sideLetter) {
                    <app-output fxFlex label="Side Letter Summary">
                      <span>{{ accountDetails.sideLetterSummary || '-' }}</span>
                    </app-output>
                  }
                </div>
                <div fxLayout="row">
                  <app-output fxFlex label="Erisa">
                    <span>{{ accountDetails.erisaStatus | emptyValue }}</span>
                  </app-output>
                  <app-output fxFlex label="Finra 5131">
                    <span>{{ accountDetails.finra5131 || '-' | titlecase }}</span>
                  </app-output>
                  <app-output fxFlex label="Blue Sky State">
                    <span>{{ accountDetails.blueSkyState || '-' }}</span>
                  </app-output>
                  <app-output fxFlex label="New Issue">
                    <span>{{ accountDetails.newIssueStatus || '-' | titlecase }}</span>
                  </app-output>
                </div>
                <div fxLayout="row">
                  <app-output fxFlex label="FormPf">
                    <span>{{ accountDetails.formPf || '-' }}</span>
                  </app-output>
                  <app-output fxFlex label="Share Rebate">
                    <span>{{ accountDetails.shareRebate | yesNo }}</span>
                  </app-output>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Location" icon="mdi-earth">
                <div fxLayout="row">
                  <app-output fxFlex="25" label="Region">
                    <span>{{ accountDetails.region?.name || '-' }}</span>
                  </app-output>
                  <app-output fxFlex label="Country">
                    <span>{{ accountDetails.country?.name || '-' }}</span>
                  </app-output>
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Account keys" icon="mdi-account-key">
                <div fxLayout="column">
                  @for (key of accountDetails.keys; track key) {
                    <div fxLayout="row" fxLayoutAlign="start center">
                      {{ key.key }}
                      @if (accountDetails.keys.length > 1 && key.main) {
                        <mat-chip-option class="mini abs-margin-half-left">Main</mat-chip-option>
                      }
                    </div>
                  }
                  @if (accountDetails.keys.length === 0) {
                    <div>No keys configured</div>
                  }
                </div>
                <mat-divider></mat-divider>
              </app-output-section>
              <app-output-section label="Other info" icon="mdi-information-outline">
                <div fxLayout="row">
                  <app-output fxFlex="50" label="Type">
                    <span>{{ accountDetails.type?.name || '-' }}</span>
                  </app-output>
                </div>
              </app-output-section>
            </div>
          }
        </div>
      </mat-tab>

      <mat-tab label="Balance History">
        <ng-template matTabContent>
          @if (accountDetails$ | async; as account) {
            <app-account-balance-history
              [idtAccount]="account.idtAccount!"
              [inactive]="account.inactive"
              [name]="account.name"
            ></app-account-balance-history>
          }
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
