import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Address } from '../model/address.model';
import { Country } from '../model/country.model';
import { State } from '../model/state.model';

/**
 * Service to call the api for a address CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  /**
   * Gets the data of a specific address
   *
   * @param id address id
   * @returns Address
   */
  public getById(id: number): Observable<Address> {
    return this.http.get<Address>(`${environment.apiUrl}/address/${id}`);
  }

  /**
   * Gets the countries
   *
   * @returns Countries.
   */
  public getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${environment.apiUrl}/country`);
  }

  /**
   * Finds the states of a specific country.
   *
   * @returns an observable that emits the list of states found when the server responds
   */
  public findStatesByCountry(idtCountry: number): Observable<State[]> {
    return this.http.get<State[]>(`${environment.apiUrl}/country/${idtCountry}/state`);
  }

  /**
   * Find all company addresses.
   *
   * @param idtCompany the id of the company
   * @returns an observable that emits the list of addresses found
   */
  public getCompanyAddresses(idtCompany: number): Observable<Address[]> {
    return this.http.get<Address[]>(`${environment.apiUrl}/address`, {
      params: {
        idtCompany,
      },
    });
  }
}
